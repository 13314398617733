import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CheckCircle, XCircle, ArrowRight } from 'lucide-react';
import useApi from '../../../hooks/api';

const AcceptTeamInvite = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [inviteStatus, setInviteStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { post } = useApi();

  useEffect(() => {
    const acceptInvite = async () => {
      if (!token) {
        setError('No invite token found');
        setLoading(false);
        return;
      }

      try {
        const response = await post('/team-members/accept-invite', { inviteToken: token });
        localStorage.setItem('inviteOrgId' , response?.orgId);
        setInviteStatus(response.message);
        setLoading(false);
      } catch (err) {
        setError(err.response?.data?.error || 'Failed to accept invite');
        setLoading(false);
        console.log(err)
      }
    };

    acceptInvite();
  }, [token, post]);

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 px-4">
      <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-8 text-center">
        {inviteStatus ? (
          <>
            <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-6" />
            <h1 className="text-3xl font-bold text-gray-800 mb-4">Invite Accepted!</h1>
            <p className="text-gray-600 mb-6">
              {inviteStatus}
            </p>
          </>
        ) : (
          <>
            <XCircle className="w-16 h-16 text-red-500 mx-auto mb-6" />
            <h1 className="text-3xl font-bold text-gray-800 mb-4">Invite Acceptance Failed</h1>
            <p className="text-gray-600 mb-6">
              {error || 'An unknown error occurred.'}
            </p>
          </>
        )}
        <button
          onClick={() => navigate('/auth')}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg transition duration-200 ease-in-out inline-flex items-center"
        >
          Go to Login
          <ArrowRight className="ml-2 w-4 h-4" />
        </button>
      </div>
    </div>
  );
};

export default AcceptTeamInvite;