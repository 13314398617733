import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AuthForm from './components/AuthForm';
import Dashboard from './components/dashboard/dashboard';
import useApi from './hooks/api';
import { getUserData } from './utils/userData';
import PlanSelectionPage from './components/dashboard/PlanSelectionPage';
import PaymentSuccessPage from './components/dashboard/payment/success';
import PaymentFailedPage from './components/dashboard/payment/failed';
import AcceptTeamInvite from './components/dashboard/invite/accept';
import Home from './components/home';
import ForgotPassword from './components/forgotPassword';
import ResetPass from './components/ResetPass';
import PricingPage from './components/home/PricingPage';
import Loader from './components/common/Loader'; // Import the Loader component
import FeaturesPage from './components/home/Features';
import PrivacyPolicy from './components/home/PrivacyPolicy';
import TermsAndConditions from './components/home/TermsAndConditions';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { post } = useApi();

  useEffect(() => {
    const validateToken = async () => {
      const userData = getUserData();
      if (userData?.token) {
        try {
          const response = await post('/validate', { uid: userData.uid, token: userData.token });
          if (response.message === 'VALID_TOKEN') {
            setIsAuthenticated(true);
          } else {
            localStorage.removeItem('userData');
            setIsAuthenticated(false);
          }
        } catch (err) {
          setIsAuthenticated(false);
        }
      } else {
        setIsAuthenticated(false);
      }
      
      setIsLoading(false);
    };

    validateToken();
  }, [post]);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  if (isLoading) {
    return <Loader />;
  }

  // Modified ProtectedRoute component
  const ProtectedRoute = ({ children }) => {
    const userData = getUserData();
    if (!isAuthenticated && !userData?.token) {
      return <Navigate to="/auth" replace />;
    }
    return children;
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/auth" element={<AuthForm onLogin={handleLogin} />} />
          <Route path="/register" element={<Navigate to="/auth?register" replace />} />
          <Route path="/features" element={<FeaturesPage />} />

          <Route 
            path="/dashboard" 
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            } 
          />
          <Route path="/" element={<Home />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route 
            path='/plan'  
            element={
              <ProtectedRoute>
                <PlanSelectionPage />
              </ProtectedRoute>
            }
          /> 
          <Route 
            path="/subscription/success" 
            element={
              <ProtectedRoute>
                <PaymentSuccessPage />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/subscription/failed" 
            element={
              <ProtectedRoute>
                <PaymentFailedPage />
              </ProtectedRoute>
            } 
          />
          <Route path="/requestResetPassword" element={<ForgotPassword />} />
          <Route path="/reset-password/:code/:uid" element={<ResetPass />} />
          <Route 
            path='/accept-invite/:token' 
            element={
                <AcceptTeamInvite />
            }
          />

<Route path="/privacy-policy" element={<PrivacyPolicy />} />
<Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;