import React, { useState, useEffect } from 'react';
import { Plus, Globe, X, Edit, Trash2, ChevronDown, AlertOctagon } from 'lucide-react';
import useApi from '../../hooks/api';
import { getValue } from '../../utils/userData';
import Loader from '../common/Loader';

const Alert = ({ type, children, onClose }) => (
  <div className={`p-4 mb-4 rounded-md ${type === 'success' ? 'bg-green-100 border-green-400 text-green-700' : 'bg-red-100 border-red-400 text-red-700'}`}>
    <div className="flex justify-between items-center">
      <div>{children}</div>
      <button onClick={onClose} className="text-sm font-semibold">×</button>
    </div>
  </div>
);

const ProductsPage = () => {
  const [products, setProducts] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [newProduct, setNewProduct] = useState({ productName: '', productDescription: '', productUrl: '', campaignId: '' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);
  const [status, setStatus] = useState(null);
  const [urlError, setUrlError] = useState('');
  const [filter, setFilter] = useState('all');
  const { get, post, loading, error } = useApi();
  const orgId = localStorage.getItem('orgIdSelected');

  useEffect(() => {
    if (orgId) {
      fetchProducts();
      fetchCampaigns();
    }
  }, [orgId]);

  const fetchProducts = async () => {
    try {
      const data = await post('getProducts', { uid: getValue('uid'), orgId });
      setProducts(data);
    } catch (err) {
      setStatus({ type: 'error', message: err.error || 'Failed to fetch products' });
    }
  };

  const fetchCampaigns = async () => {
    try {
      const response = await post('/getCampaigns', {
        uid: getValue('uid'),
        orgId
      });

      const formattedCampaigns = response.map(campaign => ({
        name: campaign.campaignName,
        description: campaign.campaignDescription,
        teamMembers: campaign.teamMembers || [],
        id: campaign.campaignId,
        createdAt: new Date(parseInt(campaign.created)).toISOString(),
        uid: campaign.uid,
        orgId: campaign.orgId,
        keywordCount: campaign.keywordCount,
        productCount: campaign.productCount
      }));
      setCampaigns(formattedCampaigns);
    } catch (err) {
      setStatus({ type: 'error', message: err.error || 'Failed to fetch campaigns' });
    }
  };

  const validateUrl = (url) => {
    const urlPattern = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
    return urlPattern.test(url);
  };

  const handleAddOrUpdateProduct = async () => {
    if (newProduct.productName && newProduct.productDescription && newProduct.productUrl && newProduct.campaignId) {
      if (!validateUrl(newProduct.productUrl)) {
        setUrlError('Please enter a valid URL');
        return;
      }
      setUrlError('');

      try {
        let response;
        if (editingProduct) {
          response = await post('editProduct', {
            uid: getValue('uid'),
            orgId,
            productId: editingProduct.productId,
            ...newProduct
          });
        } else {
          response = await post('addProduct', {
            uid: getValue('uid'),
            orgId,
            ...newProduct
          });
        }
  
        if (editingProduct) {
          setStatus({ type: 'success', message: 'Product updated successfully!' });
          setProducts(products.map(p => p.productId === editingProduct.productId ? response.product : p));
        } else {
          setStatus({ type: 'success', message: 'Product created successfully!' });
          fetchProducts();
        }
  
        setNewProduct({ productName: '', productDescription: '', productUrl: '', campaignId: '' });
        setTimeout(() => {
          setIsModalOpen(false);
          setEditingProduct(null);
        }, 2000);
      } catch (err) {
        console.error('Error object:', err);
        if (err?.error === 'PRODUCTS_LIMIT_REACHED') {
          setStatus({
            type: 'error',
            message: 'You have reached the maximum number of products for your current plan. Consider upgrading to add more products.',
            action: 'upgrade'
          });
        } else {
          setStatus({ type: 'error', message: err?.error || 'An error occurred while processing your request' });
        }
      }
    } else {
      setStatus({ type: 'error', message: 'Please fill in all fields' });
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      await post('deleteProducts', { uid: getValue('uid'), orgId, productId });
      setStatus({ type: 'success', message: 'Product deleted successfully!' });
      fetchProducts();
    } catch (err) {
      setStatus({ type: 'error', message: err.error || 'Failed to delete product' });
    }
  };

  const openEditModal = (product) => {
    setEditingProduct(product);
    setNewProduct({
      productName: product.productName,
      productDescription: product.productDescription,
      productUrl: product.productUrl,
      campaignId: product.campaignId
    });
    setIsModalOpen(true);
  };

  const filteredProducts = products.filter(product => {
    if (filter === 'all') return true;
    return product.campaignId === filter;
  });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!orgId) {
    return <div className="text-center py-12">No organization selected. Please select an organization first.</div>;
  }

  return (
    <div className="space-y-8 bg-gray-50 p-8">
      {/* Header Section */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h1 className="text-3xl font-semibold text-gray-800 mb-4">Products Dashboard</h1>
        <p className="text-gray-600 mb-6">Manage and track your campaign products</p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-blue-50 p-4 rounded-lg border border-blue-200">
            <h3 className="text-2xl font-semibold text-blue-700">{products.length}</h3>
            <p className="text-blue-600">Total Products</p>
          </div>
          <div className="bg-green-50 p-4 rounded-lg border border-green-200">
            <h3 className="text-2xl font-semibold text-green-700">{campaigns.length}</h3>
            <p className="text-green-600">Campaigns</p>
          </div>
          <div className="bg-purple-50 p-4 rounded-lg border border-purple-200">
            <h3 className="text-2xl font-semibold text-purple-700">{campaigns.reduce((sum, campaign) => sum + campaign.keywordCount, 0)}</h3>
            <p className="text-purple-600">Total Keywords</p>
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold text-gray-800">Your Products</h2>
        <div className="flex items-center space-x-4">
          <select
            className="bg-white border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value="all">All Campaigns</option>
            {campaigns.map(campaign => (
              <option key={campaign.id} value={campaign.id}>{campaign.name}</option>
            ))}
          </select>
          <button
            onClick={() => {
              setEditingProduct(null);
              setNewProduct({ productName: '', productDescription: '', productUrl: '', campaignId: '' });
              setIsModalOpen(true);
            }}
            className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 flex items-center"
            disabled={loading}
          >
            <Plus className="w-5 h-5 mr-2" />
            Add Product
          </button>
        </div>
      </div>

      {status && (
        <Alert 
          type={status.type} 
          onClose={() => setStatus(null)}
        >
          <div>
            {status.message}
            {status.action === 'upgrade' && (
              <button
                onClick={() => {
                  window.location.href = '/upgrade';
                }}
                className="ml-2 underline text-blue-600 hover:text-blue-800"
              >
                Upgrade now
              </button>
            )}
          </div>
        </Alert>
      )}

      {filteredProducts.length === 0 ? (
        <div className="text-center py-12 bg-white rounded-lg shadow-md">
          <AlertOctagon className="w-16 h-16 text-gray-400 mx-auto mb-4" />
          <h3 className="text-xl font-semibold text-gray-700 mb-2">No Products Found</h3>
          <p className="text-gray-500 mb-4">Try adjusting your filter or add a new product.</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {filteredProducts.map(product => (
            <div key={product.productId} className="bg-white rounded-lg shadow-md hover:shadow-lg transition-all duration-300 ease-in-out overflow-hidden">
              <div className="p-6">
                <div className="flex justify-between items-start mb-4">
                  <div className="flex items-center">
                    <Globe className="w-6 h-6 text-blue-600 mr-2" />
                    <h3 className="text-xl font-semibold text-gray-800">{product.productName}</h3>
                  </div>
                  <div className="flex space-x-2">
                    <button onClick={() => openEditModal(product)} className="text-gray-500 hover:text-blue-500" disabled={loading}>
                      <Edit className="w-5 h-5" />
                    </button>
                    <button onClick={() => handleDeleteProduct(product.productId)} className="text-gray-500 hover:text-red-500" disabled={loading}>
                      <Trash2 className="w-5 h-5" />
                    </button>
                  </div>
                </div>
                <p className="text-gray-600 mb-4">{product.productDescription}</p>
                <div className="mt-auto">
                  <a href={product.productUrl} className="text-blue-500 hover:underline text-sm" target="_blank" rel="noopener noreferrer">
                    {product.productUrl}
                  </a>
                  <div className="mt-2 text-sm text-gray-500">
                    Campaign: {campaigns.find(c => c.id === product.campaignId)?.name || 'Unknown'}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            <div className="relative bg-white w-full max-w-md m-4 p-8 rounded-lg shadow-lg">
              <button
                onClick={() => {
                  setIsModalOpen(false);
                  setEditingProduct(null);
                  setNewProduct({ productName: '', productDescription: '', productUrl: '', campaignId: '' });
                  setUrlError('');
                }}
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition duration-300 ease-in-out"
              >
                <X className="w-6 h-6" />
              </button>
              <h2 className="text-2xl font-bold mb-6">{editingProduct ? 'Edit Product' : 'Add New Product'}</h2>
              <div className="space-y-4">
                <input
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Product Name"
                  value={newProduct.productName}
                  onChange={(e) => setNewProduct({ ...newProduct, productName: e.target.value })}
                />
                <textarea
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Product Description"
                  value={newProduct.productDescription}
                  onChange={(e) => setNewProduct({ ...newProduct, productDescription: e.target.value })}
                  rows="3"
                />
                <div>
                  <input
                    className={`w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${urlError ? 'border-red-500' : ''}`}
                    placeholder="Product URL"
                    value={newProduct.productUrl}
                    onChange={(e) => {
                      setNewProduct({ ...newProduct, productUrl: e.target.value });
                      if (urlError) setUrlError('');
                    }}
                  />
                  {urlError && <p className="text-red-500 text-sm mt-1">{urlError}</p>}
                </div>
                <select
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={newProduct.campaignId}
                  onChange={(e) => setNewProduct({ ...newProduct, campaignId: e.target.value })}
                >
                  <option value="">Select Campaign</option>
                  {campaigns.map(campaign => (
                    <option key={campaign.id} value={campaign.id}>{campaign.name}</option>
                  ))}
                </select>
              </div>
              <div className="flex justify-end space-x-2 mt-6">
                <button
                  className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition duration-300 ease-in-out"
                  onClick={() => {
                    setIsModalOpen(false);
                    setEditingProduct(null);
                    setNewProduct({ productName: '', productDescription: '', productUrl: '', campaignId: '' });
                    setUrlError('');
                  }}
                  disabled={loading}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300 ease-in-out"
                  onClick={handleAddOrUpdateProduct}
                  disabled={loading}
                >
                  {editingProduct ? 'Update Product' : 'Add Product'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductsPage;