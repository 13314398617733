import React, { useEffect, useState, useRef } from 'react';
import { Check, Star, ChevronDown, ChevronUp } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import useApi from '../../hooks/api';
import { getValue } from '../../utils/userData';

const Card = ({ children, className, ...props }) => (
  <div className={`bg-white shadow-lg rounded-lg ${className}`} {...props}>{children}</div>
);

const CardHeader = ({ children, className, ...props }) => (
  <div className={`px-6 py-4 ${className}`} {...props}>{children}</div>
);

const CardContent = ({ children, className, ...props }) => (
  <div className={`px-6 py-4 ${className}`} {...props}>{children}</div>
);

const PlanSelectionPage = () => {
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isYearly, setIsYearly] = useState(false);
  const yearlyDiscount = 0.2; // 20% discount for yearly plans
  const { post, loading, error } = useApi();
  const [isLoading, setIsLoading] = useState(true);
  const [apiError, setApiError] = useState(null);
  const [userSubscription, setUserSubscription] = useState(null);
  const [openFaqIndex, setOpenFaqIndex] = useState(null);

  useEffect(() => {
    const checkSubscription = async () => {
      try {
        setIsLoading(true);
        const inviteOrgId = localStorage.getItem('inviteOrgId');
        const orgIdSelected = localStorage.getItem('orgIdSelected');
        const orgId = inviteOrgId || orgIdSelected;

        const payload = {
          uid: getValue('uid'),
        };

        if (orgId) {
          payload.orgId = orgId;
        }

        const response = await post('/check-user-subscription', payload);
        if(response){
          setUserSubscription(response);
        }
      } catch (error) {
        console.error('Error checking subscription status:', error);
      } finally {
        setIsLoading(false);
      }
    };

    checkSubscription();
  }, []);

  const plans = [
    {
      name: 'Trial',
      monthlyPrice: 0,
      features: [
        '2 Keywords Tracking',
        '2 Auto-replies/month',
        '1 Campaign',
        'Auto-replies with AI-driven enhancements',
        'Advanced Sentiment Analysis',
        'Real-time Notifications',
        'Email Support'
      ],
      color: 'bg-green-500',
      duration: '14 days',
      idealFor: 'New users who want to explore our features before committing.'
    },
    {
      name: 'Starter Plan',
      monthlyPrice: 29,
      features: [
        '10 Keywords Tracking',
        '50 Auto-replies/month',
        '2 Campaigns',
        'Auto-replies',
        'Advanced Sentiment Analysis',
        'Real-time Notifications',
        '24/7 Email and Chat Support'
      ],
      color: 'bg-blue-500',
      idealFor: 'Small businesses or individuals who need a reliable, feature-rich solution for managing a single project.'
    },
    {
      name: 'Pro Plan',
      monthlyPrice: 79,
      features: [
        '100 Keywords Tracking',
        '250 Auto-replies/month',
        '5 Campaigns',
        'Auto-replies with AI-driven enhancements',
        'Advanced Sentiment Analysis with Historical Insights',
        'Real-time Notifications',
        '24/7 Priority Support (Email, Chat, Phone)'
      ],
      color: 'bg-purple-500',
      idealFor: 'Growing businesses or mid-sized teams that require more advanced tools and increased project capacity.'
    },
    {
      name: 'Business Plan',
      monthlyPrice: 199,
      features: [
        '500 Keywords Tracking',
        '2,000 Auto-replies/month',
        '100 Campaigns',
        'Auto-replies with AI and Multilingual Support',
        'Advanced Sentiment Analysis with Predictive Models',
        'Real-time Notifications',
        '24/7 Premium Support with Dedicated Account Manager'
      ],
      color: 'bg-indigo-600',
      popular: true,
      idealFor: 'Established businesses or enterprises needing extensive capabilities and top-tier support.'
    },
    {
      name: 'Agency Plan',
      monthlyPrice: 499,
      features: [
        'Unlimited Keywords Tracking',
        '5,000 Auto-replies/month',
        'Unlimited Projects',
        'Multi-account Management',
        'Advanced Analytics and Reporting',
        '24/7 VIP Support with Dedicated Team'
      ],
      color: 'bg-gray-800',
      idealFor: 'Marketing agencies and large enterprises managing multiple brands or clients with need for customization and scalability.'
    }
  ];

  const faqs = [
    {
      question: "What happens after my trial ends?",
      answer: "After your 14-day trial ends, you'll need to choose a paid plan to continue using our services. Don't worry, we'll remind you before your trial expires."
    },
    {
      question: "Can I change my plan later?",
      answer: "Yes, you can upgrade or downgrade your plan at any time. Changes will be reflected in your next billing cycle."
    },
    {
      question: "Is there a long-term contract?",
      answer: "No, our plans are billed monthly or yearly with no long-term commitment. You can cancel anytime."
    },
    {
      question: "What payment methods do you accept?",
      answer: "We accept all major credit cards for payment."
    }
  ];

  const testimonials = [
    { name: "Priya Sharma", company: "GreenLeaf Digital", quote: "SocialSignalAI has revolutionized how we handle our social media presence. The insights are invaluable!", plan: "Pro Plan" },
    { name: "Rajesh Patel", company: "Nexus Innovations", quote: "The sentiment analysis feature alone has saved us countless hours. It's a game-changer for our brand.", plan: "Business Plan" },
    { name: "Ananya Gupta", company: "Pixel Perfect Marketing", quote: "As a growing agency, the Starter Plan gives us everything we need to deliver great results for our clients.", plan: "Starter Plan" },
    { name: "Vikram Singh", company: "TechSprint Solutions", quote: "The keyword tracking feature has helped us stay ahead of trends in our industry.", plan: "Pro Plan" },
    { name: "Neha Kapoor", company: "Bluebird Enterprises", quote: "The auto-reply feature has significantly improved our response time to customer inquiries.", plan: "Business Plan" },
    { name: "Arjun Mehta", company: "Quantum Dynamics", quote: "SocialSignalAI's analytics have given us deep insights into our social media performance.", plan: "Agency Plan" },
    { name: "Sanya Reddy", company: "Crimson Technologies", quote: "The multi-account management feature is perfect for our agency needs.", plan: "Agency Plan" },
    { name: "Karthik Nair", company: "Evergreen Solutions", quote: "We've seen a notable increase in engagement since using SocialSignalAI's tools.", plan: "Pro Plan" },
    { name: "Meera Joshi", company: "Sapphire Digital", quote: "The campaign management tools have streamlined our workflow tremendously.", plan: "Business Plan" },
    { name: "Rahul Sharma", company: "Pinnacle Innovations", quote: "The trial period convinced us of the value SocialSignalAI brings to our operations.", plan: "Starter Plan" },
    { name: "Zara Malik", company: "Zenith Marketing", quote: "The customizable reports have impressed our clients and improved our presentations.", plan: "Agency Plan" },
    { name: "Aditya Patel", company: "Nebula Systems", quote: "SocialSignalAI's customer support team has been exceptional in addressing our needs.", plan: "Business Plan" },
    { name: "Nisha Verma", company: "Pulse Digital", quote: "The real-time notifications have helped us stay on top of important social media trends.", plan: "Pro Plan" },
    { name: "Rohan Kapoor", company: "Stellar Solutions", quote: "We've been able to scale our social media efforts effortlessly with SocialSignalAI.", plan: "Agency Plan" },
    { name: "Anjali Desai", company: "Prism Analytics", quote: "The AI-driven enhancements have significantly improved our content strategy.", plan: "Business Plan" },
    { name: "Vivek Malhotra", company: "Oasis Technologies", quote: "SocialSignalAI has become an indispensable tool in our digital marketing arsenal.", plan: "Pro Plan" },
    { name: "Pooja Singhania", company: "Cascade Innovations", quote: "The multilingual support has allowed us to expand our reach to new markets.", plan: "Business Plan" },
    { name: "Siddharth Rao", company: "Vertex Digital", quote: "The predictive models have given us a competitive edge in our campaigns.", plan: "Agency Plan" },
    { name: "Tanya Khanna", company: "Mosaic Marketing", quote: "We've seen a significant ROI since implementing SocialSignalAI in our workflow.", plan: "Pro Plan" },
    { name: "Aryan Choudhury", company: "Nova Dynamics", quote: "The comprehensive features of SocialSignalAI have exceeded our expectations.", plan: "Business Plan" },
  ];

  const testimonialRef = useRef(null);

  useEffect(() => {
    const scrollTestimonials = () => {
      if (testimonialRef.current) {
        if (testimonialRef.current.scrollLeft >= testimonialRef.current.scrollWidth / 2) {
          testimonialRef.current.scrollLeft = 0;
        } else {
          testimonialRef.current.scrollLeft += 1;
        }
      }
    };

    const intervalId = setInterval(scrollTestimonials, 30);

    return () => clearInterval(intervalId);
  }, []);

  const calculateYearlyPrice = (monthlyPrice) => {
    const yearlyPrice = monthlyPrice * 12;
    const discountedPrice = yearlyPrice * (1 - yearlyDiscount);
    return Math.round(discountedPrice);
  };

  const handleSelectPlan = (planName) => {
    setSelectedPlan(planName);
  };

  const handleContinue = async () => {
    if (selectedPlan) {
      const planKey = selectedPlan.toLowerCase().replace(' plan', '');
      const billingCycle = isYearly ? 'yearly' : 'monthly';
      
      if (planKey === 'trial') {
        try {
          if (localStorage.getItem('orgIdSelected')) { 
            var organizationId = localStorage.getItem('orgIdSelected');
          } else {
            var organizationId = getValue('organizationId');
          }
          const response = await post('/startTrial', {
            uid: getValue('uid'),
            email: getValue('email'),
            organizationId: organizationId
          });
          if (response.message === "Trial started successfully") {
            navigate('/dashboard');
          } else {
            console.error('Error starting trial');
            setApiError('Error starting trial. Please try again.');
          }
        } catch (err) {
          console.error('Error starting trial:', err);
          setApiError('Error starting trial. Please try again.');
        }
      } else {
        try {
          const response = await post('/create-checkout-session', {
            uid: getValue('uid'),
            email: getValue('email'),
            plan: planKey,
            billingCycle: billingCycle,
            organizationId: getValue('organizationId')
          });

          if (response.url) {
            window.location.href = response.url;
          } else {
            console.error('No checkout URL received');
            setApiError('Error creating checkout session. Please try again.');
          }
        } catch (err) {
          console.error('Error creating checkout session:', err);
          setApiError('Error creating checkout session. Please try again.');
        }
      }
    }
  };

  const togglePricing = () => {
    setIsYearly(!isYearly);
  };

  const isTrialDisabled = userSubscription ? 
    (userSubscription.subscription !== 'none' && 
    userSubscription.subscription !== 'trial') || 
    (userSubscription.subscription === 'trial' && !userSubscription.isTrialExpired)
    : false;

  const isPlanDisabled = (planName) => {
    if (!userSubscription) return false;
    if (planName === 'Trial') {
      return isTrialDisabled || userSubscription.subscription === 'trialing';
    }
    return userSubscription.subscription === planName.toLowerCase().replace(' plan', '') ||
           userSubscription.subscription === 'trialing';
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="text-center">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <p className="mt-2 text-lg text-gray-600">Checking subscription status...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
            Choose Your Subscription Plan
          </h2>
          <p className="mt-4 text-xl text-gray-600">
            Select the plan that best fits your business needs
          </p>
        </div>

        <div className="mt-12 flex justify-center">
          <div className="relative self-center rounded-full bg-gray-200 p-1">
            <button
              className={`${
                !isYearly ? 'bg-white shadow-sm' : ''
              } relative inline-flex items-center rounded-full py-2 px-4 text-sm font-medium text-gray-700 transition-colors duration-300 focus:outline-none`}
              onClick={togglePricing}
            >
              Monthly
            </button>
            <button
              className={`${
                isYearly ? 'bg-white shadow-sm' : ''
              } relative inline-flex items-center rounded-full py-2 px-4 text-sm font-medium text-gray-700 transition-colors duration-300 focus:outline-none`}
              onClick={togglePricing}
            >
              Yearly
            </button>
          </div>
        </div>

        <div className="mt-8 text-center">
          <p className="text-lg font-medium text-indigo-600">
            {isYearly ? 'Save 20% with yearly billing' : 'Switch to yearly billing for 20% discount'}
          </p>
        </div>

        <div className="mt-12 flex justify-center">
          <div className="space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:gap-6 lg:max-w-5xl mx-auto">
            {plans.filter(plan => plan.name !== 'Trial').map((plan) => (
              <Card
                key={plan.name}
                className={`relative flex flex-col ${
                  plan.popular ? 'border-2 border-indigo-500 shadow-xl' : ''
                } ${
                  selectedPlan === plan.name ? 'ring-2 ring-indigo-500' : ''
                } ${
                  isPlanDisabled(plan.name) ? 'opacity-50 cursor-not-allowed' : ''
                } transform transition duration-500 hover:scale-105`}
              >
                <CardHeader>
                  <h3 className="text-2xl font-semibold text-gray-900">{plan.name}</h3>
                  {plan.popular && (
                    <span className="absolute top-0 right-0 px-3 py-1 bg-indigo-500 text-white text-sm font-semibold rounded-bl-lg rounded-tr-lg flex items-center">
                      <Star className="w-4 h-4 mr-1" /> POPULAR
                    </span>
                  )}
                  <p className="mt-4 text-4xl font-bold text-gray-900">
                    ${isYearly && plan.name !== 'Trial' ? calculateYearlyPrice(plan.monthlyPrice) : plan.monthlyPrice}
                    <span className="text-xl font-normal text-gray-500">
                      {plan.name === 'Trial' ? `/${plan.duration}` : `/${isYearly ? 'year' : 'month'}`}
                    </span>
                  </p>
                  {isYearly && plan.name !== 'Trial' && (
                    <p className="mt-2 text-sm text-green-600">
                      Save ${Math.round(plan.monthlyPrice * 12 * yearlyDiscount)} per year
                    </p>
                  )}
                </CardHeader>
                <CardContent className="flex-grow">
                  <ul className="mt-6 space-y-4">
                    {plan.features.map((feature) => (
                      <li key={feature} className="flex items-start">
                        <div className={`flex-shrink-0 ${plan.color} rounded-full p-1`}>
                          <Check className="w-4 h-4 text-white" />
                        </div>
                        <p className="ml-3 text-sm text-gray-700">{feature}</p>
                      </li>
                    ))}
                  </ul>
                  <p className="mt-6 text-sm text-gray-500">{plan.idealFor}</p>
                </CardContent>
                <div className="p-6">
                  <button
                    onClick={() => handleSelectPlan(plan.name)}
                    disabled={isPlanDisabled(plan.name)}
                    className={`w-full py-3 px-4 border border-transparent rounded-md shadow-sm text-lg font-medium text-white ${plan.color} hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200 ${
                      (selectedPlan === plan.name || isPlanDisabled(plan.name)) ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                  >
                    {selectedPlan === plan.name ? 'Selected' : (isPlanDisabled(plan.name) ? (plan.name === 'Trial' && userSubscription?.subscription === 'trialing' ? 'Trial Active' : 'Current Plan') : 'Select Plan')}
                  </button>
                </div>
              </Card>
            ))}
          </div>
        </div>

        <div className="mt-10 text-center">
          <button
            onClick={handleContinue}
            disabled={!selectedPlan || loading}
            className={`inline-flex items-center px-8 py-3 border border-transparent text-lg font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200 ${
              !selectedPlan || loading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {loading ? 'Processing...' : `Continue with ${selectedPlan || 'selected plan'}`}
          </button>
          {apiError && <p className="mt-2 text-red-600">{apiError}</p>}
        </div>

        {/* Testimonials Section */}
        <div className="mt-16 max-w-7xl mx-auto">
          <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-8">
            What Our Customers Say
          </h2>
          <div 
            ref={testimonialRef}
            className="overflow-hidden whitespace-nowrap"
            style={{ maskImage: 'linear-gradient(to right, transparent, black 10%, black 90%, transparent)' }}
          >
            <div className="inline-block animate-marquee">
              {testimonials.concat(testimonials).map((testimonial, index) => (
                <div key={index} className="inline-block w-80 mx-4 align-top whitespace-normal">
                  <div className="bg-white shadow-lg rounded-lg overflow-hidden h-full">
                    <div className="px-6 py-8">
                      <div className="text-xl font-semibold text-indigo-600 mb-2">{testimonial.plan}</div>
                      <p className="text-gray-700 mb-4">"{testimonial.quote}"</p>
                      <div className="font-medium text-gray-900">{testimonial.name}</div>
                      <div className="text-gray-600">{testimonial.company}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* FAQ Section */}
        <div className="mt-16 max-w-3xl mx-auto">
          <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-8">
            Frequently Asked Questions
          </h2>
          <dl className="space-y-6">
            {faqs.map((faq, index) => (
              <div key={index} className="bg-white shadow overflow-hidden rounded-lg">
                <dt className="text-lg">
                  <button
                    onClick={() => setOpenFaqIndex(openFaqIndex === index ? null : index)}
                    className="flex justify-between w-full px-4 py-5 text-left text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  >
                    <span className="font-medium">{faq.question}</span>
                    <span className="ml-6 flex-shrink-0">
                      {openFaqIndex === index ? (
                        <ChevronUp className="h-6 w-6 text-indigo-500" />
                      ) : (
                        <ChevronDown className="h-6 w-6 text-gray-400" />
                      )}
                    </span>
                  </button>
                </dt>
                {openFaqIndex === index && (
                  <dd className="px-4 py-5 bg-gray-50">
                    <p className="text-base text-gray-700">{faq.answer}</p>
                  </dd>
                )}
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default PlanSelectionPage;