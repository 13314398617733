import React, { useState } from 'react';
import { X, Menu, ArrowRight, Check, ChevronDown, Facebook, Twitter, Linkedin, ArrowLeft } from 'lucide-react';
import dashboardImage from './images/dashboard.png'
import { useNavigate, Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import avatar1 from './images/1.jpg';
import avatar2 from './images/2.jpg';
import avatar3 from './images/3.jpg';
import avatar4 from './images/4.jpg';
import avatar5 from './images/5.jpg';
import avatar6 from './images/6.jpg';
import avatar7 from './images/7.jpeg';
import avatar8 from './images/8.jpeg';
import avatar9 from './images/9.jpeg';
import avatar10 from './images/10.jpg';
import dashboard from './images/dashboardedit.png';
import keywords from './images/keywords.png';
import settingsImage from './images/settings.png';
import senitimentanalysis from './images/senitimentanalysis.png';
import engagementtracker from './images/engagementtracker.png';
import alert from './images/alert.png';
import { CheckCircle, Users, Zap, TrendingUp, MessageCircle, Target } from 'lucide-react';
import {  useEffect, useRef } from 'react';


const GradientLogo = () => (
  <Link to="/">
    <h1 className="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-indigo-600">
      SocialSignalAI
    </h1>
  </Link>
);




const Avatar = ({ letter, color }) => (
  <div className={`w-8 h-8 rounded-full ${color} flex items-center justify-center text-white font-semibold text-lg`}>
    {letter}
  </div>
);

const Conversation = ({ question, answer, questionUser, answerUser, questionTime, answerTime }) => (
  <div className="bg-white rounded-lg shadow-md p-6 mb-6">
    <div className="flex items-start space-x-3 mb-4">
      <Avatar letter={questionUser[0]} color="bg-blue-500" />
      <div className="flex-grow">
        <div className="flex items-center justify-between mb-1">
          <span className="font-semibold text-gray-800">{questionUser}</span>
          <span className="text-sm text-gray-500">{questionTime}</span>
        </div>
        <p className="text-gray-800">{question}</p>
      </div>
    </div>
    <div className="ml-11 pl-6 border-l-2 border-gray-200">
      <div className="flex items-start space-x-3">
        <Avatar letter={answerUser[0]} color="bg-green-500" />
        <div className="flex-grow">
          <div className="flex items-center justify-between mb-1">
            <span className="font-semibold text-gray-800">{answerUser}</span>
            <span className="text-sm text-gray-500">{answerTime}</span>
          </div>
          <p className="text-gray-800">{answer}</p>
        </div>
      </div>
    </div>
  </div>
);



const TestimonialSection = () => {
  const testimonials = [
    { name: "Priya Sharma", company: "GreenLeaf Digital", quote: "SocialSignalAI has revolutionized how we handle our social media presence. The insights are invaluable!", plan: "Pro Plan" },
    { name: "Rajesh Patel", company: "Nexus Innovations", quote: "The sentiment analysis feature alone has saved us countless hours. It's a game-changer for our brand.", plan: "Business Plan" },
    { name: "Ananya Gupta", company: "Pixel Perfect Marketing", quote: "As a growing agency, the Starter Plan gives us everything we need to deliver great results for our clients.", plan: "Starter Plan" },
    { name: "Vikram Singh", company: "TechSprint Solutions", quote: "The keyword tracking feature has helped us stay ahead of trends in our industry.", plan: "Pro Plan" },
    { name: "Neha Kapoor", company: "Bluebird Enterprises", quote: "The auto-reply feature has significantly improved our response time to customer inquiries.", plan: "Business Plan" },
    { name: "Arjun Mehta", company: "Quantum Dynamics", quote: "SocialSignalAI's analytics have given us deep insights into our social media performance.", plan: "Agency Plan" },
    { name: "Sanya Reddy", company: "Crimson Technologies", quote: "The multi-account management feature is perfect for our agency needs.", plan: "Agency Plan" },
    { name: "Karthik Nair", company: "Evergreen Solutions", quote: "We've seen a notable increase in engagement since using SocialSignalAI's tools.", plan: "Pro Plan" },
    { name: "Meera Joshi", company: "Sapphire Digital", quote: "The campaign management tools have streamlined our workflow tremendously.", plan: "Business Plan" },
    { name: "Rahul Sharma", company: "Pinnacle Innovations", quote: "The trial period convinced us of the value SocialSignalAI brings to our operations.", plan: "Starter Plan" },
  ];

  const testimonialRef = useRef(null);

  useEffect(() => {
    const scrollTestimonials = () => {
      if (testimonialRef.current) {
        if (testimonialRef.current.scrollLeft >= testimonialRef.current.scrollWidth / 2) {
          testimonialRef.current.scrollLeft = 0;
        } else {
          testimonialRef.current.scrollLeft += 1;
        }
      }
    };

    const intervalId = setInterval(scrollTestimonials, 30);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <section className="py-20 ">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-12 text-center">What Our Customers Say</h2>
        <div 
          ref={testimonialRef}
          className="overflow-hidden whitespace-nowrap"
          style={{ maskImage: 'linear-gradient(to right, transparent, black 10%, black 90%, transparent)' }}
        >
          <div className="inline-block animate-marquee">
            {testimonials.concat(testimonials).map((testimonial, index) => (
              <div key={index} className="inline-block w-80 mx-4 align-top whitespace-normal mb-8"> {/* Added mb-8 for bottom margin */}
                <div className="bg-white shadow-lg rounded-lg overflow-hidden h-full">
                  <div className="px-6 py-8">
                    <div className="text-xl font-semibold text-indigo-600 mb-2">{testimonial.plan}</div>
                    <p className="text-gray-700 mb-4">"{testimonial.quote}"</p>
                    <div className="font-medium text-gray-900">{testimonial.name}</div>
                    <div className="text-gray-600">{testimonial.company}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};


const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const scrollToComparison = (e) => {
    e.preventDefault();
    const comparisonSection = document.getElementById('comparison');
    if (comparisonSection) {
      comparisonSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToFAQ = (e) => {
    e.preventDefault();
    const faqSection = document.getElementById('faq');
    if (faqSection) {
      faqSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header className="bg-white shadow-sm fixed top-0 left-0 right-0 z-50">
      <nav className="container mx-auto px-4 py-4 flex flex-wrap justify-between items-center">
        <div className="flex items-center space-x-6">
          <GradientLogo />

          <div className="hidden md:flex space-x-6">
            <Link to="/features" className="text-gray-600 hover:text-gray-900 text-sm">Features</Link>
            <a href="#comparison" className="text-gray-600 hover:text-gray-900 text-sm">Compare</a>
            <Link to="/pricing" className="text-gray-600 hover:text-gray-900 text-sm">Pricing</Link>
            <a href="#faq" className="text-gray-600 hover:text-gray-900 text-sm">FAQ</a>
            <a href="https://tally.so/r/31v7vg" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-gray-900 text-sm">Contact Us</a>
          </div>
        </div>

        <div className="flex items-center space-x-2 sm:space-x-4">
          <Link to="/auth" className="hidden md:inline-block text-gray-600 hover:text-gray-900 text-sm">Log in</Link>
          {/* <a 
            href="https://calendly.com/socialsignalai/30min" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="bg-indigo-600 text-white px-3 py-1 sm:px-4 sm:py-2 rounded-full text-xs sm:text-sm hover:bg-indigo-700 transition duration-300"
          >
            Request a Demo →
          </a> */}

           <Link 
            to="/auth?register" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="bg-indigo-600 text-white px-3 py-1 sm:px-4 sm:py-2 rounded-full text-xs sm:text-sm hover:bg-indigo-700 transition duration-300"
          >
            Create an Account
          </Link>
          <button
            className="md:hidden text-gray-600 hover:text-gray-900"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <Menu className="h-6 w-6" />
          </button>
        </div>
      </nav>

      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="md:hidden absolute top-full left-0 right-0 bg-white shadow-md">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <Link to="/features" className="block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50">Features</Link>
            <a href="#comparison" onClick={scrollToComparison} className="block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50">Compare</a>
            <Link to="/pricing" className="block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50">Pricing</Link>
            <Link to="/auth" className="block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50">Log in</Link>
            <a href="#faq" onClick={scrollToFAQ} className="block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50">FAQ</a>
            <a href="https://tally.so/r/31v7vg" target="_blank" rel="noopener noreferrer" className="block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50">Contact Us</a>
          </div>
        </div>
      )}
    </header>
  );
};

const Hero = () => {
  const navigate = useNavigate();
  const avatars = [avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, avatar7, avatar8, avatar9, avatar10];

  return (
    <section className="pt-24 sm:pt-32 pb-16 sm:pb-24 bg-gradient-to-b from-indigo-50 via-white to-white overflow-hidden">
      <div className="container mx-auto px-4 relative">
        <div className="flex flex-col lg:flex-row items-center mb-16 sm:mb-20">
          <div className="lg:w-1/2 mb-12 lg:mb-0 z-10">
            <motion.h1 
              className="text-5xl sm:text-6xl lg:text-7xl font-bold text-gray-900 mb-6"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Turn Social Chatter into <span className="text-indigo-600 relative">
                Growth
                <svg className="absolute w-full h-3 -bottom-2 left-0 text-yellow-400" viewBox="0 0 100 10" preserveAspectRatio="none">
                  <path d="M0 5 Q 25 0, 50 5 Q 75 10, 100 5" stroke="currentColor" strokeWidth="3" fill="none"/>
                </svg>
              </span>
            </motion.h1>
            <motion.p 
              className="text-xl text-gray-600 mb-8"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              SocialSignal AI helps your product engage, reply, find leads, and track mentions all in one tool.
            </motion.p>
            <motion.div 
              className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <button 
                onClick={() => navigate('/auth?register')} 
                className="bg-indigo-600 text-white px-8 py-4 rounded-full text-lg font-semibold hover:bg-indigo-700 transition duration-300 flex items-center justify-center group w-full sm:w-auto"
              >
                Sign Up Now
                <ArrowRight className="ml-2 h-5 w-5 transform group-hover:translate-x-1 transition-transform" />
              </button>
            </motion.div>
            <motion.div 
              className="mt-8 flex flex-col sm:flex-row items-center justify-center sm:justify-start space-y-4 sm:space-y-0 sm:space-x-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              <span className="flex items-center text-sm text-gray-500">
                <Check className="h-5 w-5 text-green-500 mr-2" />
                Cancel anytime
              </span>
              <span className="flex items-center text-sm text-gray-500">
                <Check className="h-5 w-5 text-green-500 mr-2" />
                Premium support
              </span>
            </motion.div>
          </div>
          <div className="lg:w-1/2 relative">
            <motion.div 
              className="relative z-10"
              initial={{ opacity: 0, scale: 0.9, rotate: -5 }}
              animate={{ opacity: 1, scale: 1, rotate: 0 }}
              transition={{ duration: 0.5 }}
            >
              <img
                src={dashboardImage}
                alt="SocialSignalAI Dashboard"
                className="w-full rounded-lg shadow-2xl"
              />
              
              {/* Stat Stickers */}
              <StatSticker
                icon={<Target className="w-6 h-6 text-white" />}
                stat="95%"
                description="Lead Accuracy"
                className="absolute -top-6 -left-6 bg-gradient-to-br from-blue-500 to-indigo-600"
              />
              <StatSticker
                icon={<Zap className="w-6 h-6 text-white" />}
                stat="10x"
                description="Faster Engagement"
                className="absolute top-1/4 -right-6 bg-gradient-to-br from-yellow-400 to-orange-500"
              />
              <StatSticker
                icon={<TrendingUp className="w-6 h-6 text-white" />}
                stat="3x"
                description="Faster Growth"
                className="absolute -bottom-6 left-1/4 bg-gradient-to-br from-green-400 to-emerald-600"
              />
            </motion.div>
            <div className="absolute -bottom-16 -right-16 w-64 h-64 bg-indigo-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
            <div className="absolute -top-16 -left-16 w-64 h-64 bg-yellow-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
          </div>
        </div>
        
        {/* Growth hacker count and images */}
        <motion.div 
          className="text-center relative z-10"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.8 }}
        >
          <div className="flex justify-center mb-4">
            <div className="flex -space-x-2 overflow-hidden">
              {avatars.map((avatar, i) => (
                <img
                  key={i}
                  className="inline-block h-10 w-10 rounded-full ring-2 ring-white"
                  src={avatar}
                  alt={`User ${i + 1}`}
                />
              ))}
            </div>
          </div>
          <div className="text-center">
            <div className="flex justify-center mb-2">
              {[...Array(5)].map((_, i) => (
                <svg key={i} className="h-6 w-6 text-yellow-400 animate-pulse" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
              ))}
            </div>
            <p className="text-lg text-gray-600 font-semibold">Trusted by 500+ growth hackers</p>
          </div>
        </motion.div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-white to-transparent"></div>
    </section>
  );
};

const StatSticker = ({ icon, stat, description, className }) => (
  <motion.div 
    className={`p-3 rounded-2xl shadow-lg flex flex-col items-center justify-center w-24 h-24 ${className}`}
    initial={{ opacity: 0, scale: 0.5, rotate: -10 }}
    animate={{ opacity: 1, scale: 1, rotate: 0 }}
    transition={{ duration: 0.5, delay: 1 }}
    whileHover={{ scale: 1.05, rotate: 5 }}
  >
    <div className="mb-1">{icon}</div>
    <p className="text-lg font-bold text-white">{stat}</p>
    <p className="text-xs text-white text-center">{description}</p>
  </motion.div>
);

const Features = () => (
  <section className="py-24 sm:py-32 bg-white relative -mt-24">
    <div className="container mx-auto px-4">
      <h2 className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-16 text-center text-gray-900">
        Elevate Your <span className="text-indigo-600">Social Strategy</span>
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10">
        <FeatureCard
          icon={<svg className="w-12 h-12 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path></svg>}
          title="Smart Keyword Tracking"
          description="Stay on top of conversations about your brand, products, and competitors across multiple platforms in real-time."
        />
        <FeatureCard
          icon={<svg className="w-12 h-12 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>}
          title="AI-Powered Engagement"
          description="Connect with your audience using smart, context-aware responses that feel natural and drive conversations."
        />
        <FeatureCard
          icon={<svg className="w-12 h-12 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2m0 0V9a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path></svg>}
          title="Sentiment Analysis"
          description="Gain deeper insights into how people feel about your brand, helping you tailor your engagement strategy."
        />
        <FeatureCard
          icon={<svg className="w-12 h-12 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path></svg>}
          title="Smart Lead Capture"
          description="Turn social interactions into valuable leads with our intelligent lead capture system, growing your customer base organically."
        />
      </div>
    </div>
  </section>
);

const FeatureCard = ({ icon, title, description }) => (
  <div className="bg-white rounded-2xl shadow-xl hover:shadow-2xl transition-all duration-300 overflow-hidden group hover:-translate-y-2">
    <div className="p-8">
      <div className="w-20 h-20 bg-indigo-100 rounded-full flex items-center justify-center mb-8 mx-auto">
        {icon}
      </div>
      <h3 className="text-2xl font-bold mb-4 text-gray-900 group-hover:text-indigo-600 transition-colors duration-300">{title}</h3>
      <p className="text-gray-600 group-hover:text-gray-800 transition-colors duration-300">{description}</p>
    </div>
  </div>
);

const ComparisonSection = () => (
  <section id="comparison" className="py-24 sm:py-32 relative overflow-hidden bg-white">
    <div className="container mx-auto px-4 relative z-10">
      <div className="relative">
        <h2 className="text-4xl sm:text-5xl font-bold mb-16 text-center text-gray-900">
          Boost Your Growth with <span className="text-indigo-600">SocialSignalAI</span>
        </h2>
        <div className="flex flex-col lg:flex-row items-stretch justify-center gap-8">
          <ComparisonCard
            title="Without SocialSignalAI"
            items={[
              "Time-consuming manual social media monitoring",
              "Slow and tedious keyword tracking",
              "Delayed responses to potential leads",
              "Limited insights from social interactions",
              "Inconsistent engagement strategy",
              "Hours spent on repetitive tasks",
            ]}
            isPositive={false}
          />
          <div className="hidden lg:flex items-center justify-center w-16">
            <div className="w-16 h-16 bg-indigo-600 rounded-full text-white text-2xl font-bold flex items-center justify-center transform ">
              <ArrowRight className="w-8 h-8" />
            </div>
          </div>
          <ComparisonCard
            title="With SocialSignalAI"
            items={[
              "Automated social listening across platforms",
              "Real-time keyword tracking and alerts",
              "Instant AI-powered responses to leads",
              "Deep insights and sentiment analysis",
              "Consistent, data-driven engagement strategy",
              "Save hours daily on social media management",
            ]}
            isPositive={true}
          />
        </div>
      </div>
    </div>
  </section>
);

const ComparisonCard = ({ title, items, isPositive }) => (
  <div className={`bg-white p-8 sm:p-10 rounded-2xl shadow-xl ${isPositive ? 'border-t-8 border-indigo-600' : 'border-t-8 border-gray-300'} w-full max-w-xl transform transition-all duration-300 hover:scale-105 hover:shadow-2xl`}>
    <h3 className={`text-2xl sm:text-3xl font-bold mb-8 ${isPositive ? 'text-indigo-600' : 'text-gray-700'}`}>{title}</h3>
    <ul className="space-y-6">
      {items.map((item, index) => (
        <li key={index} className="flex items-start">
          <span className={`mr-4 mt-1 ${isPositive ? 'text-green-500' : 'text-red-500'}`}>
            {isPositive ? (
              <Check className="w-6 h-6" />
            ) : (
              <X className="w-6 h-6" />
            )}
          </span>
          <span className="text-gray-600 text-lg">{item}</span>
        </li>
      ))}
    </ul>
    {isPositive && (
      <div className="mt-8 text-center">
        <span className="inline-block bg-indigo-100 text-indigo-800 px-4 py-2 rounded-full text-sm font-semibold">
          Supercharge Your Growth Potential
        </span>
      </div>
    )}
  </div>
);

const WorkflowSection = () => {
  return (
    <section className="py-24 sm:py-32 bg-white">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto text-center mb-16">
          <h2 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-4">
            Simplify Your Social Media Strategy
          </h2>
          <p className="text-xl text-gray-600">
            SocialSignalAI adapts to your unique needs, providing tailored insights and recommendations to optimize your social media presence and engagement.
          </p>
        </div>

        <div className="flex flex-col lg:flex-row items-center justify-between space-y-12 lg:space-y-0 lg:space-x-12">
          <div className="lg:w-1/2">
            <img 
              src={dashboard}
              alt="SocialSignalAI Dashboard" 
              className="w-full object-contain rounded-lg"
            />
          </div>

          <div className="lg:w-1/2 space-y-8">
            {[
              { title: "Customizable Keyword Tracking", description: "Focus on the conversations that matter most to your brand with tailored keyword monitoring." },
              { title: "Multi-Platform Monitoring", description: "Keep a pulse on discussions across various social media platforms from one central dashboard." },
              { title: "AI-Powered Engagement Suggestions", description: "Get smart recommendations for timely and relevant responses that resonate with your audience." }
            ].map((feature, index) => (
              <div key={index} className="flex items-start">
                <CheckCircle className="w-6 h-6 text-indigo-600 mt-1 mr-4 flex-shrink-0" />
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">
                    {feature.title}
                  </h3>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

const WorkflowSectionOne = () => (
  <section className="py-24 sm:py-32 bg-gray-50">
    <div className="container mx-auto px-4 flex flex-col lg:flex-row items-center justify-end">
      <div className="lg:w-2/3 mb-12 lg:mb-0">
        <img src={senitimentanalysis}
          alt="CRM Customization" className="w-full h-auto rounded-lg object-contain max-h-[1000px]" />
      </div>
      <div className="lg:w-1/3 lg:pl-16">
        <h2 className="text-3xl sm:text-4xl font-bold mb-4 sm:mb-6 text-gray-800">Tailored for Your Growth Journey</h2>
        <p className="text-base sm:text-lg text-gray-600 mb-4 sm:mb-6 leading-relaxed">SocialSignalAI adapts to your specific needs, providing custom insights and recommendations to optimize your strategy and maximize impact.</p>
        <ul className="space-y-2 sm:space-y-4 text-sm sm:text-base text-gray-600">
          <li className="flex items-center">
            <svg className="w-4 h-4 sm:w-5 sm:h-5 text-indigo-600 mr-2" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd"
                d="M16.707 7.293a1 1 0 00-1.414 0L9 13.586l-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7a1 1 0 000-1.414z"
                clipRule="evenodd" />
            </svg>
            Customizable insights
          </li>
          <li className="flex items-center">
            <svg className="w-4 h-4 sm:w-5 sm:h-5 text-indigo-600 mr-2" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd"
                d="M16.707 7.293a1 1 0 00-1.414 0L9 13.586l-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7a1 1 0 000-1.414z"
                clipRule="evenodd" />
            </svg>
            Multi-platform tracking
          </li>
          <li className="flex items-center">
            <svg className="w-4 h-4 sm:w-5 sm:h-5 text-indigo-600 mr-2" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd"
                d="M16.707 7.293a1 1 0 00-1.414 0L9 13.586l-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7a1 1 0 000-1.414z"
                clipRule="evenodd" />
            </svg>
            Flexible and scalable solutions
          </li>
        </ul>
      </div>
    </div>
  </section>
);

const SalesProcessSection = () => {
  const [activeTab, setActiveTab] = useState('keyword-tracking');
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    setImageLoaded(false);
  }, [activeTab]);

  const tabs = [
    {
      id: 'keyword-tracking',
      title: 'Keyword Tracking',
      content: 'Monitor brand mentions, competitor activity, and industry trends across multiple platforms in real-time. Our AI also suggests relevant keywords to expand your tracking scope.',
      image: keywords,
    },
    {
      id: 'engagement',
      title: 'AI-Powered Engagement',
      content: 'Engage with your audience effectively using our AI-generated, context-aware response suggestions. Our AI intelligently plugs your product into relevant conversations, capturing leads for further follow-ups and nurturing.',
      image: engagementtracker,
    },
    {
      id: 'sentiment-analysis',
      title: 'Sentiment Analysis',
      content: 'Gain deep insights into public perception of your brand with our advanced natural language processing algorithms.',
      image: senitimentanalysis,
    },
    {
      id: 'alerting',
      title: 'Real-time Alerting',
      content: 'Stay informed with instant notifications for critical mentions, trends, or sentiment shifts affecting your brand.',
      image: alert,
    },
    {
      id: 'team-management',
      title: 'Multi-Org Team Management',
      content: 'Efficiently manage multiple teams across organizations with role-based access control and plan sharing capabilities.',
      image: settingsImage,
    },
  ];

  return (
    <section className="py-24 ">
      <div className="container mx-auto px-4">
        <motion.h2 
          className="text-4xl font-bold mb-16 text-center text-gray-900"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Unlock Social Media Intelligence
        </motion.h2>
        <div className="flex flex-col lg:flex-row items-start space-y-8 lg:space-y-0 lg:space-x-12">
          <div className="w-full lg:w-1/3">
            {tabs.map((tab) => (
              <motion.button
                key={tab.id}
                className={`w-full text-left p-6 mb-4 rounded-xl transition-all duration-300 ${
                  activeTab === tab.id 
                    ? 'bg-indigo-600 text-white shadow-lg' 
                    : 'bg-white text-gray-700 hover:bg-indigo-50 hover:text-indigo-600'
                }`}
                onClick={() => setActiveTab(tab.id)}
                whileHover={{ scale: 1.03, translateX: 5 }}
                whileTap={{ scale: 0.98 }}
              >
                <div className="flex items-start">
                  <ChevronRight className={`w-6 h-6 mr-3 mt-1 transform transition-transform duration-300 ${activeTab === tab.id ? 'rotate-90 text-white' : 'text-indigo-400'}`} />
                  <div>
                    <h3 className={`text-lg font-semibold mb-2 ${activeTab === tab.id ? 'text-white' : 'text-indigo-600'}`}>{tab.title}</h3>
                    <p className={`text-sm ${activeTab === tab.id ? 'text-indigo-100' : 'text-gray-600'}`}>{tab.content}</p>
                  </div>
                </div>
              </motion.button>
            ))}
          </div>
          <div className="w-full lg:w-2/3">
            <div className="relative h-96 md:h-[40rem] group perspective-1000">
              {/* Bottom stack layer */}
              <div className="absolute inset-0 bg-gradient-to-r from-blue-400 to-indigo-500 transform -rotate-3 translate-x-4 translate-y-4 rounded-lg shadow-xl group-hover:rotate-0 group-hover:translate-x-5 group-hover:translate-y-5 transition-all duration-300"></div>
              
              {/* Top stack layer with white background */}
              <AnimatePresence mode="wait">
                <motion.div
                  key={activeTab}
                  className="absolute inset-0 bg-white rounded-lg shadow-2xl overflow-hidden"
                  initial={{ opacity: 0, rotate: 1, scale: 0.95 }}
                  animate={{ opacity: 1, rotate: 0, scale: 1 }}
                  exit={{ opacity: 0, rotate: -1, scale: 0.95 }}
                  transition={{ duration: 0.5 }}
                >
                  {!imageLoaded && (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-indigo-500"></div>
                    </div>
                  )}
                  <img
                    src={tabs.find(tab => tab.id === activeTab).image}
                    alt={`${tabs.find(tab => tab.id === activeTab).title} dashboard`}
                    className={`w-[120%] h-[120%] object-cover p-4 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-opacity duration-300 ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}
                    onLoad={() => setImageLoaded(true)}
                  />
                </motion.div>
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const plans = [
  {
    name: 'Starter Plan',
    monthlyPrice: 29,
    features: [
      '10 Keywords Tracking',
      '50 Auto-replies/month',
      '2 Campaigns',
      'Auto-replies',
      'Advanced Sentiment Analysis',
      'Real-time Notifications',
      '24/7 Email and Chat Support'
    ],
    color: 'bg-blue-500',
    idealFor: 'Small businesses or individuals who need a reliable, feature-rich solution for managing a single project.'
  },
  {
    name: 'Pro Plan',
    monthlyPrice: 79,
    features: [
      '100 Keywords Tracking',
      '250 Auto-replies/month',
      '5 Campaigns',
      'Auto-replies with AI-driven enhancements',
      'Advanced Sentiment Analysis with Historical Insights',
      'Real-time Notifications',
      '24/7 Priority Support (Email, Chat, Phone)'
    ],
    color: 'bg-purple-500',
    popular: true,
    idealFor: 'Growing businesses or mid-sized teams that require more advanced tools and increased project capacity.'
  },
  {
    name: 'Business Plan',
    monthlyPrice: 199,
    features: [
      '500 Keywords Tracking',
      '1,500 Auto-replies/month',
      '100 Campaigns',
      'Advanced Sentiment Analysis',
      'Advanced Notifications',
      '24/7 Premium Support with Dedicated Account Manager',
      'Multi-Account Management'
    ],
    color: 'bg-indigo-600',
    idealFor: 'Established businesses or enterprises needing extensive capabilities and top-tier support.'
  },
  {
    name: 'Agency Plan',
    monthlyPrice: 499,
    features: [
      '1000 Keywords Tracking',
      '5,000 Auto-replies/month',
      '1000 Campaigns',
      'AI-driven Enhancements',
      'Advanced Sentiment Analysis',
      'Multi-account Management',
      '24/7 VIP Support with Dedicated Team'
    ],
    color: 'bg-gray-800',
    idealFor: 'Marketing agencies and large enterprises managing multiple brands or clients with need for customization and scalability.'
  }
];

const PricingCard = ({ plan, isYearly, isActive }) => {
  const navigate = useNavigate();
  const price = isYearly ? plan.monthlyPrice * 10 : plan.monthlyPrice;
  const billingPeriod = isYearly ? '/year' : '/month';

  const handleButtonClick = () => {
    if (plan.name === 'Agency Plan' || plan.name === 'Business Plan') {
      window.open('https://calendly.com/socialsignalai/30min', '_blank');
    } else {
      navigate('/auth?register');
    }
  };

  const buttonText = plan.name === 'Agency Plan' || plan.name === 'Business Plan' 
    ? 'Book a Demo' 
    : 'Get Started Now';

  return (
    <div className={`${plan.color} text-white p-6 sm:p-8 rounded-lg w-full relative transition-all duration-300 ${
      isActive ? 'transform scale-105 shadow-2xl z-10 my-4' : 'scale-95 opacity-90'
    }`}>
      {plan.popular && (
        <div className="absolute top-0 right-0 bg-yellow-400 text-gray-900 text-xs font-bold px-2 py-1 rounded-bl-lg rounded-tr-lg">
          Most Popular
        </div>
      )}
      <h3 className="text-xl sm:text-2xl font-semibold mb-4">{plan.name}</h3>
      <p className="text-4xl sm:text-5xl font-bold mb-4">
        ${price}<span className="text-base sm:text-lg">{billingPeriod}</span>
      </p>
      {isYearly && (
        <p className="mb-2 text-sm text-yellow-300">Save ${plan.monthlyPrice * 2} annually</p>
      )}
      <p className="mb-6 sm:mb-8 text-xs sm:text-sm">{plan.idealFor}</p>
      <ul className="space-y-2 sm:space-y-4 mb-6 sm:mb-8 text-left text-sm sm:text-base">
        {plan.features.map((feature, index) => (
          <li key={index} className="flex items-center">
            <Check className="w-4 h-4 sm:w-5 sm:h-5 mr-2" />
            {feature}
          </li>
        ))}
      </ul>
      <button 
        className="bg-white text-gray-800 px-4 py-2 sm:px-6 sm:py-3 rounded-full text-sm sm:text-base font-semibold hover:bg-gray-100 transition duration-300 w-full"
        onClick={handleButtonClick}
      >
        {buttonText}
      </button>
    </div>
  );
};

const Toggle = ({ isOn, onToggle }) => (
  <div
    onClick={onToggle}
    className={`w-14 h-7 flex items-center rounded-full p-1 cursor-pointer ${isOn ? 'bg-indigo-600' : 'bg-gray-300'
      }`}
  >
    <div
      className={`bg-white w-5 h-5 rounded-full shadow-md transform transition-transform duration-300 ease-in-out ${isOn ? 'translate-x-7' : ''
        }`}
    ></div>
  </div>
);

const Pricing = () => {
  const [isYearly, setIsYearly] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const visiblePlans = plans.slice(currentIndex, currentIndex + 3);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, plans.length - 3));
  };

  return (
    <section className="py-16 sm:py-24 bg-white">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-8">Choose Your Plan</h2>
        <div className="flex justify-center items-center mb-8">
          <span className={`mr-3 ${isYearly ? 'text-gray-500' : 'text-gray-900 font-semibold'}`}>Monthly</span>
          <Toggle isOn={isYearly} onToggle={() => setIsYearly(!isYearly)} />
          <span className={`ml-3 ${isYearly ? 'text-gray-900 font-semibold' : 'text-gray-500'}`}>Yearly</span>
        </div>
        <div className="relative max-w-6xl mx-auto">
          <div className="overflow-visible">
            <div className="flex flex-col md:flex-row md:space-x-4">
              {visiblePlans.map((plan, index) => (
                <div key={plan.name} className="w-full md:w-1/3 mb-8 md:mb-0">
                  <PricingCard 
                    plan={plan} 
                    isYearly={isYearly} 
                    isActive={plan.popular}
                  />
                </div>
              ))}
            </div>
          </div>
          {plans.length > 3 && (
            <>
              <button 
                onClick={handlePrev} 
                className="absolute top-1/2 -left-4 md:-left-8 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md z-10"
                disabled={currentIndex === 0}
              >
                <ChevronLeft className={`w-6 h-6 ${currentIndex === 0 ? 'text-gray-300' : 'text-gray-600'}`} />
              </button>
              <button 
                onClick={handleNext} 
                className="absolute top-1/2 -right-4 md:-right-8 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md z-10"
                disabled={currentIndex === plans.length - 3}
              >
                <ChevronRight className={`w-6 h-6 ${currentIndex === plans.length - 3 ? 'text-gray-300' : 'text-gray-600'}`} />
              </button>
            </>
          )}
        </div>
        <div className="mt-8 flex justify-center space-x-2 md:hidden">
          {plans.slice(0, plans.length - 2).map((_, index) => (
            <button
              key={index}
              className={`w-3 h-3 rounded-full ${
                index === currentIndex ? 'bg-indigo-600' : 'bg-gray-300'
              }`}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

const FAQ = () => (
  <section id="faq" className="py-24 sm:py-32 bg-gray-50 scroll-mt-20">
    <div className="container mx-auto px-4">
      <h2 className="text-4xl sm:text-5xl font-bold mb-16 text-center text-gray-900">Your Questions, Answered</h2>
      <div className="max-w-4xl mx-auto">
        <div className="bg-white rounded-3xl shadow-2xl overflow-hidden">
          <FAQItem
            question="What exactly is SocialSignalAI?"
            answer="SocialSignalAI is your smart companion for social media growth. We use AI to help businesses like yours monitor social networks, find potential customers, and engage with them naturally across Reddit, HackerNews, and X (Twitter)."
          />
          <FAQItem
            question="How does SocialSignalAI make my life easier?"
            answer="Think of us as your social media assistant. We use clever AI to keep an eye on mentions of your keywords, understand what people are saying about you, and help you respond in a way that feels natural and builds relationships. It's like having a team of experts working 24/7 to grow your online presence."
          />
          <FAQItem
            question="Which social media platforms can I use SocialSignalAI with?"
            answer="Right now, we're focused on helping you shine on Reddit, HackerNews, and X (formerly Twitter). But we're always working on expanding, so stay tuned for more platforms in the future!"
          />
        </div>
      </div>
    </div>
  </section>
);

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 last:border-b-0">
      <button
        className="w-full px-8 py-6 text-left text-gray-800 font-semibold focus:outline-none flex items-center justify-between hover:bg-gray-50 transition-colors duration-200"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-xl sm:text-2xl">{question}</span>
        <ChevronDown className={`w-8 h-8 text-blue-500 transform transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`} />
      </button>
      {isOpen && (
        <div className="px-8 py-6 bg-gray-50">
          <p className="text-gray-600 text-lg sm:text-xl">{answer}</p>
        </div>
      )}
    </div>
  );
};

const Footer = () => (
  <footer className="bg-gray-800 text-white py-8 sm:py-12">
    <div className="container mx-auto px-4">
      <div className="grid grid-cols-2 md:grid-cols-3 gap-8">
        <div>
          <h3 className="text-base sm:text-lg font-semibold mb-4">Company</h3>
          <ul className="space-y-2">
            <li><Link to="/" className="text-sm sm:text-base hover:text-gray-300">Home</Link></li>
            <li><Link to="/features" className="text-sm sm:text-base hover:text-gray-300">Features</Link></li>
            <li><Link to="/pricing" className="text-sm sm:text-base hover:text-gray-300">Pricing</Link></li>
            <li><a href="#faq" className="text-sm sm:text-base hover:text-gray-300">FAQ</a></li>
            <li><a href="https://tally.so/r/31v7vg" target="_blank" rel="noopener noreferrer" className="text-sm sm:text-base hover:text-gray-300">Contact Us</a></li>
          </ul>
        </div>
        <div>
          <h3 className="text-base sm:text-lg font-semibold mb-4">Legal</h3>
          <ul className="space-y-2">
            <li><Link to="/privacy-policy" className="text-sm sm:text-base hover:text-gray-300">Privacy Policy</Link></li>
            <li><Link to="/terms-and-conditions" className="text-sm sm:text-base hover:text-gray-300">Terms & Conditions</Link></li>
          </ul>
        </div>
      </div>
      <div className="mt-8 pt-8 border-t border-gray-700 text-center">
        <p className="text-sm sm:text-base">&copy; 2023 SocialSignalAI. All rights reserved.</p>
      </div>
    </div>
  </footer>
);

const CallToAction = () => {
  const navigate = useNavigate();

  return (
    <section className="py-16 sm:py-24 bg-white">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl sm:text-4xl font-bold mb-4 sm:mb-6 text-gray-900">
            Ready to Amplify Your Social Presence?
          </h2>
          <p className="text-lg sm:text-xl mb-6 sm:mb-8 text-gray-700">
            Don't let valuable conversations slip by. Let SocialSignalAI be your guide to finding the perfect moments to showcase your product and connect with potential customers. It's time to turn social chatter into real growth.
          </p>
          <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
            <button 
              onClick={() => navigate('/auth?register')} 
              className="w-full sm:w-auto bg-indigo-600 text-white px-8 py-4 rounded-full text-lg font-semibold hover:bg-indigo-700 transition duration-300 flex items-center justify-center group"
            >
              Get Started Now
              <ArrowRight className="ml-2 h-5 w-5 transform group-hover:translate-x-1 transition-transform" />
            </button>
            {/* <a 
              href="https://calendly.com/socialsignalai/30min" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="w-full sm:w-auto bg-white text-indigo-600 px-6 py-3 rounded-md text-lg font-semibold border border-indigo-600 hover:bg-indigo-50 transition duration-300"
            >
              Book a Personal Demo
            </a> */}
          </div>
          <p className="mt-4 text-sm text-gray-600">
            Flexible plans to suit your business needs. Start growing today!
          </p>
        </div>
      </div>
    </section>
  );
};

const CookieDialog = () => {
  const [showDialog, setShowDialog] = useState(!localStorage.getItem('cookieConsent'));

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowDialog(false);
  };

  return (
    <>
      {showDialog && (
        <div className="fixed bottom-0 left-0 right-0 bg-gray-800 text-white p-4 flex flex-col sm:flex-row items-center justify-between">
          <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">
            <p className="text-xs sm:text-sm md:text-base">
              We use cookies to ensure you get the best experience on our website. By continuing to use this site, you agree to our use of cookies.
            </p>
            <button
              onClick={handleAccept}
              className="bg-white text-gray-800 px-4 py-2 rounded-full text-xs sm:text-sm font-semibold hover:bg-gray-100 transition duration-300 w-full sm:w-auto"
            >
              Accept
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const Home = () => {
  return (
    <div className="font-sans">
      <Header />
      <main className="pt-16 sm:pt-20">
        <Hero />
        <TestimonialSection />
        <Features />
        <ComparisonSection />
        <SalesProcessSection />
        <WorkflowSection />
        <Pricing />
        <FAQ />
        <CallToAction />
        {/* <UserConversations /> */}
      </main>
      <Footer />
      {/* <CookieDialog /> */}
    </div>
  );
};

export default Home;