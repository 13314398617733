import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="font-sans flex flex-col min-h-screen">
      <main className="flex-grow pt-16 sm:pt-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl sm:text-5xl font-bold mb-8 text-center text-gray-900">Privacy Policy</h1>
          <div className="bg-white rounded-lg shadow-lg p-6 sm:p-10">
            {/* Introduction */}
            <section className="mb-10">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">1. Introduction</h2>
              <p className="text-gray-600">
                Welcome to SocialSignalAI. We respect your privacy and are committed to protecting your personal data. This privacy policy will inform you about how we look after your personal data when you visit our website and use our services, and tell you about your privacy rights and how the law protects you.
              </p>
            </section>

            {/* Data We Collect */}
            <section className="mb-10">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">2. Data We Collect</h2>
              <p className="text-gray-600 mb-4">
                We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:
              </p>
              <ul className="list-disc list-inside text-gray-600">
                <li>Identity Data: first name, last name, username</li>
                <li>Contact Data: email address, telephone numbers</li>
                <li>Technical Data: internet protocol (IP) address, login data, browser type and version</li>
                <li>Usage Data: information about how you use our website and services</li>
                <li>Marketing and Communications Data: your preferences in receiving marketing from us and our third parties</li>
              </ul>
            </section>

            {/* How We Use Your Data */}
            <section className="mb-10">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">3. How We Use Your Data</h2>
              <p className="text-gray-600">
                We use your data to provide and improve our services, including but not limited to:
              </p>
              <ul className="list-disc list-inside text-gray-600">
                <li>Providing and managing your account</li>
                <li>Providing and managing the services you've requested</li>
                <li>Communicating with you about our services</li>
                <li>Analyzing usage of our services to improve them</li>
              </ul>
            </section>

            {/* Data Security */}
            <section className="mb-10">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">4. Data Security</h2>
              <p className="text-gray-600">
                We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorized way, altered or disclosed. We limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know.
              </p>
            </section>

            {/* Your Legal Rights */}
            <section className="mb-10">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">5. Your Legal Rights</h2>
              <p className="text-gray-600">
                Under certain circumstances, you have rights under data protection laws in relation to your personal data, including the right to request access, correction, erasure, restriction, transfer, or to object to processing.
              </p>
            </section>

            {/* Changes to This Privacy Policy */}
            <section className="mb-10">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">6. Changes to This Privacy Policy</h2>
              <p className="text-gray-600">
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date at the top of this Privacy Policy.
              </p>
            </section>

            {/* Contact Us */}
            <section className="mb-10">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">7. Contact Us</h2>
              <p className="text-gray-600">
                If you have any questions about this Privacy Policy, please contact us at privacy@socialsignalai.com.
              </p>
            </section>
          </div>
        </div>
      </main>
    </div>
  );
};

export default PrivacyPolicy;