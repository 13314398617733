import React, { useState, useEffect } from 'react';
import { Plus, Flag, Users, Calendar, MoreVertical, X, Edit, Trash2, Twitter, FileText, ToggleLeft, ToggleRight, AlertOctagon } from 'lucide-react';
import useApi from '../../hooks/api';
import { getValue } from '../../utils/userData';
import Loader from '../common/Loader';

// Add this Alert component definition
const Alert = ({ type, children, onClose }) => (
  <div className={`p-4 mb-4 rounded-md ${type === 'success' ? 'bg-green-100 border-green-400 text-green-700' : 'bg-red-100 border-red-400 text-red-700'}`}>
    <div className="flex justify-between items-center">
      <div>{children}</div>
      <button onClick={onClose} className="text-sm font-semibold">×</button>
    </div>
  </div>
);

const CampaignsPage = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [newCampaign, setNewCampaign] = useState({ name: '', description: '', teamMembers: [], campaignType: 'REDDIT', status: 'true', autoComment: false, autoPost: false });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingCampaign, setEditingCampaign] = useState(null);
  const [status, setStatus] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const { post, loading, error } = useApi();
  const orgId = localStorage.getItem('orgIdSelected');
  const [filter, setFilter] = useState('all');
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    if (orgId) {
      fetchCampaigns();
      fetchTeamMembers();
    }
  }, [orgId]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchCampaigns = async () => {
    try {
      const response = await post('/getCampaigns', {
        uid: getValue('uid'),
        orgId: orgId
      });

      const formattedCampaigns = response.map(campaign => ({
        name: campaign.campaignName,
        description: campaign.campaignDescription,
        teamMembers: campaign.teamMembers || [],
        id: campaign.campaignId,
        createdAt: new Date(parseInt(campaign.created)).toISOString(),
        uid: campaign.uid,
        orgId: campaign.orgId,
        keywordCount: campaign.keywordCount,
        productCount: campaign.productCount,
        campaignType: campaign.campaignType || 'REDDIT',
        status: campaign.status || 'true',
        autoComment: campaign.autoComment || false,
        autoPost: campaign.autoPost || false
      }));
      setCampaigns(formattedCampaigns);
    } catch (error) {
      setStatus({ type: 'error', message: 'Failed to fetch campaigns. Please try again.' });
    }
  };

  const fetchTeamMembers = async () => {
    try {
      const response = await post('/list-team-members-org-id', { uid: getValue('uid'), organizationId: orgId });
      setTeamMembers(response.members);
    } catch (error) {
      console.error('Error fetching team members:', error);
      setStatus({ type: 'error', message: 'Failed to fetch team members. Please try again.' });
    }
  };

  const handleAddOrUpdateCampaign = async () => {
    if (newCampaign.name && newCampaign.description) {
      try {
        setStatus(null); // Clear any previous status
        const endpoint = editingCampaign ? '/editCampaign' : '/addCampaign';
        const payload = {
          uid: getValue('uid'),
          orgId: orgId,
          campaignName: newCampaign.name,
          campaignDescription: newCampaign.description,
          teamMembers: newCampaign.teamMembers,
          campaignType: newCampaign.campaignType,
          status: newCampaign.status,
          autoComment: newCampaign.autoComment,
          autoPost: newCampaign.autoPost
        };
        if (editingCampaign) {
          payload.campaignId = editingCampaign.id;
        }

        const response = await post(endpoint, payload);

        if (response.message === 'CAMPAIGN_UPDATED' || response.message === 'CAMPAIGN_ADDED') {
          await fetchCampaigns();
          setStatus({ type: 'success', message: `Campaign ${editingCampaign ? 'updated' : 'created'} successfully!` });
          setTimeout(() => {
            setIsModalOpen(false);
            setStatus(null);
            setEditingCampaign(null);
            setNewCampaign({ name: '', description: '', teamMembers: [], campaignType: 'REDDIT', status: 'true', autoComment: false, autoPost: false });
          }, 2000);
        } else {
          throw new Error(response.error || `Failed to ${editingCampaign ? 'update' : 'create'} campaign`);
        }
      } catch (error) {
        console.error('Error in handleAddOrUpdateCampaign:', error);
        if (error.error === 'CAMPAIGNS_LIMIT_REACHED') {
          setStatus({
            type: 'error',
            message: 'Campaign limit reached. Please upgrade your plan to create more campaigns.',
            action: 'upgrade'
          });
        } else {
          setStatus({ type: 'error', message: error.error || `Failed to ${editingCampaign ? 'update' : 'create'} campaign. Please try again.` });
        }
        setIsModalOpen(false);
      } finally {
        // Ensure we always fetch campaigns, even if there was an error
        await fetchCampaigns();
      }
    }
  };

  const handleDeleteCampaign = async (campaignId) => {
    try {
      const response = await post('/deleteCampaign', {
        uid: getValue('uid'),
        orgId: orgId,
        campaignId: campaignId
      });

      if (response.message === 'CAMPAIGN_DELETED') {
        await fetchCampaigns();
        setStatus({ type: 'success', message: 'Campaign deleted successfully!' });
      } else {
        throw new Error('Failed to delete campaign');
      }
    } catch (error) {
      setStatus({ type: 'error', message: error.message || 'Failed to delete campaign. Please try again.' });
    }
  };

  const handleToggleStatus = async (campaign) => {
    try {
      const response = await post('/editCampaign', {
        uid: getValue('uid'),
        orgId: orgId,
        campaignId: campaign.id,
        status: campaign.status === 'true' ? 'false' : 'true'
      });

      if (response.message === 'CAMPAIGN_UPDATED') {
        await fetchCampaigns();
        setStatus({ type: 'success', message: 'Campaign status updated successfully!' });
      } else {
        throw new Error('Failed to update campaign status');
      }
    } catch (error) {
      setStatus({ type: 'error', message: error.message || 'Failed to update campaign status. Please try again.' });
    }
  };

  const openEditModal = (campaign) => {
    setEditingCampaign(campaign);
    setNewCampaign({
      name: campaign.name,
      description: campaign.description,
      teamMembers: campaign.teamMembers,
      campaignType: campaign.campaignType || 'REDDIT',
      status: campaign.status || 'true',
      autoComment: campaign.autoComment || false,
      autoPost: campaign.autoPost || false
    });
    setIsModalOpen(true);
  };

  const getTeamMemberName = (uid) => {
    const member = teamMembers.find(m => m.uid === uid);
    return member ? member.name : 'Unknown Member';
  };

  // Add this new function for the loading skeleton
  const CampaignSkeleton = () => (
    <div className="bg-white rounded-lg shadow-md p-6 animate-pulse">
      <div className="flex justify-between items-start">
        <div className="flex items-center">
          <div className="w-6 h-6 bg-gray-200 rounded-full mr-2"></div>
          <div className="h-6 bg-gray-200 rounded w-1/2"></div>
        </div>
        <div className="flex space-x-2">
          <div className="w-5 h-5 bg-gray-200 rounded"></div>
          <div className="w-5 h-5 bg-gray-200 rounded"></div>
          <div className="w-5 h-5 bg-gray-200 rounded"></div>
        </div>
      </div>
      <div className="mt-2 h-4 bg-gray-200 rounded w-3/4"></div>
      <div className="mt-4 flex items-center">
        <div className="w-4 h-4 bg-gray-200 rounded-full mr-1"></div>
        <div className="h-4 bg-gray-200 rounded w-1/4"></div>
      </div>
    </div>
  );

  const filteredCampaigns = campaigns.filter(campaign => {
    if (filter === 'all') return true;
    if (filter === 'active') return campaign.status === 'true';
    if (filter === 'inactive') return campaign.status === 'false';
    return campaign.campaignType === filter;
  });

  const handleUpgrade = async () => {
    try {
      const response = await post('/create-checkout-session', {
        uid: getValue('uid'),
        email: getValue('email'),
        plan: 'pro', // You might want to choose an appropriate plan
        billingCycle: 'monthly', // Or let the user choose
        organizationId: orgId
      });

      if (response.url) {
        // Redirect to Stripe Checkout
        window.location.href = response.url;
      } else {
        setStatus({ type: 'error', message: 'No checkout URL received' });
      }
    } catch (err) {
      console.error('Error creating checkout session:', err);
      setStatus({ type: 'error', message: err.message || 'An error occurred while creating the checkout session. Please try again.' });
    }
  };

  if (!orgId) {
    return <div className="text-center py-12">No organization selected. Please select an organization first.</div>;
  }

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="space-y-8 bg-gray-50 p-4 md:p-8">
      {/* Header Section */}
      <div className="bg-white rounded-lg shadow-md p-4 md:p-6 mb-8">
        <h1 className="text-3xl font-semibold text-gray-800 mb-4">Campaigns Dashboard</h1>
        <p className="text-gray-600 mb-6">Manage and track your marketing campaigns</p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-blue-50 p-4 rounded-lg border border-blue-200">
            <h3 className="text-2xl font-semibold text-blue-700">{campaigns.length}</h3>
            <p className="text-blue-600">Total Campaigns</p>
          </div>
          <div className="bg-green-50 p-4 rounded-lg border border-green-200">
            <h3 className="text-2xl font-semibold text-green-700">{campaigns.filter(c => c.status === 'true').length}</h3>
            <p className="text-green-600">Active Campaigns</p>
          </div>
          <div className="bg-purple-50 p-4 rounded-lg border border-purple-200">
            <h3 className="text-2xl font-semibold text-purple-700">{teamMembers.length}</h3>
            <p className="text-purple-600">Team Members</p>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold text-gray-800">Your Campaigns</h2>
        <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4">
          <select
            className="bg-white border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value="all">All Campaigns</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
            <option value="REDDIT">Reddit</option>
            <option value="TWITTER">Twitter</option>
            <option value="HackerNews">HackerNews</option>
          </select>
          <button
            onClick={() => setIsModalOpen(true)}
            className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300 flex items-center"
          >
            <Plus className="w-5 h-5 mr-2" />
            Create New Campaign
          </button>
        </div>
      </div>

      {status && (
        <Alert 
          type={status.type} 
          onClose={() => setStatus(null)}
        >
          <div className="flex items-center">
            {status.type === 'error' && <AlertOctagon className="w-5 h-5 mr-2 text-red-500" />}
            {status.message}
            {status.action === 'upgrade' && (
              <button
                onClick={() => setShowUpgradeModal(true)}
                className="ml-2 px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
              >
                Upgrade now
              </button>
            )}
          </div>
        </Alert>
      )}

      {filteredCampaigns.length === 0 ? (
        <div className="text-center py-12 bg-white rounded-lg shadow-md">
          <h3 className="text-xl font-semibold text-gray-700 mb-2">No Campaigns Found</h3>
          <p className="text-gray-500 mb-4">Try adjusting your filter or create a new campaign.</p>
          <button
            onClick={() => setIsModalOpen(true)}
            className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300"
          >
            Create New Campaign
          </button>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {filteredCampaigns.map(campaign => (
            <div 
              key={campaign.id} 
              className={`bg-white rounded-lg shadow-md hover:shadow-lg transition-all duration-300 ease-in-out overflow-hidden p-4 md:p-6 ${campaign.status === 'true' ? 'border-l-4 border-green-500' : 'border-l-4 border-red-500'}`}
            >
              <div className="flex justify-between items-start mb-4">
                <div className="flex items-center">
                  <Flag className="w-6 h-6 text-blue-600 mr-2" />
                  <h3 className="text-xl font-semibold text-gray-800">{campaign.name}</h3>
                </div>
                <div className="flex space-x-2">
                  {campaign.campaignType === 'TWITTER' ? (
                    <Twitter className="w-5 h-5 text-blue-400" />
                  ) : (
                    <FileText className="w-5 h-5 text-orange-500" />
                  )}
                  <button 
                    onClick={() => handleToggleStatus(campaign)} 
                    className={`text-gray-500 hover:${campaign.status === 'true' ? 'text-green-500' : 'text-red-500'} transition-colors duration-300`}
                  >
                    {campaign.status === 'true' ? <ToggleRight className="w-5 h-5" /> : <ToggleLeft className="w-5 h-5" />}
                  </button>
                  <button onClick={() => openEditModal(campaign)} className="text-gray-500 hover:text-blue-500">
                    <Edit className="w-5 h-5" />
                  </button>
                  <button onClick={() => handleDeleteCampaign(campaign.id)} className="text-gray-500 hover:text-red-500">
                    <Trash2 className="w-5 h-5" />
                  </button>
                </div>
              </div>
              <p className="text-gray-600 mb-6">{campaign.description}</p>
              <div className="mt-auto">
                <div className="flex items-center text-sm text-gray-500 mb-2">
                  <Calendar className="w-4 h-4 mr-1" />
                  <span>{new Date(campaign.createdAt).toLocaleDateString()}</span>
                </div>
                {campaign.teamMembers?.length > 0 && (
                  <div className="flex flex-wrap gap-2 mb-2">
                    {campaign.teamMembers?.map(uid => (
                      <span key={uid} className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                        {getTeamMemberName(uid)}
                      </span>
                    ))}
                  </div>
                )}
                <div className="flex justify-between text-sm text-gray-500 mt-2">
                  <span>{campaign.keywordCount} keywords</span>
                  <span>{campaign.productCount} products</span>
                </div>
              </div>
            </div>
          ))}
          {/* Add loading skeletons */}
          {loading && Array(2).fill().map((_, index) => (
            <CampaignSkeleton key={index} />
          ))}
        </div>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            <div className="relative bg-white w-full max-w-md m-4 p-8 rounded-lg shadow-lg">
              <button
                onClick={() => {
                  setIsModalOpen(false);
                  setEditingCampaign(null);
                  setNewCampaign({ name: '', description: '', teamMembers: [], campaignType: 'REDDIT', status: 'true', autoComment: false, autoPost: false });
                }}
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition duration-300 ease-in-out"
              >
                <X className="w-6 h-6" />
              </button>
              <h2 className="text-2xl font-bold mb-6">{editingCampaign ? 'Edit Campaign' : 'Create New Campaign'}</h2>
              <div className="space-y-4">
                <div>
                  <label htmlFor="campaign-name" className="block text-sm font-medium text-gray-700 mb-1">
                    Campaign Name
                  </label>
                  <input
                    id="campaign-name"
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Enter campaign name"
                    value={newCampaign.name}
                    onChange={(e) => setNewCampaign({ ...newCampaign, name: e.target.value })}
                  />
                </div>
                <div>
                  <label htmlFor="campaign-description" className="block text-sm font-medium text-gray-700 mb-1">
                    Campaign Description
                  </label>
                  <textarea
                    id="campaign-description"
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Enter campaign description"
                    value={newCampaign.description}
                    onChange={(e) => setNewCampaign({ ...newCampaign, description: e.target.value })}
                    rows="3"
                  />
                </div>
                <div>
                  <label htmlFor="campaign-type" className="block text-sm font-medium text-gray-700 mb-1">
                    Campaign Type
                  </label>
                  <select
                    id="campaign-type"
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={newCampaign.campaignType}
                    onChange={(e) => setNewCampaign({ ...newCampaign, campaignType: e.target.value })}
                  >
                    <option value="REDDIT">Reddit</option>
                    <option value="HackerNews">HackerNews</option>
                    <option value="TWITTER">Twitter</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="campaign-status" className="block text-sm font-medium text-gray-700 mb-1">
                    Campaign Status
                  </label>
                  <select
                    id="campaign-status"
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={newCampaign.status}
                    onChange={(e) => setNewCampaign({ ...newCampaign, status: e.target.value })}
                  >
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="team-members" className="block text-sm font-medium text-gray-700 mb-1">
                    Team Members
                  </label>
                  {teamMembers.length > 0 ? (
                    <>
                      <select
                        id="team-members"
                        multiple
                        className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        onChange={(e) => setNewCampaign({ 
                          ...newCampaign, 
                          teamMembers: Array.from(e.target.selectedOptions, option => option.value)
                        })}
                        value={newCampaign.teamMembers}
                      >
                        {teamMembers.map((member) => (
                          <option key={member.id} value={member.uid}>{member.name}</option>
                        ))}
                      </select>
                      <div className="mt-2 flex flex-wrap gap-2">
                        {newCampaign.teamMembers.map((uid) => (
                          <span key={uid} className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
                            {getTeamMemberName(uid)}
                            <button
                              onClick={() => setNewCampaign({ 
                                ...newCampaign, 
                                teamMembers: newCampaign.teamMembers.filter(memberUid => memberUid !== uid) 
                              })}
                              className="ml-1 text-blue-600 hover:text-blue-800"
                            >
                              ×
                            </button>
                          </span>
                        ))}
                      </div>
                    </>
                  ) : (
                    <p className="text-sm text-gray-500">
                      No team members available. You can add team members through the settings page.
                    </p>
                  )}
                </div>
                <div className="flex items-center space-x-4">
                  {newCampaign.campaignType !== 'TWITTER' && (
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        checked={newCampaign.autoComment}
                        onChange={(e) => setNewCampaign({ ...newCampaign, autoComment: e.target.checked })}
                        className="form-checkbox h-5 w-5 text-blue-600"
                      />
                      <span className="ml-2 text-gray-700">Auto Comment</span>
                    </label>
                  )}
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      checked={newCampaign.autoPost}
                      onChange={(e) => setNewCampaign({ ...newCampaign, autoPost: e.target.checked })}
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <span className="ml-2 text-gray-700">Auto Post</span>
                  </label>
                </div>
              </div>
              <div className="flex justify-end space-x-2 mt-6">
                <button
                  className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition duration-300 ease-in-out"
                  onClick={() => {
                    setIsModalOpen(false);
                    setEditingCampaign(null);
                    setNewCampaign({ name: '', description: '', teamMembers: [], campaignType: 'REDDIT', status: 'true', autoComment: false, autoPost: false });
                  }}
                >
                  Cancel
                </button>
                <button
                  className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  onClick={handleAddOrUpdateCampaign}
                  disabled={loading}
                >
                  {loading ? (editingCampaign ? 'Updating...' : 'Creating...') : (editingCampaign ? 'Update Campaign' : 'Create Campaign')}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showUpgradeModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg shadow-xl w-full max-w-md">
            <h3 className="text-xl font-bold mb-4">Upgrade Your Plan</h3>
            <p className="mb-4">Upgrade your plan to create more campaigns and access additional features.</p>
            <div className="flex justify-end space-x-2">
              <button
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition duration-300"
                onClick={() => setShowUpgradeModal(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
                onClick={handleUpgrade}
              >
                Proceed to Upgrade
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignsPage;