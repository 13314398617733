import React, { useState, useEffect } from 'react';
import { Layout, Plus, Key, Settings, ChevronLeft, ChevronRight, Activity, Eye, MessageSquare, Users, DollarSign, Twitter, Globe, Save, Flag, ClipboardList, BarChart2, Clock, CheckCircle, Menu, LogOut, Bell, AlertCircle, ChevronDown, X, Search, User, Calendar, Menu as MenuIcon, MessageCircle } from 'lucide-react'; // Import Calendar icon
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import SettingsPage from './settingsPage';
import CampaignsPage from './campaignsPage';
import ActivityLog from './activityLog';
import KeywordsPage from './keywordsPage';
import ProductsPage from './products';
import TasksPage from './tasksPage';
import useApi from '../../hooks/api';
import { getValue } from '../../utils/userData';
import { useNavigate } from 'react-router-dom';
import MentionsPage from './mentions';
import AlertsPage from './AlertsPage';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';
import './css/dashboard.css';
import Joyride from 'react-joyride'; // Import Joyride

const COLORS = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA600'];

const MetricCard = ({ title, value, icon, color, textColor }) => (
  <div className={`bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 ease-in-out ${color} bg-opacity-10`}>
    <div className="flex justify-between items-center mb-4">
      <h3 className="text-lg font-semibold text-gray-800">{title}</h3>
      {icon}
    </div>
    <p className={`text-3xl font-bold ${textColor}`}>{value}</p>
  </div>
);


const NavItem = ({ icon, text, onClick, active, isPremium, className }) => (
  <button
    className={`flex items-center w-full px-4 py-3 text-left rounded-lg mb-2
      transition-all duration-300 ease-in-out
      ${active
        ? 'bg-indigo-600 text-white shadow-lg'
        : 'text-gray-600 hover:bg-gray-100'
      } ${className}`}
    onClick={onClick}
  >
    <div className="flex items-center justify-between w-full">
      <div className="flex items-center">
        <div className={`${active ? 'bg-white bg-opacity-30' : 'bg-gray-200'} p-2 rounded-lg`}>
          {icon}
        </div>
        <span className={`ml-3 font-medium ${active ? 'text-white' : 'text-gray-700'}`}>
          {text}
        </span>
      </div>
      {isPremium && (
        <span className="bg-yellow-400 text-yellow-900 text-xs font-semibold px-2 py-1 rounded-full">
          PREMIUM
        </span>
      )}
    </div>
  </button>
);

const Dashboard = () => {
  const [currentPage, setCurrentPage] = useState('dashboard');
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showCreateOrgDialog, setShowCreateOrgDialog] = useState(false);
  const [newOrgName, setNewOrgName] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [access, setAccess] = useState();
  const [sentimentAnalysis, setSentimentAnalysis] = useState(null);
  const [trialStatus, setTrialStatus] = useState(null);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [focusedInput, setFocusedInput] = useState(null);
  const [topMenuOpen, setTopMenuOpen] = useState(false);
  const [bottomMenuOpen, setBottomMenuOpen] = useState(false);
  const [multiAccountManagement, setMultiAccountManagement] = useState(true);
  const navigate = useNavigate();
  const { post } = useApi();
  const uid = getValue('uid');
  const [runTour, setRunTour] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(true);

  // Define the steps for the Joyride with adjusted placements and offsets
  const steps = [
    {
      target: 'body',
      content: "Welcome to SocialSignalAI! Let's take a quick tour of your new social media command center.",
      placement: 'center',
      disableBeacon: true
    },
    {
      target: '.settings-button',
      content: "Start here: Connect your social media accounts to get the ball rolling.",
      placement: 'right',
      disableBeacon: true
    },
    {
      target: '.campaigns-button',
      content: "Create campaigns to organize your social media efforts. It's where the magic happens!",
      placement: 'right',
      disableBeacon: true
    },
    {
      target: '.products-button',
      content: "Add products you want to promote. This helps tailor your campaigns for better results.",
      placement: 'right',
      disableBeacon: true
    },
    {
      target: '.keywords-button',
      content: "Track keywords to monitor brand mentions and industry trends. Stay in the loop!",
      placement: 'right',
      disableBeacon: true
    },
    {
      target: 'body',
      content: "You're all set! Remember, we're here if you need help. Now go rock those socials!",
      placement: 'center',
      disableBeacon: true
    }
  ];

  useEffect(() => {
    if (typeof getValue('uid') === 'undefined' || getValue('uid') === null) {
      navigate('/auth');
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      if (window.innerWidth < 768) {
        setSidebarCollapsed(true);
      }
    };

    window.addEventListener('resize', handleResize);
    fetchOrganizations();
    availableFeatures();
    return () => window.removeEventListener('resize', handleResize);
  }, [navigate]);

  useEffect(() => {
    // Start the tour after a short delay to ensure all elements are rendered
    const timer = setTimeout(() => setRunTour(true), 500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    checkOnboardingStatus();
  }, []);

  const checkOnboardingStatus = async () => {
    try {
      const response = await post('/get-onboarding-status', { uid: getValue('uid') });
      setShowOnboarding(!response.data.onboardingCompleted);
    } catch (error) {
      console.error('Error checking onboarding status:', error);
    }
  };

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = ['finished', 'skipped'];

    if (finishedStatuses.includes(status)) {
      setShowOnboarding(false);
      updateOnboardingStatus();
    }
  };

  const updateOnboardingStatus = async () => {
    try {
      await post('/update-onboarding-status', { uid: getValue('uid') });
    } catch (error) {
      console.error('Error updating onboarding status:', error);
    }
  };

  const availableFeatures = async () => {
    try {
      const response = await post('/getAvailableAccess', { orgId: localStorage.getItem('orgIdSelected') });
      setAccess(response?.limit);
      setSentimentAnalysis(response?.limit?.sentimentAnalysis);
      setMultiAccountManagement(response?.limit?.multiAccountManagement);
    } catch (err) {
      console.error('Error fetching available features:', err);
      if (err.message === 'Pricing plan not found' || err.message === 'SOMETHING_WENT_WRONG') {
        // Redirect to the plan page
        navigate('/plan');
      }
    }
  };

  const fetchOrganizations = async () => {
    try {
      const response = await post('/list-organizations', { uid });
      setOrganizations(response.organizations);

      if (response.organizations.length === 0) {
        setShowCreateOrgDialog(true);
      } else {
        const storedOrgId = localStorage.getItem('orgIdSelected');
        if (storedOrgId && response.organizations.some(org => org.organizationId === storedOrgId)) {
          const storedOrg = response.organizations.find(org => org.organizationId === storedOrgId);
          setSelectedOrganization(storedOrg);
        } else {
          setSelectedOrganization(response.organizations[0]);
          localStorage.setItem('orgIdSelected', response.organizations[0].organizationId);
        }
      }
    } catch (err) {
      console.error('Error fetching organizations:', err);
      setError('Failed to fetch organizations. Please try again.');
    }
  };

  useEffect(() => {
    const fetchDashboardData = async () => {
      if (!selectedOrganization) return;

      setLoading(true);
      try {
        const response = await post('/dashboard', {
          uid,
          organizationId: selectedOrganization.organizationId,
          startDate,
          endDate
        });
        console.log('Dashboard response:', response); // Log the response
        setDashboardData(response);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching dashboard data:', err);
        setError(err.message || 'An error occurred while fetching dashboard data');
        setLoading(false);
      }
    };

    const checkSubscriptionStatus = async () => {
      if (!selectedOrganization) return;

      try {
        const response = await post('/check-user-subscription', { uid, orgId: selectedOrganization.organizationId });
        setSubscriptionStatus(response.isPremium ? 'premium' : 'free');
        setTrialStatus(response.trialStatus);
      } catch (err) {
        console.error('Error checking subscription status:', err);
      }
    };

    fetchDashboardData();
    checkSubscriptionStatus();
    checkTrialStatus();
  }, [post, uid, selectedOrganization, startDate, endDate]);

  const checkTrialStatus = async () => {
    if (!selectedOrganization) return;

    try {
      const response = await post('/check-trial-status', {
        uid: getValue('uid'),
        orgId: selectedOrganization.organizationId
      });
      setTrialStatus(response);

      if (response.trialExpired && !response.isPremium) {
        setShowUpgradeModal(true);
      }
    } catch (err) {
      console.error('Error checking trial status:', err);
    }
  };

  const handleOrganizationChange = (e) => {
    const org = organizations.find(org => org.organizationId === e.target.value);
    setSelectedOrganization(org);
    localStorage.setItem('orgIdSelected', org.organizationId);
    window.location.reload();
  };

  const handleCreateOrg = async () => {
    try {
      const response = await post('/create-organization', {
        uid: getValue('uid'),
        organizationName: newOrgName
      });

      if (response.organizationId) {
        localStorage.setItem('orgIdSelected', response.organizationId);
        setShowCreateOrgDialog(false);
        setNewOrgName('');
        fetchOrganizations();
      }
    } catch (error) {
      console.error('Error creating organization:', error);
      setError('Failed to create organization. Please try again.');
    }
  };

  const logoutUser = () => {
    localStorage.clear();
    navigate('/auth');
  };

  const renderDashboardContent = (access) => {
    if (loading) return <div className="flex justify-center items-center h-64"><ClipLoader size={50} color={"#4f46e5"} /></div>;
    if (error) return <div className="text-center py-10 text-red-500">Error: {error}</div>;
    if (!dashboardData) return <div className="text-center py-10">No data available</div>;

    const { metrics, activityOverTime, campaignPerformance, taskStatusDistribution, recentActivities, upcomingTasks } = dashboardData;

    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 animate-fade-in">
        <div className="col-span-full mb-4 flex justify-end">
          <DateRangePicker
            startDate={startDate ? moment(startDate) : null}
            startDateId="start_date_input"
            endDate={endDate ? moment(endDate) : null}
            endDateId="end_date_input"
            onDatesChange={({ startDate, endDate }) => {
              setStartDate(startDate ? startDate.format('YYYY-MM-DD') : '');
              setEndDate(endDate ? endDate.format('YYYY-MM-DD') : '');
            }}
            focusedInput={focusedInput}
            onFocusChange={focusedInput => setFocusedInput(focusedInput)}
            isOutsideRange={() => false}
            displayFormat="YYYY-MM-DD"
            numberOfMonths={1}
            showClearDates={true}
            startDatePlaceholderText="Start Date"
            endDatePlaceholderText="End Date"
            customArrowIcon={<ChevronRight className="h-5 w-5 text-gray-500" />}
            customInputIcon={<Calendar className="h-5 w-5 text-gray-500" />}
            inputIconPosition="after"
            small
            block
          />
        </div>
        {/* <MetricCard title="Total Mentions" value={metrics.totalMentions} icon={<MessageSquare className="h-6 w-6 text-blue-500" />} color="bg-blue-100" textColor="text-blue-700" /> */}
        <MetricCard title="Posts Created" value={metrics.postsCreated} icon={<Plus className="h-6 w-6 text-green-500" />} color="bg-green-100" textColor="text-green-700" />
        <MetricCard title="Comments Created" value={metrics.commentsCreated} icon={<MessageCircle className="h-6 w-6 text-purple-500" />} color="bg-purple-100" textColor="text-purple-700" />
        <MetricCard title="Keywords Tracked" value={metrics.totalKeywords} icon={<Key className="h-6 w-6 text-yellow-500" />} color="bg-yellow-100" textColor="text-yellow-700" />
        <MetricCard title="Active Campaigns" value={metrics.activeCampaigns} icon={<Activity className="h-6 w-6 text-red-500" />} color="bg-red-100" textColor="text-red-700" />

        <div className="col-span-full bg-white p-6 rounded-xl shadow-lg">
          <h3 className="text-xl font-semibold mb-4 text-gray-800">Activity Over Time</h3>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={activityOverTime}>
                <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                <XAxis dataKey="date" stroke="#888" />
                <YAxis allowDecimals={false} stroke="#888" />
                <Tooltip contentStyle={{ background: '#fff', border: 'none', boxShadow: '0 2px 8px rgba(0,0,0,0.15)' }} />
                <Legend />
                <Line type="monotone" dataKey="count" stroke="#4f46e5" strokeWidth={3} dot={{ r: 4 }} activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="col-span-full lg:col-span-2 bg-white p-6 rounded-xl shadow-lg">
          <h3 className="text-xl font-semibold mb-4 text-gray-800">Top Performing Campaigns</h3>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={campaignPerformance}>
                <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                <XAxis dataKey="name" stroke="#888" />
                <YAxis allowDecimals={false} stroke="#888" />
                <Tooltip contentStyle={{ background: '#fff', border: 'none', boxShadow: '0 2px 8px rgba(0,0,0,0.15)' }} />
                <Legend />
                <Bar dataKey="mentions" fill="#4f46e5" radius={[4, 4, 0, 0]} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="col-span-full lg:col-span-2 bg-white p-6 rounded-xl shadow-lg">
          <h3 className="text-xl font-semibold mb-4 text-gray-800">Task Status</h3>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={taskStatusDistribution}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius="80%"
                  fill="#8884d8"
                  dataKey="count"
                  label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                >
                  {taskStatusDistribution.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={['#4f46e5', '#60a5fa', '#34d399', '#fbbf24'][index % 4]} />
                  ))}
                </Pie>
                <Tooltip contentStyle={{ background: '#fff', border: 'none', boxShadow: '0 2px 8px rgba(0,0,0,0.15)' }} />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="col-span-full lg:col-span-2 bg-white p-6 rounded-xl shadow-lg">
          <h3 className="text-xl font-semibold mb-4 text-gray-800">Recent Activities</h3>
          {recentActivities.length > 0 ? (
            <ul className="space-y-3 max-h-[300px] overflow-y-auto">
              {recentActivities.map((activity, index) => (
                <li key={index} className="flex items-center space-x-3 p-3 bg-gray-50 rounded-lg transition-colors duration-200 hover:bg-gray-100">
                  <span className={`w-3 h-3 rounded-full ${activity.activityType === 'post' ? 'bg-green-500' : 'bg-blue-500'}`}></span>
                  <span className="text-gray-700">{activity.activityType === 'post' ? 'Posted' : 'Commented'} on {activity.platform}</span>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-500 italic">No recent activities.</p>
          )}
        </div>

        <div className="col-span-full lg:col-span-2 bg-white p-6 rounded-xl shadow-lg">
          <h3 className="text-xl font-semibold mb-4 text-gray-800">Upcoming Tasks</h3>
          {upcomingTasks.length > 0 ? (
            <div className="grid grid-cols-1 gap-4 max-h-[300px] overflow-y-auto">
              {upcomingTasks.map((task, index) => (
                <div key={index} className="p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors duration-200">
                  <h4 className="font-semibold text-blue-600">{task.title}</h4>
                  <p className="text-sm text-gray-600 mt-1">{task.description}</p>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-gray-500 italic">No upcoming tasks.</p>
          )}
        </div>
      </div>
    );
  };

  const renderPage = () => {
    if (trialStatus && trialStatus.trialExpired && !trialStatus.isPremium) {
      return <UpgradeModal />;
    }

    switch (currentPage) {
      case 'dashboard':
        return renderDashboardContent(access);
      case 'campaigns':
        return <CampaignsPage organizationId={selectedOrganization?.organizationId} />;
      case 'products':
        return <ProductsPage organizationId={selectedOrganization?.organizationId} />;
      case 'keywords':
        return <KeywordsPage organizationId={selectedOrganization?.organizationId} />;
      case 'tasks':
        return <TasksPage organizationId={selectedOrganization?.organizationId} />;
      case 'activityLog':
        return <ActivityLog organizationId={selectedOrganization?.organizationId} />;
      case 'sentimentanalysis':
        if (access?.sentimentAnalysis === 'advanced' || access?.sentimentAnalysis === 'basic') {
          return <MentionsPage organizationId={selectedOrganization?.organizationId} />;
        } else {
          return (
            <div className="text-center py-10">
              <h2 className="text-2xl font-bold mb-4">Premium Feature</h2>
              <p>Sentiment Analysis is a premium feature. Please upgrade your plan to access this feature.</p>
            </div>
          );
        }
      case 'settings':
        return <SettingsPage organizationId={selectedOrganization?.organizationId} />;
      case 'alerts':
        return AlertsPage ? <AlertsPage /> : <div>Loading Alerts Page...</div>;
      default:
        return renderDashboardContent(access);
    }
  };

  const UpgradeModal = () => {
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [selectedBillingCycle, setSelectedBillingCycle] = useState('monthly');
    const [upgradeError, setUpgradeError] = useState(null);
    const [showOrgSwitch, setShowOrgSwitch] = useState(false);

    const plans = [
      { id: 'starter', name: 'Starter Plan', monthlyPrice: 29, yearlyPrice: 279 },
      { id: 'pro', name: 'Pro Plan', monthlyPrice: 79, yearlyPrice: 759 },
      { id: 'business', name: 'Business Plan', monthlyPrice: 199, yearlyPrice: 1911 },
      { id: 'agency', name: 'Agency Plan', monthlyPrice: 499, yearlyPrice: 4791 }
    ];

    const handleUpgrade = async () => {
      if (!selectedPlan) return;

      try {
        const response = await post('/create-checkout-session', {
          uid: getValue('uid'),
          email: getValue('email'),
          plan: selectedPlan,
          billingCycle: selectedBillingCycle,
          organizationId: localStorage.getItem('orgIdSelected')
        });

        if (response.url) {
          window.location.href = response.url;
        } else {
          setUpgradeError('No checkout URL received');
        }
      } catch (err) {
        console.error('Error creating checkout session:', err);
        setUpgradeError(err.message || 'An error occurred while creating the checkout session. Please try again.');
      }
    };

    const handleOrgSwitch = (orgId) => {
      setSelectedOrganization(organizations.find(org => org.organizationId === orgId));
      localStorage.setItem('orgIdSelected', orgId);
      setShowUpgradeModal(false);
      window.location.reload();
    };

    return (
      <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
        <div className="bg-white rounded-xl p-8 max-w-2xl w-full">
          <h2 className="text-3xl font-bold mb-4 text-gray-800">Your Trial Has Expired</h2>
          <p className="text-gray-600 mb-6">
            Upgrade to premium to continue using all features and unlock even more powerful tools!
          </p>

          {!showOrgSwitch ? (
            <>
              <div className="mb-6">
                <label className="block mb-2 font-semibold">Billing Cycle</label>
                <select
                  className="w-full p-2 border rounded-md"
                  value={selectedBillingCycle}
                  onChange={(e) => setSelectedBillingCycle(e.target.value)}
                >
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
                </select>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                {plans.map((plan) => (
                  <div
                    key={plan.id}
                    className={`p-4 border rounded-md cursor-pointer transition-all duration-300 ${
                      selectedPlan === plan.id ? 'border-blue-500 bg-blue-50 shadow-md' : 'hover:shadow-md'
                    }`}
                    onClick={() => setSelectedPlan(plan.id)}
                  >
                    <h4 className="font-bold text-lg">{plan.name}</h4>
                    <p className="text-2xl font-semibold text-blue-600 mt-2">
                      ${selectedBillingCycle === 'monthly' ? plan.monthlyPrice : plan.yearlyPrice}
                      <span className="text-sm text-gray-500">/{selectedBillingCycle}</span>
                    </p>
                  </div>
                ))}
              </div>
              {upgradeError && (
                <div className="mb-4 text-red-500">
                  <AlertCircle className="inline mr-2" />
                  {upgradeError}
                </div>
              )}
              <div className="flex justify-between items-center">
                <button
                  onClick={() => setShowOrgSwitch(true)}
                  className="text-blue-500 hover:text-blue-700 transition duration-300"
                >
                  Switch Organization
                </button>
                <button
                  onClick={handleUpgrade}
                  className="bg-blue-500 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-blue-600 transition duration-300 shadow-lg"
                  disabled={!selectedPlan}
                >
                  Upgrade to Premium
                </button>
              </div>
            </>
          ) : (
            <div>
              <h3 className="text-xl font-semibold mb-4">Switch to a Premium Organization</h3>
              <div className="space-y-4 max-h-60 overflow-y-auto">
                {organizations.map((org) => (
                  <div
                    key={org.organizationId}
                    className={`p-4 border rounded-md cursor-pointer transition-all duration-300 ${
                      org.organizationId === selectedOrganization.organizationId ? 'border-blue-500 bg-blue-50' : 'hover:bg-gray-50'
                    }`}
                    onClick={() => handleOrgSwitch(org.organizationId)}
                  >
                    <h4 className="font-semibold">{org.organizationName}</h4>
                    <p className="text-sm text-gray-500">
                      {org.subscriptionStatus === 'premium' ? 'Premium' : 'Free'}
                    </p>
                  </div>
                ))}
              </div>
              <button
                onClick={() => setShowOrgSwitch(false)}
                className="mt-4 text-blue-500 hover:text-blue-700 transition duration-300"
              >
                Back to Upgrade Options
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  const FancyOrgSelector = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <div className="relative inline-block text-left">
        <div>
          <button
            type="button"
            className="inline-flex justify-between items-center w-64 rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span className="truncate">{selectedOrganization?.organizationName || 'Select Organization'}</span>
            <ChevronDown className="ml-2 h-5 w-5" aria-hidden="true" />
          </button>
        </div>

        {isOpen && (
          <div className="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
            <div className="py-1" role="menu" aria-orientation="vertical">
              {organizations.map((org) => (
                <button
                  key={org.organizationId}
                  className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center justify-between"
                  role="menuitem"
                  onClick={() => {
                    handleOrganizationChange({ target: { value: org.organizationId } });
                    setIsOpen(false);
                  }}
                >
                  <span>{org.organizationName}</span>
                  {org.organizationId === selectedOrganization?.organizationId && (
                    <CheckCircle className="h-4 w-4 text-blue-500" />
                  )}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const GradientLogo = () => (
    <h1 className="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-indigo-600">
      SocialSignalAI
    </h1>
  );

  const Header = () => {
    const userName = getValue('name') || 'User';
    const userInitial = userName.charAt(0).toUpperCase();

    return (
      <header className="bg-white shadow-sm fixed top-0 left-0 right-0 z-50 h-16">
        <nav className="container mx-auto px-4 py-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-6">
              <GradientLogo />
              <div className="hidden md:block">
                <FancyOrgSelector />
              </div>
            </div>
            <div className="hidden md:flex items-center space-x-2 sm:space-x-4">
              <button
                className={`bg-indigo-600 text-white px-3 py-1 sm:px-4 sm:py-2 rounded-full text-xs sm:text-sm transition duration-300 flex items-center ${
                  !multiAccountManagement ? 'opacity-50 cursor-not-allowed' : 'hover:bg-indigo-700'
                }`}
                onClick={() => multiAccountManagement && setShowCreateOrgDialog(true)}
                disabled={!multiAccountManagement}
              >
                <Plus className="h-4 w-4 mr-2" />
                New Org
              </button>
              <div className="flex items-center space-x-3">
                <div className="w-8 h-8 rounded-full bg-indigo-600 flex items-center justify-center text-white font-semibold">
                  {userInitial}
                </div>
                <span className="font-medium text-gray-700">{userName}</span>
              </div>
              <button
                className="bg-gray-200 text-gray-700 px-3 py-1 sm:px-4 sm:py-2 rounded-full text-xs sm:text-sm hover:bg-gray-300 transition duration-300 flex items-center"
                onClick={logoutUser}
              >
                <LogOut className="h-4 w-4 mr-2" />
                Logout
              </button>
            </div>
            <div className="md:hidden">
              <button
                onClick={() => setTopMenuOpen(!topMenuOpen)}
                className="text-gray-600 hover:text-gray-900 focus:outline-none"
              >
                <MenuIcon className="h-6 w-6" />
              </button>
            </div>
          </div>
          {topMenuOpen && (
            <div className="mt-4 md:hidden">
              <FancyOrgSelector />
              <div className="mt-4 space-y-2">
                <button
                  className={`w-full bg-indigo-600 text-white px-4 py-2 rounded-full text-sm transition duration-300 flex items-center justify-center ${
                    !multiAccountManagement ? 'opacity-50 cursor-not-allowed' : 'hover:bg-indigo-700'
                  }`}
                  onClick={() => {
                    if (multiAccountManagement) {
                      setShowCreateOrgDialog(true);
                      setTopMenuOpen(false);
                    }
                  }}
                  disabled={!multiAccountManagement}
                >
                  <Plus className="h-4 w-4 mr-2" />
                  New Org
                </button>
                <div className="flex items-center justify-between bg-gray-100 px-4 py-2 rounded-full">
                  <div className="flex items-center space-x-3">
                    <div className="w-8 h-8 rounded-full bg-indigo-600 flex items-center justify-center text-white font-semibold">
                      {userInitial}
                    </div>
                    <span className="font-medium text-gray-700">{userName}</span>
                  </div>
                  <button
                    className="bg-gray-200 text-gray-700 px-3 py-1 rounded-full text-xs hover:bg-gray-300 transition duration-300 flex items-center"
                    onClick={() => {
                      logoutUser();
                      setTopMenuOpen(false);
                    }}
                  >
                    <LogOut className="h-4 w-4 mr-2" />
                    Logout
                  </button>
                </div>
              </div>
            </div>
          )}
        </nav>
      </header>
    );
  };

  const BottomNavItem = ({ icon, text, onClick, active }) => (
    <button
      className={`flex flex-col items-center justify-center w-full py-2
        ${active ? 'text-indigo-600' : 'text-gray-600'}`}
      onClick={onClick}
    >
      {icon}
      <span className="text-xs mt-1">{text}</span>
    </button>
  );

  const BottomNav = () => (
    <div className="md:hidden fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 flex justify-around items-center z-50">
      <BottomNavItem icon={<Layout className="h-5 w-5" />} text="Dashboard" onClick={() => setCurrentPage('dashboard')} active={currentPage === 'dashboard'} />
      <BottomNavItem icon={<Flag className="h-5 w-5" />} text="Campaigns" onClick={() => setCurrentPage('campaigns')} active={currentPage === 'campaigns'} />
      <BottomNavItem icon={<Plus className="h-5 w-5" />} text="Products" onClick={() => setCurrentPage('products')} active={currentPage === 'products'} />
      <BottomNavItem icon={<Key className="h-5 w-5" />} text="Keywords" onClick={() => setCurrentPage('keywords')} active={currentPage === 'keywords'} />
      <BottomNavItem icon={<Settings className="h-5 w-5" />} text="More" onClick={() => setBottomMenuOpen(!bottomMenuOpen)} active={bottomMenuOpen} />
    </div>
  );

  const SideNav = () => (
    <div className="hidden md:block bg-white text-gray-800 shadow-md w-64 h-full overflow-y-auto">
      <div className="p-4">
        <GradientLogo />
      </div>
      <nav className="mt-6 px-4">
        <NavItem icon={<Layout className="h-5 w-5" />} text="Dashboard" onClick={() => setCurrentPage('dashboard')} active={currentPage === 'dashboard'} />
        <NavItem icon={<Flag className="h-5 w-5" />} text="Campaigns" onClick={() => setCurrentPage('campaigns')} active={currentPage === 'campaigns'} className="campaigns-button" />
        <NavItem icon={<Plus className="h-5 w-5" />} text="Products" onClick={() => setCurrentPage('products')} active={currentPage === 'products'} className="products-button" />
        <NavItem icon={<Key className="h-5 w-5" />} text="Keywords" onClick={() => setCurrentPage('keywords')} active={currentPage === 'keywords'} className="keywords-button" />
        <NavItem
          icon={<MessageSquare className="h-5 w-5" />}
          text="Sentiment Analysis"
          onClick={() => setCurrentPage('sentimentanalysis')}
          active={currentPage === 'sentimentanalysis'}
          isPremium={sentimentAnalysis === 'below'}
        />
        <NavItem icon={<ClipboardList className="h-5 w-5" />} text="Tasks" onClick={() => setCurrentPage('tasks')} active={currentPage === 'tasks'} />
        <NavItem icon={<Activity className="h-5 w-5" />} text="Engagement Tracker" onClick={() => setCurrentPage('activityLog')} active={currentPage === 'activityLog'} />
        <NavItem icon={<Bell className="h-5 w-5" />} text="Alerts" onClick={() => setCurrentPage('alerts')} active={currentPage === 'alerts'} />
        <NavItem icon={<Settings className="h-5 w-5" />} text="Settings" onClick={() => setCurrentPage('settings')} active={currentPage === 'settings'} className="settings-button" />
      </nav>
    </div>
  );

  return (
    <div className="flex h-screen bg-gray-50">
      <SideNav />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-50 mt-16">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
            {renderPage()}
          </div>
        </main>
        <BottomNav />
      </div>

      {/* Mobile menu (slides up from bottom) */}
      {bottomMenuOpen && (
        <div className="md:hidden fixed inset-0 bg-gray-600 bg-opacity-50 z-50">
          <div className="absolute bottom-0 left-0 right-0 bg-white rounded-t-2xl shadow-lg p-4 transform transition-transform duration-300 ease-in-out">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold">More Options</h2>
              <button onClick={() => setBottomMenuOpen(false)} className="text-gray-500">
                <X className="h-6 w-6" />
              </button>
            </div>
            <nav className="space-y-2">
              <NavItem icon={<MessageSquare className="h-5 w-5" />} text="Sentiment Analysis" onClick={() => { setCurrentPage('sentimentanalysis'); setBottomMenuOpen(false); }} active={currentPage === 'sentimentanalysis'} />
              <NavItem icon={<ClipboardList className="h-5 w-5" />} text="Tasks" onClick={() => { setCurrentPage('tasks'); setBottomMenuOpen(false); }} active={currentPage === 'tasks'} />
              <NavItem icon={<Activity className="h-5 w-5" />} text="Engagement Tracker" onClick={() => { setCurrentPage('activityLog'); setBottomMenuOpen(false); }} active={currentPage === 'activityLog'} />
              <NavItem icon={<Bell className="h-5 w-5" />} text="Alerts" onClick={() => { setCurrentPage('alerts'); setBottomMenuOpen(false); }} active={currentPage === 'alerts'} />
              <NavItem icon={<Settings className="h-5 w-5" />} text="Settings" onClick={() => { setCurrentPage('settings'); setBottomMenuOpen(false); }} active={currentPage === 'settings'} />
            </nav>
          </div>
        </div>
      )}

      {multiAccountManagement && showCreateOrgDialog && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-xl w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4">Create New Organization</h2>
            <p className="mb-4">Enter a name for your new organization.</p>
            <input
              type="text"
              className="w-full p-2 border rounded-md mb-4"
              value={newOrgName}
              onChange={(e) => setNewOrgName(e.target.value)}
              placeholder="Enter organization name"
            />
            <div className="flex justify-end space-x-2">
              <button
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-400 transition duration-300"
                onClick={() => setShowCreateOrgDialog(false)}
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300"
                onClick={handleCreateOrg}
              >
                Create Organization
              </button>
            </div>
          </div>
        </div>
      )}

      {showUpgradeModal && <UpgradeModal />}

      {/* Add Joyride component */}
      {showOnboarding && (
        <Joyride
          steps={steps}
          run={runTour}
          continuous={true}
          showProgress={true}
          showSkipButton={true}
          disableOverlayClose={true}
          disableScrolling={true}
          callback={handleJoyrideCallback}
          styles={{
            options: {
              zIndex: 10000,
              arrowColor: '#fff',
            },
            tooltipContainer: {
              textAlign: 'left'
            },
            buttonNext: {
              backgroundColor: '#4f46e5',
              color: '#fff'
            },
            buttonBack: {
              color: '#4f46e5'
            }
          }}
          floaterProps={{
            disableAnimation: true,
            placement: 'right'
          }}
        />
      )}
    </div>
  );
};

export default Dashboard;