import { useState, useCallback } from 'react';
import axios from 'axios';

const useApi = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const baseURL = "https://api.socialsignalai.com/api/"

  const api = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const request = useCallback(async (method, url, data = null, options = {}) => {
    setLoading(true);
    setError(null);

    try {
      const response = await api({
        method,
        url,
        data,
        ...options,
      });
      return response.data;
    } catch (err) {
      const errorResponse = err.response?.data || { error: err.message };
      setError(errorResponse);
      throw errorResponse; // Throw the error response
    } finally {
      setLoading(false);
    }
  }, []);

  const get = useCallback((url, options = {}) => {
    return request('get', url, null, options);
  }, [request]);

  const post = useCallback((url, data, options = {}) => {
    return request('post', url, data, options);
  }, [request]);

  const uploadFile = useCallback((url, file, options = {}) => {
    const formData = new FormData();
    formData.append('file', file);

    return request('post', url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      ...options,
    });
  }, [request]);

  return {
    get,
    post,
    uploadFile,
    loading,
    error,
  };
};

export default useApi;
