import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CheckCircle, ArrowRight } from 'lucide-react';
import useApi from '../../../hooks/api';
import { getValue } from '../../../utils/userData';

function gtag_report_conversion(url) {
  const callback = () => {
    if (typeof(url) !== 'undefined') {
      window.location = url;
    }
  };
  window.gtag('event', 'conversion', {
    'send_to': 'AW-16704663969/kC5MCMy5rtMZEKHjs50-',
    'value': 1.0,
    'currency': 'CAD',
    'transaction_id': '',
    'event_callback': callback
  });
  return false;
}


const PaymentSuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { post } = useApi();

  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      const searchParams = new URLSearchParams(location.search);
      const sessionId = searchParams.get('session_id');

      if (!sessionId) {
        setError('No session ID found');
        setLoading(false);
        return;
      }

      try {
        if (localStorage.getItem('orgIdSelected') || localStorage.getItem('inviteOrgId')) {
          await post('/update-subscription-status', {
            uid: getValue('uid'),
            organizationId:localStorage.getItem('orgIdSelected') ?? localStorage.getItem('inviteOrgId'),
            sessionId: sessionId
          });
        } else {
          await post('/update-subscription-status', {
            uid: getValue('uid'),
            organizationId: getValue('organizationId'),
            sessionId: sessionId
          });
        }

        const response = await post('/subscription-details', {
          uid: getValue('uid'),
          organizationId: getValue('organizationId') || localStorage.getItem('orgIdSelected') || localStorage.getItem('inviteOrgId'),
          sessionId: sessionId
        });
        setSubscriptionDetails(response);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch subscription details');
        setLoading(false);
      }
    };
    fetchSubscriptionDetails();
    gtag_report_conversion();
  }, []);

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (error) {
    return <div className="flex justify-center items-center h-screen text-red-500">{error}</div>;
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 px-4">
      <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-8 text-center">
        <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-6" />
        <h1 className="text-3xl font-bold text-gray-800 mb-4">Payment Successful!</h1>
        <p className="text-gray-600 mb-6">
          Thank you for your subscription. Your account has been successfully upgraded.
        </p>
        {subscriptionDetails && (
          <div className="bg-gray-50 p-4 rounded-lg mb-6">
            <h2 className="font-semibold text-lg mb-2">Subscription Details</h2>
            <p>Plan: {subscriptionDetails.planName}</p>
            <p>Billing Cycle: {subscriptionDetails.billingCycle}</p>
            <p>Next Billing Date: {new Date(subscriptionDetails.nextBillingDate).toLocaleDateString()}</p>
          </div>
        )}
        <button
          onClick={() => navigate('/dashboard')}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg transition duration-200 ease-in-out inline-flex items-center"
        >
          Go to Dashboard
          <ArrowRight className="ml-2 w-4 h-4" />
        </button>
      </div>
    </div>
  );
};

export default PaymentSuccessPage;