// userDataWrapper.js

import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://kqvgrqahkjzigcqsmvkx.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImtxdmdycWFoa2p6aWdjcXNtdmt4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjIwNDc2ODEsImV4cCI6MjAzNzYyMzY4MX0.dJVotFjQ4R72tQXGuxkbYMy7fZ5eAMNSREIsnkyFucg';
const supabase = createClient(supabaseUrl, supabaseAnonKey);

const USER_STORAGE_KEY = 'user';
const SESSION_STORAGE_KEY = 'session';

export const storeUserData = (data) => {
  localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(data));
};

export const getUserData = () => {
  const userData = localStorage.getItem(USER_STORAGE_KEY);
  return userData ? JSON.parse(userData) : null;
};

export const storeSessionData = (data) => {
  localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(data));
};

export const getSessionData = () => {
  const sessionData = localStorage.getItem(SESSION_STORAGE_KEY);
  return sessionData ? JSON.parse(sessionData) : null;
};

export const clearUserData = () => {
  localStorage.removeItem(USER_STORAGE_KEY);
  localStorage.removeItem(SESSION_STORAGE_KEY);
};

export const isAuthenticated = () => {
  return !!getUserData() && !!getSessionData();
};

export const loginUser = async (email, password) => {
  try {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) throw error;

    storeUserData(data.user);
    storeSessionData(data.session);
    return data;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const registerUser = async (email, password, userData) => {
  try {
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: userData,
      },
    });

    if (error) throw error;

    // Note: User might need email confirmation before being fully registered
    return data;
  } catch (error) {
    console.error('Registration error:', error);
    throw error;
  }
};

export const logoutUser = async () => {
  try {
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
    clearUserData();
  } catch (error) {
    console.error('Logout error:', error);
    throw error;
  }
};

export const handleGoogleLogin = async () => {
  try {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
    });

    if (error) throw error;

    // Note: This might not immediately have user data, as OAuth is a redirect flow
    return data;
  } catch (error) {
    console.error('Google login error:', error);
    throw error;
  }
};

export const getCurrentUser = async () => {
  const { data: { user } } = await supabase.auth.getUser();
  return user;
};

export const refreshSession = async () => {
  const { data, error } = await supabase.auth.refreshSession();
  if (error) throw error;
  
  if (data.session) {
    storeSessionData(data.session);
    storeUserData(data.user);
  }
  
  return data;
};

// Use this function to make authenticated API calls
export const authenticatedFetch = async (url, options = {}) => {
  const session = getSessionData();
  if (!session) throw new Error('No active session');

  const headers = {
    ...options.headers,
    'Authorization': `Bearer ${session.access_token}`,
  };

  const response = await fetch(url, { ...options, headers });
  
  if (response.status === 401) {
    // Token might be expired, try to refresh
    await refreshSession();
    // Retry the request with the new token
    return authenticatedFetch(url, options);
  }

  return response;
};


export const getValue = (key) => {
  const userData = getUserData();
  if (!userData) return null;

  // Handle nested properties
  const keys = key.split('.');
  let value = userData;
  for (const k of keys) {
    if (value === null || value === undefined) return null;
    value = value[k];
  }
  return value;
};