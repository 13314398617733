import React from 'react';
import { useNavigate } from 'react-router-dom';
import { XCircle, RefreshCw, MessageCircle } from 'lucide-react';

const PaymentFailedPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 px-4">
      <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-8 text-center">
        <XCircle className="w-16 h-16 text-red-500 mx-auto mb-6" />
        <h1 className="text-3xl font-bold text-gray-800 mb-4">Payment Failed</h1>
        <p className="text-gray-600 mb-6">
          We're sorry, but there was an issue processing your payment. Your subscription was not activated.
        </p>
        <div className="space-y-4">
          <button
            onClick={() => navigate('/subscription')}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg transition duration-200 ease-in-out inline-flex items-center justify-center w-full"
          >
            <RefreshCw className="mr-2 w-4 h-4" />
            Try Again
          </button>
          <button
            onClick={() => navigate('/support')}
            className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-lg transition duration-200 ease-in-out inline-flex items-center justify-center w-full"
          >
            <MessageCircle className="mr-2 w-4 h-4" />
            Contact Support
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailedPage;