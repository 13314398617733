import React, { useState, useEffect, useCallback } from 'react';
import { Plus, Key, X, Edit, Trash2, ChevronDown, ChevronUp, Loader as LoaderIcon } from 'lucide-react';
import useApi from '../../hooks/api';
import { getValue } from '../../utils/userData';
import throttle from 'lodash.throttle';
import Loader from '../common/Loader';

const KeywordsPage = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [products, setProducts] = useState([]);
  const [newKeyword, setNewKeyword] = useState({ keyword: '', campaignId: '', productId: '' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingKeyword, setEditingKeyword] = useState(null);
  const [status, setStatus] = useState(null);
  const [expandedCampaigns, setExpandedCampaigns] = useState({});
  const [suggestedKeywords, setSuggestedKeywords] = useState([]);
  const [isSuggestionsLoading, setIsSuggestionsLoading] = useState(false);
  const [filter, setFilter] = useState('all');
  const { post, loading, error } = useApi();
  const orgId = localStorage.getItem('orgIdSelected');
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    if (orgId) {
      fetchCampaigns();
    }
  }, [orgId]);

  useEffect(() => {
    if (error) {
      setStatus({ type: 'error', message: error.message || 'An error occurred' });
    }
  }, [error]);

  useEffect(() => {
    if (newKeyword.campaignId) {
      const campaign = campaigns.find(c => c.id === newKeyword.campaignId);
      if (campaign) {
        setProducts(campaign.products || []);
      }
    }
  }, [newKeyword.campaignId, campaigns]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchCampaigns = async () => {
    try {
      const response = await post('/getCampaigns', {
        uid: getValue('uid'),
        orgId
      });

      const formattedCampaigns = response.map(campaign => ({
        name: campaign.campaignName,
        description: campaign.campaignDescription,
        teamMembers: campaign.teamMembers || [],
        id: campaign.campaignId,
        createdAt: new Date(parseInt(campaign.created)).toISOString(),
        uid: campaign.uid,
        orgId: campaign.orgId,
        keywordCount: campaign.keywordCount,
        productCount: campaign.productCount,
        keywords: campaign.keywords || [],
        products: campaign.products || []
      }));
      setCampaigns(formattedCampaigns);
      setExpandedCampaigns(formattedCampaigns.reduce((acc, campaign) => {
        acc[campaign.id] = true;
        return acc;
      }, {}));
    } catch (error) {
      setStatus({ type: 'error', message: 'Failed to fetch campaigns. Please try again.' });
    }
  };

  const handleAddOrUpdateKeyword = async () => {
    if (newKeyword.keyword && newKeyword.campaignId && newKeyword.productId) {
      try {
        setStatus(null); // Clear any previous status
        let response;
        if (editingKeyword) {
          response = await post('/editKeyword', {
            uid: getValue('uid'),
            orgId,
            keywordId: editingKeyword.keywordId,
            ...newKeyword
          });
        } else {
          response = await post('/addKeyword', {
            uid: getValue('uid'),
            orgId,
            ...newKeyword
          });
        }

        if (response.message === 'KEYWORD_ADDED' || response.message === 'KEYWORD_UPDATED') {
          setStatus({ type: 'success', message: editingKeyword ? 'Keyword updated successfully!' : 'Keyword added successfully!' });
          fetchCampaigns();
          setNewKeyword({ keyword: '', campaignId: '', productId: '' });
          setTimeout(() => {
            setIsModalOpen(false);
            setStatus(null);
            setEditingKeyword(null);
          }, 2000);
        } else if (response.message === 'KEYWORD_NOT_FOUND') {
          setStatus({ type: 'error', message: 'Keyword not found. Please try again.' });
        } else {
          throw new Error(response.error || 'Failed to add/update keyword');
        }
      } catch (err) {
        console.error('Error in handleAddOrUpdateKeyword:', err);
        if (err.error === 'KEYWORDS_LIMIT_REACHED') {
          setStatus({
            type: 'error',
            message: 'You have reached the maximum number of keywords for your current plan. Consider upgrading to add more keywords.',
            action: 'upgrade'
          });
          setShowUpgradeModal(true);
        } else {
          setStatus({ type: 'error', message: err.error || 'An error occurred while processing your request' });
        }
        setIsModalOpen(false);
      } finally {
        // Ensure we always fetch campaigns, even if there was an error
        await fetchCampaigns();
      }
    }
  };

  const handleDeleteKeyword = async (campaignId, keywordId) => {
    try {
      await post('/deleteKeyword', { uid: getValue('uid'), orgId, campaignId, keywordId });
      setStatus({ type: 'success', message: 'Keyword deleted successfully!' });
      fetchCampaigns();
    } catch (err) {
      setStatus({ type: 'error', message: 'Failed to delete keyword. Please try again.' });
    }
  };

  const openEditModal = (keyword, campaignId) => {
    setEditingKeyword(keyword);
    setNewKeyword({
      keyword: keyword.keyword,
      campaignId: campaignId,
      productId: keyword.productId || ''
    });
    const campaign = campaigns.find(c => c.id === campaignId);
    if (campaign) {
      setProducts(campaign.products || []);
    }
    setIsModalOpen(true);
  };

  const toggleCampaignExpansion = (campaignId) => {
    setExpandedCampaigns(prev => ({
      ...prev,
      [campaignId]: !prev[campaignId]
    }));
  };

  const fetchSuggestedKeywords = async (keyword) => {
    setIsSuggestionsLoading(true);
    try {
      const response = await post('/getKeywordSuggestion', {
        uid: getValue('uid'),
        orgId,
        keyword: keyword
      });
      setSuggestedKeywords(response.keywordlist || []);
    } catch (error) {
      console.error('Failed to fetch keyword suggestions:', error);
      setSuggestedKeywords([]);
    } finally {
      setIsSuggestionsLoading(false);
    }
  };

  const throttledFetchSuggestedKeywords = useCallback(
    throttle(fetchSuggestedKeywords, 1000),
    []
  );

  const handleKeywordChange = (e) => {
    const newKeywordValue = e.target.value;
    setNewKeyword({ ...newKeyword, keyword: newKeywordValue });
    if (newKeywordValue.length > 2) {
      throttledFetchSuggestedKeywords(newKeywordValue);
    } else {
      setSuggestedKeywords([]);
    }
  };

  const handleUpgrade = async () => {
    try {
      const response = await post('/create-checkout-session', {
        uid: getValue('uid'),
        email: getValue('email'),
        plan: 'pro', // You might want to choose an appropriate plan
        billingCycle: 'monthly', // Or let the user choose
        organizationId: orgId
      });

      if (response.url) {
        // Redirect to Stripe Checkout
        window.location.href = response.url;
      } else {
        setStatus({ type: 'error', message: 'No checkout URL received' });
      }
    } catch (err) {
      console.error('Error creating checkout session:', err);
      setStatus({ type: 'error', message: err.message || 'An error occurred while creating the checkout session. Please try again.' });
    }
  };

  const filteredCampaigns = campaigns.filter(campaign => {
    if (filter === 'all') return true;
    return campaign.id === filter;
  });

  if (!orgId) {
    return <div className="text-center py-12">No organization selected. Please select an organization first.</div>;
  }

  // if (loading) {
  //   return <Loader />;
  // }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="space-y-6 p-4 md:p-6 bg-gray-50 rounded-xl">
      <div className="flex flex-col md:flex-row justify-between items-center mb-6">
        <h2 className="text-3xl font-bold text-gray-800">Keywords</h2>
        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 flex items-center"
        >
          <Plus className="w-5 h-5 mr-2" /> Add Keyword
        </button>
      </div>

      {status && (
        <div className={`p-4 mb-4 rounded-md ${status.type === 'success' ? 'bg-green-100 border-green-400 text-green-700' : 'bg-red-100 border-red-400 text-red-700'}`}>
          <div className="flex justify-between items-center">
            <div>{status.message}</div>
            {status.action === 'upgrade' && (
              <button
                onClick={() => setShowUpgradeModal(true)}
                className="ml-2 px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
              >
                Upgrade now
              </button>
            )}
          </div>
        </div>
      )}

      {filteredCampaigns.length === 0 ? (
        <div className="text-center py-12 bg-white rounded-lg shadow-md">
          <Key className="w-16 h-16 text-gray-400 mx-auto mb-4" />
          <h3 className="text-xl font-semibold text-gray-700 mb-2">No Keywords Found</h3>
          <p className="text-gray-500 mb-4">Try adjusting your filter or add a new keyword.</p>
        </div>
      ) : (
        <div className="space-y-6">
          {filteredCampaigns.map(campaign => (
            <div key={campaign.id} className="bg-white rounded-lg shadow-md overflow-hidden">
              <div 
                className="p-4 bg-gray-100 flex justify-between items-center cursor-pointer"
                onClick={() => toggleCampaignExpansion(campaign.id)}
              >
                <h3 className="text-lg font-semibold">{campaign.name}</h3>
                {expandedCampaigns[campaign.id] ? <ChevronUp /> : <ChevronDown />}
              </div>
              {expandedCampaigns[campaign.id] && (
                <div className="p-4">
                  {campaign.keywords.length === 0 ? (
                    <p className="text-gray-500">No keywords for this campaign yet.</p>
                  ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                      {campaign.keywords.map(keyword => (
                        <div key={keyword.keywordId} className="bg-gray-50 p-3 rounded-md flex justify-between items-center">
                          <div className="flex items-center">
                            <Key className="w-5 h-5 text-blue-500 mr-2" />
                            <span>{keyword.keyword}</span>
                          </div>
                          <div className="flex space-x-2">
                            <button onClick={() => openEditModal(keyword, campaign.id)} className="text-gray-500 hover:text-blue-500">
                              <Edit className="w-4 h-4" />
                            </button>
                            <button onClick={() => handleDeleteKeyword(campaign.id, keyword.keywordId)} className="text-gray-500 hover:text-red-500">
                              <Trash2 className="w-4 h-4" />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            <div className="relative bg-white w-full max-w-md m-4 p-4 md:p-8 rounded-lg shadow-lg">
              <button
                onClick={() => {
                  setIsModalOpen(false);
                  setEditingKeyword(null);
                  setNewKeyword({ keyword: '', campaignId: '', productId: '' });
                  setSuggestedKeywords([]);
                }}
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition duration-300 ease-in-out"
              >
                <X className="w-6 h-6" />
              </button>
              <h2 className="text-2xl font-bold mb-6">{editingKeyword ? 'Edit Keyword' : 'Add New Keyword'}</h2>
              <div className="space-y-4">
                <div className="relative">
                  <input
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Keyword"
                    value={newKeyword.keyword}
                    onChange={handleKeywordChange}
                  />
                  {isSuggestionsLoading && (
                    <div className="absolute right-2 top-2">
                      <LoaderIcon className="w-5 h-5 animate-spin text-blue-500" />
                    </div>
                  )}
                </div>
                {suggestedKeywords.length > 0 && (
                  <div className="mt-2 p-2 bg-gray-100 rounded-md max-h-40 overflow-y-auto">
                    <h3 className="font-semibold mb-2">Suggested Keywords:</h3>
                    <ul className="space-y-1">
                      {suggestedKeywords.map((suggestion, index) => (
                        <li
                          key={index}
                          className="cursor-pointer hover:bg-gray-200 p-1 rounded"
                          onClick={() => setNewKeyword({ ...newKeyword, keyword: suggestion })}
                        >
                          {suggestion}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <select
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={newKeyword.campaignId}
                  onChange={(e) => setNewKeyword({ ...newKeyword, campaignId: e.target.value, productId: '' })}
                >
                  <option value="">Select Campaign</option>
                  {campaigns.map(campaign => (
                    <option key={campaign.id} value={campaign.id}>{campaign.name}</option>
                  ))}
                </select>
                <select
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={newKeyword.productId}
                  onChange={(e) => setNewKeyword({ ...newKeyword, productId: e.target.value })}
                  disabled={!newKeyword.campaignId}
                >
                  <option value="">Select Product</option>
                  {products.map(product => (
                    <option key={product.productId} value={product.productId}>{product.productName}</option>
                  ))}
                </select>
              </div>
              <div className="flex justify-end space-x-2 mt-6">
                <button
                  className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition duration-300 ease-in-out"
                  onClick={() => {
                    setIsModalOpen(false);
                    setEditingKeyword(null);
                    setNewKeyword({ keyword: '', campaignId: '', productId: '' });
                    setSuggestedKeywords([]);
                  }}
                  disabled={loading}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300 ease-in-out"
                  onClick={handleAddOrUpdateKeyword}
                  disabled={loading || !newKeyword.keyword || !newKeyword.campaignId || !newKeyword.productId}
                >
                  {editingKeyword ? 'Update Keyword' : 'Add Keyword'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showUpgradeModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg shadow-xl w-full max-w-md">
            <h3 className="text-xl font-bold mb-4">Upgrade Your Plan</h3>
            <p className="mb-4">Upgrade your plan to add more keywords and access additional features.</p>
            <div className="flex justify-end space-x-2">
              <button
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition duration-300"
                onClick={() => setShowUpgradeModal(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
                onClick={handleUpgrade}
              >
                Proceed to Upgrade
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default KeywordsPage;