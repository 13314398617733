import React, { useState, useEffect } from 'react';
import { MessageSquare, ThumbsUp, ThumbsDown, Search, ChevronDown, ChevronUp, ExternalLink, Download, Filter, ChevronLeft, ChevronRight, RefreshCw, AlertCircle, Calendar, TrendingUp, PieChart as PieChartIcon, BarChart2, Cloud } from 'lucide-react';
import { LineChart, Line, PieChart, Pie, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import { TagCloud } from 'react-tagcloud';
import useApi from '../../hooks/api';
import { getValue } from '../../utils/userData';
import { motion, AnimatePresence } from 'framer-motion';
import { FaReddit, FaTwitter, FaHackerNews } from 'react-icons/fa';
import Loader from '../common/Loader';

const COLORS = ['#3B82F6', '#10B981', '#F59E0B', '#EF4444', '#8B5CF6'];

// Move these functions outside of the main component
const getSentimentColor = (sentiment) => {
  if (sentiment > 0.3) return 'text-green-600';
  if (sentiment < -0.3) return 'text-red-600';
  return 'text-yellow-600';
};

const getSentimentIcon = (sentiment) => {
  if (sentiment > 0.3) return <ThumbsUp className="w-5 h-5" />;
  if (sentiment < -0.3) return <ThumbsDown className="w-5 h-5" />;
  return <MessageSquare className="w-5 h-5" />;
};

const MentionsPage = ({ organizationId }) => {
  const [mentions, setMentions] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sort, setSort] = useState('date');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedMention, setSelectedMention] = useState(null);
  const [dateRange, setDateRange] = useState('last7days');
  const [selectedSources, setSelectedSources] = useState(['Reddit', 'Hacker News']);
  const [selectedSentiments, setSelectedSentiments] = useState(['positive', 'neutral', 'negative']);
  const [activeTab, setActiveTab] = useState('mentions');
  const { post } = useApi();
  const itemsPerPage = 10;
  const orgId = localStorage.getItem('orgIdSelected');
  const [analyticsAccess, setAnalyticsAccess] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    console.log('Component mounted, fetching campaigns');
    fetchCampaigns();
    availableFeatures();
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const availableFeatures = async () => {
    try {
      const response = await post('/getAvailableAccess', { orgId : localStorage.getItem('orgIdSelected') });
      setAnalyticsAccess(response?.limit?.sentimentAnalysis);
    } catch (err) {
      console.error('Error fetching organizations:', err);
    }
  };

  useEffect(() => {
    if (selectedCampaign) {
      console.log('Selected campaign changed, fetching mentions');
      fetchMentions();
    }
  }, [selectedCampaign, dateRange, selectedSources, selectedSentiments]);

  const fetchCampaigns = async () => {
    try {
      setLoading(true);
      const response = await post('/getCampaigns', {
        uid: getValue('uid'),
        orgId: orgId
      });
      console.log('Campaigns response:', response);

      const formattedCampaigns = response.map(campaign => ({
        name: campaign.campaignName,
        description: campaign.campaignDescription,
        teamMembers: campaign.teamMembers || [],
        id: campaign.campaignId,
        createdAt: new Date(campaign.created).toISOString(),
        uid: campaign.uid,
        orgId: campaign.orgId,
        keywordCount: campaign.keywordCount,
        productCount: campaign.productCount,
        campaignType: campaign.campaignType || 'REDDIT',
        status: campaign.status || 'true'
      }));
      console.log('Formatted campaigns:', formattedCampaigns);
      setCampaigns(formattedCampaigns);
      if (formattedCampaigns.length > 0) {
        setSelectedCampaign(formattedCampaigns[0].id);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      setError('Failed to fetch campaigns. Please try again.');
      setLoading(false);
    }
  };

  const fetchMentions = async () => {
    setLoading(true);
    try {
      const response = await post('/mentions', {
        uid: getValue('uid'),
        orgId: orgId,
        campaignId: selectedCampaign,
        dateRange,
        sources: selectedSources,
        sentiments: selectedSentiments
      });
      console.log('Mentions response:', response);
      if (Array.isArray(response)) {
        setMentions(response);
      } else if (response && Array.isArray(response.data)) {
        setMentions(response.data);
      } else {
        console.error('Unexpected mentions response format:', response);
        setError('Received unexpected data format from ser  ver.');
      }
      setLoading(false);
    } catch (err) {
      console.error('Error fetching mentions:', err);
      setError('Failed to fetch mentions. Please try again.');
      setLoading(false);
    }
  };

  const filteredMentions = mentions.filter(mention => 
    mention.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    mention.content.toLowerCase().includes(searchTerm.toLowerCase())
  );

  console.log('Filtered mentions:', filteredMentions);

  const sortedMentions = [...filteredMentions].sort((a, b) => {
    switch (sort) {
      case 'date':
        return new Date(b.date) - new Date(a.date);
      case 'sentiment':
        return b.sentiment - a.sentiment;
      case 'popularity':
        return b.score - a.score;
      default:
        return 0;
    }
  });

  console.log('Sorted mentions:', sortedMentions);

  const paginatedMentions = sortedMentions.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  console.log('Paginated mentions:', paginatedMentions);

  const prepareTrendData = () => {
    const data = {};
    mentions.forEach(mention => {
      const date = new Date(mention.date).toISOString().split('T')[0];
      if (!data[date]) {
        data[date] = { date, count: 0 };
      }
      data[date].count++;
    });
    return Object.values(data).sort((a, b) => new Date(a.date) - new Date(b.date));
  };

  const prepareSourceData = () => {
    const data = {};
    mentions.forEach(mention => {
      if (!data[mention.source]) {
        data[mention.source] = 0;
      }
      data[mention.source]++;
    });
    return Object.entries(data).map(([name, value]) => ({ name, value }));
  };

  const prepareSentimentData = () => {
    const data = {
      Reddit: { positive: 0, neutral: 0, negative: 0 },
      'Hacker News': { positive: 0, neutral: 0, negative: 0 },
    };
    mentions.forEach(mention => {
      const sentiment = mention.sentiment > 0.3 ? 'positive' : (mention.sentiment < -0.3 ? 'negative' : 'neutral');
      data[mention.source][sentiment]++;
    });
    return Object.entries(data).flatMap(([source, sentiments]) => 
      Object.entries(sentiments).map(([sentiment, count]) => ({ source, sentiment, count }))
    );
  };

  const prepareWordCloudData = () => {
    const words = {};
    mentions.forEach(mention => {
      const text = `${mention.title} ${mention.content}`;
      text.split(/\s+/).forEach(word => {
        const cleanWord = word.toLowerCase().replace(/[^a-z0-9]/g, '');
        if (cleanWord.length > 3) {
          words[cleanWord] = (words[cleanWord] || 0) + 1;
        }
      });
    });
    return Object.entries(words)
      .map(([value, count]) => ({ value, count }))
      .sort((a, b) => b.count - a.count)
      .slice(0, 50);
  };
  
  const handleExport = () => {
    const csv = [
      ['Title', 'Content', 'Source', 'Date', 'Sentiment', 'Score', 'Comments', 'URL'],
      ...filteredMentions.map(mention => [
        mention.title,
        mention.content,
        mention.source,
        mention.date,
        mention.sentiment,
        mention.score,
        mention.numComments,
        mention.url
      ])
    ].map(row => row.map(cell => `"${cell}"`).join(',')).join('\n');

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'mentions_export.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  if (loading) return <Loader />;

  if (error) return (
    <div className="flex items-center justify-center h-screen">
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <strong className="font-bold">Error!</strong>
        <span className="block sm:inline"> {error}</span>
      </div>
    </div>
  );

  return (
    <div className="space-y-8 p-4 md:p-8 bg-gradient-to-br min-h-screen">
      <div className="flex justify-between items-center bg-white p-4 md:p-6 rounded-lg shadow-md">
        <h2 className="text-2xl md:text-3xl font-bold text-gray-800">Mention Analysis</h2>
        <button
          onClick={fetchMentions}
          className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300 flex items-center"
        >
          <RefreshCw className="w-4 h-4 mr-2" /> Refresh Data
        </button>
      </div>
      
      {/* Filters and Controls */}
      <div className="bg-white p-4 md:p-6 rounded-lg shadow-md space-y-6">
        <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-4">
          <div className="flex items-center space-x-4">
            <select
              value={selectedCampaign}
              onChange={(e) => setSelectedCampaign(e.target.value)}
              className="border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Select a campaign</option>
              {campaigns.map((campaign) => (
                <option key={campaign.id} value={campaign.id}>
                  {campaign.name}
                </option>
              ))}
            </select>
            <select
              value={dateRange}
              onChange={(e) => setDateRange(e.target.value)}
              className="border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="last7days">Last 7 days</option>
              <option value="last30days">Last 30 days</option>
              <option value="last90days">Last 90 days</option>
            </select>
            <select
              value={sort}
              onChange={(e) => setSort(e.target.value)}
              className="border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="date">Sort by Date</option>
              <option value="sentiment">Sort by Sentiment</option>
              <option value="popularity">Sort by Popularity</option>
            </select>
          </div>
          <div className="flex items-center space-x-4">
            <button
              onClick={() => setSelectedSources(selectedSources.length === 2 ? [] : ['Reddit', 'Hacker News'])}
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition duration-300"
            >
              {selectedSources.length === 2 ? 'Deselect All' : 'Select All'} Sources
            </button>
            <button
              onClick={() => setSelectedSentiments(selectedSentiments.length === 3 ? [] : ['positive', 'neutral', 'negative'])}
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition duration-300"
            >
              {selectedSentiments.length === 3 ? 'Deselect All' : 'Select All'} Sentiments
            </button>
            <button
              onClick={handleExport}
              className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition duration-300 flex items-center"
            >
              <Download className="w-4 h-4 mr-2" /> Export
            </button>
          </div>
        </div>
        
        <div className="flex flex-wrap gap-4">
          {['Reddit', 'Hacker News'].map(source => (
            <label key={source} className="flex items-center space-x-2 cursor-pointer">
              <input
                type="checkbox"
                checked={selectedSources.includes(source)}
                onChange={() => setSelectedSources(prev => 
                  prev.includes(source) ? prev.filter(s => s !== source) : [...prev, source]
                )}
                className="form-checkbox h-5 w-5 text-blue-600 transition duration-150 ease-in-out"
              />
              <span className="text-gray-700">{source}</span>
            </label>
          ))}
        </div>
        
        <div className="flex flex-wrap gap-4">
          {['positive', 'neutral', 'negative'].map(sentiment => (
            <label key={sentiment} className="flex items-center space-x-2 cursor-pointer">
              <input
                type="checkbox"
                checked={selectedSentiments.includes(sentiment)}
                onChange={() => setSelectedSentiments(prev => 
                  prev.includes(sentiment) ? prev.filter(s => s !== sentiment) : [...prev, sentiment]
                )}
                className="form-checkbox h-5 w-5 text-blue-600 transition duration-150 ease-in-out"
              />
              <span className="capitalize text-gray-700">{sentiment}</span>
              </label>
          ))}
        </div>

        <div className="relative">
          <input
            type="text"
            placeholder="Search mentions..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full border rounded-md pl-10 pr-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        </div>
      </div>

      {/* Debug information */}
      {/* <div className="bg-white p-4 rounded-lg shadow-md mt-4">
        <h3 className="text-lg font-semibold mb-2">Debug Info:</h3>
        <p>Total mentions: {mentions.length}</p>
        <p>Filtered mentions: {filteredMentions.length}</p>
        <p>Sorted mentions: {sortedMentions.length}</p>
        <p>Paginated mentions: {paginatedMentions.length}</p>
        <p>Selected campaign: {selectedCampaign}</p>
        <p>Date range: {dateRange}</p>
        <p>Selected sources: {selectedSources.join(', ')}</p>
        <p>Selected sentiments: {selectedSentiments.join(', ')}</p>
      </div> */}

      {/* Tabs */}
      <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
        <div className="flex flex-wrap bg-gray-50">
          <TabButton
            active={activeTab === 'mentions'}
            onClick={() => setActiveTab('mentions')}
            icon={<MessageSquare className="w-5 h-5 mr-2" />}
            label="Mentions"
          />
          <TabButton
            active={activeTab === 'analytics'}
            onClick={() => analyticsAccess === 'advanced' && setActiveTab('analytics')}
            icon={<TrendingUp className="w-5 h-5 mr-2" />}
            label="Analytics"
            disabled={analyticsAccess !== 'advanced'}
          >
            {analyticsAccess === 'basic' && (
              <span className="ml-2 bg-gradient-to-r from-yellow-400 to-orange-500 text-white text-xs font-bold px-2 py-0.5 rounded-full shadow-sm">
                Upgrade
              </span>
            )}
            {analyticsAccess !== 'advanced' && analyticsAccess !== 'basic' && (
              <span className="ml-2 bg-gradient-to-r from-yellow-400 to-orange-500 text-white text-xs font-bold px-2 py-0.5 rounded-full shadow-sm">
                PRO
              </span>
            )}
          </TabButton>
        </div>

        {activeTab === 'mentions' && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-white rounded-xl shadow-lg overflow-hidden"
          >
            {paginatedMentions.length === 0 ? (
              <EmptyState />
            ) : (
              <div className="overflow-hidden">
                <AnimatePresence>
                  {paginatedMentions.map((mention, index) => (
                    <MentionCard 
                      key={mention.mentionId} 
                      mention={mention} 
                      onViewDetails={() => setSelectedMention(mention)}
                      index={index}
                    />
                  ))}
                </AnimatePresence>
              </div>
            )}
            
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(sortedMentions.length / itemsPerPage)}
              onPageChange={setCurrentPage}
            />
          </motion.div>
        )}

        {activeTab === 'analytics' && (
          <div className="p-4 md:p-6">
            {analyticsAccess === 'advanced' ? (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {/* Mentions Trend */}
                <div className="bg-white p-4 md:p-6 rounded-lg shadow-md">
                  <h4 className="text-xl font-semibold mb-4 text-gray-800">Mentions Trend</h4>
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={prepareTrendData()}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey="count" stroke="#3B82F6" strokeWidth={2} dot={false} />
                    </LineChart>
                  </ResponsiveContainer>
                </div>

                {/* Source Distribution */}
                <div className="bg-white p-4 md:p-6 rounded-lg shadow-md">
                  <h4 className="text-xl font-semibold mb-4 text-gray-800">Source Distribution</h4>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={prepareSourceData()}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                      >
                        {prepareSourceData().map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </div>

                {/* Sentiment Comparison */}
                <div className="bg-white p-4 md:p-6 rounded-lg shadow-md">
                  <h4 className="text-xl font-semibold mb-4 text-gray-800">Sentiment Comparison</h4>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={prepareSentimentData()}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="source" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="count" fill="#3B82F6" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>

                {/* Word Cloud */}
                <div className="bg-white p-4 md:p-6 rounded-lg shadow-md">
                  <h4 className="text-xl font-semibold mb-4 text-gray-800">Word Cloud</h4>
                  <TagCloud
                    minSize={12}
                    maxSize={35}
                    tags={prepareWordCloudData()}
                    onClick={tag => console.log(`'${tag.value}' was selected!`)}
                  />
                </div>
              </div>
            ) : (
              <div className="text-center py-10">
                <div className="bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 text-white rounded-lg p-4 md:p-8 shadow-lg max-w-2xl mx-auto">
                  <TrendingUp className="w-16 h-16 mx-auto mb-4" />
                  <h3 className="text-2xl font-bold mb-4">Unlock Advanced Analytics</h3>
                  <p className="text-lg mb-6">
                    Gain deeper insights into your mentions with our premium analytics features. Upgrade to the Advanced plan to access:
                  </p>
                  <ul className="text-left list-disc list-inside mb-6">
                    <li>Sentiment trend analysis</li>
                    <li>Source distribution charts</li>
                    <li>Advanced filtering options</li>
                    <li>Custom report generation</li>
                  </ul>
                  <button
                    onClick={() => {/* Add logic to upgrade plan */}}
                    className="bg-white text-purple-600 font-bold py-2 px-6 rounded-full hover:bg-purple-100 transition duration-300"
                  >
                    Upgrade to Advanced
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {selectedMention && (
        <MentionDetail mention={selectedMention} onClose={() => setSelectedMention(null)} />
      )}
    </div>
  );
};

const TabButton = ({ active, onClick, icon, label, disabled, children }) => (
  <button
    className={`flex items-center px-6 py-4 font-semibold transition-colors duration-200 ${
      active
        ? 'text-blue-600 border-b-2 border-blue-600'
        : 'text-gray-600 hover:text-blue-500'
    } ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
    onClick={onClick}
    disabled={disabled}
  >
    {icon}
    {label}
    {children}
  </button>
);

const EmptyState = () => (
  <div className="text-center py-16">
    <motion.div
      initial={{ scale: 0.8, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <AlertCircle className="w-20 h-20 text-gray-400 mx-auto mb-4" />
      <h3 className="text-2xl font-bold text-gray-700 mb-2">No mentions found</h3>
      <p className="text-gray-500">Try adjusting your filters or search criteria.</p>
    </motion.div>
  </div>
);

const MentionCard = ({ mention, onViewDetails, index }) => (
  <motion.div
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -50 }}
    transition={{ duration: 0.3, delay: index * 0.1 }}
    className="border-b border-gray-200 last:border-b-0 hover:bg-gray-50 transition-colors duration-200"
  >
    <div className="p-4 md:p-6">
      <div className="flex justify-between items-start mb-4">
        <div className="flex items-center">
          {mention.source === 'Reddit' ? (
            <FaReddit className="w-6 h-6 mr-2 text-orange-500" />
          ) : mention.source === 'Hacker News' ? (
            <FaHackerNews className="w-6 h-6 mr-2 text-orange-600" />
          ) : (
            <svg className="w-6 h-6 mr-2 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
              <path d="M10 0L0 10h3v10h14V10h3L10 0zm0 18.5L5 13h10l-5 5.5z"/>
            </svg>
          )}
          <span className="font-semibold text-lg text-gray-800">{mention.source}</span>
        </div>
        <span className={`flex items-center px-3 py-1 rounded-full ${getSentimentColor(mention.sentiment)}`}>
          {getSentimentIcon(mention.sentiment)}
          <span className="ml-1 font-medium">{mention.sentiment.toFixed(2)}</span>
        </span>
      </div>
      <h3 className="text-xl font-bold text-gray-900 mb-2 line-clamp-2 hover:line-clamp-none transition-all duration-300">{mention.title}</h3>
      <p className="text-gray-600 mb-4 line-clamp-3 hover:line-clamp-none transition-all duration-300">{mention.content}</p>
      <div className="flex justify-between items-center text-sm text-gray-500">
        <span>{new Date(mention.date).toLocaleDateString()}</span>
        <div className="flex items-center space-x-4">
          <span className="flex items-center">
            <ThumbsUp className="w-4 h-4 mr-1" />
            {mention.score}
          </span>
          <span className="flex items-center">
            <MessageSquare className="w-4 h-4 mr-1" />
            {mention.numComments}
          </span>
        </div>
      </div>
    </div>
    <div className="bg-gray-50 px-6 py-3">
      <button
        onClick={onViewDetails}
        className="w-full text-blue-600 hover:text-blue-800 font-medium text-center transition-colors duration-200"
      >
        View Details
      </button>
    </div>
  </motion.div>
);

const Pagination = ({ currentPage, totalPages, onPageChange }) => (
  <div className="flex flex-col md:flex-row justify-center items-center space-y-2 md:space-y-0 md:space-x-2 py-8 bg-gray-50">
    <button
      onClick={() => onPageChange(prev => Math.max(prev - 1, 1))}
      disabled={currentPage === 1}
      className="p-2 rounded-full disabled:opacity-50 bg-white hover:bg-gray-100 transition duration-300 shadow-sm"
    >
      <ChevronLeft className="w-5 h-5" />
    </button>
    <span className="px-4 py-2 bg-white rounded-md shadow-sm font-medium">
      Page {currentPage} of {totalPages}
    </span>
    <button
      onClick={() => onPageChange(prev => Math.min(prev + 1, totalPages))}
      disabled={currentPage === totalPages}
      className="p-2 rounded-full disabled:opacity-50 bg-white hover:bg-gray-100 transition duration-300 shadow-sm"
    >
      <ChevronRight className="w-5 h-5" />
    </button>
  </div>
);

const MentionDetail = ({ mention, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl m-4 p-4 md:p-8">
        <div className="flex justify-between items-start mb-6">
          <h2 className="text-2xl font-bold text-gray-800">{mention.title}</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="flex justify-between items-center mb-6">
          <span className="flex items-center text-gray-600">
            {mention.source === 'Reddit' ? (
              <FaReddit className="w-5 h-5 mr-2 text-orange-500" />
            ) : mention.source === 'Hacker News' ? (
              <FaHackerNews className="w-5 h-5 mr-2 text-orange-600" />
            ) : (
              <svg className="w-5 h-5 mr-2 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                <path d="M10 0L0 10h3v10h14V10h3L10 0zm0 18.5L5 13h10l-5 5.5z"/>
              </svg>
            )}
            {mention.source} • {mention.subreddit}
          </span>
          <span className={`flex items-center px-3 py-1 rounded-full ${getSentimentColor(mention.sentiment)}`}>
            {getSentimentIcon(mention.sentiment)}
            <span className="ml-1 font-medium">{mention.sentiment.toFixed(2)}</span>
          </span>
        </div>
        <p className="text-gray-700 mb-6 text-lg leading-relaxed">{mention.content}</p>
        <div className="flex justify-between items-center mb-6 text-sm text-gray-600">
          <span>By {mention.author}</span>
          <span>{new Date(mention.date).toLocaleString()}</span>
        </div>
        <div className="flex justify-between items-center mb-8">
          <div className="flex items-center space-x-6">
            <span className="flex items-center text-gray-700">
              <ThumbsUp className="w-5 h-5 mr-2" />
              {mention.score} points
            </span>
            <span className="flex items-center text-gray-700">
              <MessageSquare className="w-5 h-5 mr-2" />
              {mention.numComments} comments
            </span>
          </div>
        </div>
        <div className="flex justify-end">
          <a
            href={mention.url}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-blue-600 text-white px-6 py-3 rounded-md hover:bg-blue-700 transition duration-300 flex items-center"
          >
            View original <ExternalLink className="w-4 h-4 ml-2" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default MentionsPage;