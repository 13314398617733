import React, { useState, useCallback, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Plus, X, Edit, Trash2, MoreVertical } from 'lucide-react';
import useApi from '../../hooks/api';
import Loader from '../common/Loader';

const TaskItem = ({ task, columnId, index, moveTask, handleEditTask, handleDeleteTask }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'TASK',
    item: { id: task.taskId, fromColumn: columnId, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      className={`bg-white p-4 rounded-lg shadow-md ${isDragging ? 'opacity-50' : ''} transform transition-all duration-200 hover:scale-105 hover:shadow-lg`}
      style={{ cursor: 'move' }}
    >
      <div className="flex justify-between items-start">
        <h4 className="font-semibold text-lg">{task.title}</h4>
        <div className="relative group">
          <button className="text-gray-500 hover:text-gray-700">
            <MoreVertical className="w-5 h-5" />
          </button>
          <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg hidden group-hover:block">
            <div className="py-1">
              <button
                onClick={() => handleEditTask(task.taskId)}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
              >
                Edit
              </button>
              <button
                onClick={() => handleDeleteTask(task.taskId)}
                className="block px-4 py-2 text-sm text-red-600 hover:bg-gray-100 w-full text-left"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <p className="text-sm text-gray-600 mt-2">{task.description}</p>
      <div className="mt-4 flex justify-between items-center">
        <span className="text-xs text-gray-500">{new Date(task.created).toLocaleDateString()}</span>
        <span className={`px-2 py-1 rounded-full text-xs ${getStatusColor(task.status)}`}>
          {task.status}
        </span>
      </div>
    </div>
  );
};

const getStatusColor = (status) => {
  switch (status) {
    case 'todo':
      return 'bg-yellow-200 text-yellow-800';
    case 'inProgress':
      return 'bg-blue-200 text-blue-800';
    case 'done':
      return 'bg-green-200 text-green-800';
    default:
      return 'bg-gray-200 text-gray-800';
  }
};

const Column = ({ column, tasks, moveTask, handleEditTask, handleDeleteTask }) => {
  const [{ isOver }, drop] = useDrop({
    accept: 'TASK',
    drop: (item) => {
      if (item.fromColumn !== column.id) {
        moveTask(item.id, column.id);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const columnTasks = tasks.filter(task => task.status === column.id);

  return (
    <div 
      ref={drop} 
      className={`bg-gray-100 p-4 rounded-lg ${isOver ? 'bg-blue-100' : ''} transition-all duration-300`}
    >
      <h3 className="text-lg font-semibold mb-4 flex items-center">
        <span className={`w-3 h-3 rounded-full mr-2 ${getStatusColor(column.id)}`}></span>
        {column.title}
      </h3>
      <div className="space-y-4 min-h-[200px]">
        {columnTasks.map((task, index) => (
          <TaskItem
            key={task.taskId}
            task={task}
            columnId={column.id}
            index={index}
            moveTask={moveTask}
            handleEditTask={handleEditTask}
            handleDeleteTask={handleDeleteTask}
          />
        ))}
      </div>
      <p className="mt-4 text-sm text-gray-500">Tasks: {columnTasks.length}</p>
    </div>
  );
};

const TasksPage = () => {
  const { post, loading, error } = useApi();
  const [tasks, setTasks] = useState([]);
  const [boards, setBoards] = useState([]);
  const [newTask, setNewTask] = useState({ title: '', description: '' });
  const [newBoard, setNewBoard] = useState('');
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [isBoardModalOpen, setIsBoardModalOpen] = useState(false);
  const [editingTask, setEditingTask] = useState(null);
  const [selectedBoard, setSelectedBoard] = useState(null);
  const orgId = localStorage.getItem('orgIdSelected');

  const columns = {
    todo: { id: 'todo', title: 'To Do' },
    inProgress: { id: 'inProgress', title: 'In Progress' },
    done: { id: 'done', title: 'Done' },
  };

  const fetchBoards = useCallback(async () => {
    if (!orgId) {
      console.error('No organization selected');
      return;
    }
    try {
      const response = await post('/getBoards', { orgId });
      if (Array.isArray(response)) {
        setBoards(response);
        if (response.length > 0 && !selectedBoard) {
          setSelectedBoard(response[0].boardId);
        }
      } else {
        console.error('Unexpected API response format:', response);
        setBoards([]);
      }
    } catch (err) {
      console.error('Error fetching boards:', err);
      setBoards([]);
    }
  }, [post, orgId]);

  const fetchTasks = useCallback(async () => {
    if (!orgId || !selectedBoard) {
      console.error('No organization or board selected');
      return;
    }
    try {
      const response = await post('/getTasks', { orgId, boardId: selectedBoard });
      if (Array.isArray(response)) {
        setTasks(response);
      } else {
        console.error('Unexpected API response format:', response);
        setTasks([]);
      }
    } catch (err) {
      console.error('Error fetching tasks:', err);
      setTasks([]);
    }
  }, [post, orgId, selectedBoard]);

  useEffect(() => {
    if (orgId) {
      fetchBoards();
    }
  }, [fetchBoards, orgId]);

  useEffect(() => {
    if (orgId && selectedBoard) {
      fetchTasks();
    }
  }, [fetchTasks, orgId, selectedBoard]);

  const moveTask = useCallback(async (taskId, newStatus) => {
    console.log(`Moving task ${taskId} to ${newStatus}`);
    try {
      const response = await post('/editTask', { taskId, status: newStatus, orgId });
      console.log('Move task response:', response);

      if (response && response.taskId) {
        setTasks(prevTasks => 
          prevTasks.map(task => 
            task.taskId === taskId ? { ...task, status: newStatus } : task
          )
        );
        console.log(`Task ${taskId} moved to ${newStatus}`);
      } else {
        console.error('Unexpected response format:', response);
        throw new Error('Invalid response format');
      }
    } catch (err) {
      console.error('Error moving task:', err);
    }
  }, [post, orgId]);

  const handleAddOrUpdateTask = async () => {
    if (newTask.title && orgId && selectedBoard) {
      try {
        const payload = {
          title: newTask.title,
          description: newTask.description,
          orgId,
          boardId: selectedBoard
        };
        console.log('Sending payload:', payload);

        let response;
        if (editingTask) {
          response = await post('/editTask', {
            ...payload,
            taskId: editingTask
          });
        } else {
          response = await post('/addTask', payload);
        }

        console.log('API response:', response);

        if (response) {
          fetchTasks();
          setNewTask({ title: '', description: '' });
          setIsTaskModalOpen(false);
          setEditingTask(null);
        } else {
          console.error('API response does not contain data:', response);
          throw new Error('Invalid API response');
        }
      } catch (err) {
        console.error('Error adding/updating task:', err);
        alert(`Error ${editingTask ? 'updating' : 'adding'} task: ${err.message}`);
      }
    } else {
      alert('Task title, organization selection, and board selection are required');
    }
  };

  const handleDeleteTask = async (taskId) => {
    try {
      const response = await post('/deleteTask', { taskId, orgId });
      if (response) {
        setTasks(prevTasks => prevTasks.filter(task => task.taskId !== taskId));
      }
    } catch (err) {
      console.error('Error deleting task:', err);
    }
  };

  const handleEditTask = (taskId) => {
    const taskToEdit = tasks.find(task => task.taskId === taskId);
    setEditingTask(taskId);
    setNewTask({ title: taskToEdit.title, description: taskToEdit.description });
    setIsTaskModalOpen(true);
  };

  const handleAddBoard = async () => {
    if (newBoard && orgId) {
      try {
        const response = await post('/addBoard', { name: newBoard, orgId });
        if (response) {
          fetchBoards();
          setNewBoard('');
          setIsBoardModalOpen(false);
        } else {
          throw new Error('Invalid API response');
        }
      } catch (err) {
        console.error('Error adding board:', err);
        alert(`Error adding board: ${err.message}`);
      }
    } else {
      alert('Board name and organization selection are required');
    }
  };

  if (!orgId) {
    return <div className="text-center py-12">No organization selected. Please select an organization first.</div>;
  }

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="space-y-6 p-6 bg-gray-50 min-h-screen">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex flex-col space-y-4">
            <div className="flex justify-between items-center">
              <h2 className="text-3xl font-bold text-gray-800">Tasks</h2>
              <div className="flex space-x-2">
                <button
                  onClick={() => setIsBoardModalOpen(true)}
                  className="bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 transition duration-300 ease-in-out flex items-center justify-center"
                >
                  <Plus className="w-5 h-5 mr-2" />
                  New Board
                </button>
                <button
                  onClick={() => setIsTaskModalOpen(true)}
                  className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300 ease-in-out flex items-center justify-center"
                >
                  <Plus className="w-5 h-5 mr-2" />
                  New Task
                </button>
              </div>
            </div>
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {boards.map((board) => (
                  <button
                    key={board.boardId}
                    onClick={() => setSelectedBoard(board.boardId)}
                    className={`
                      whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm
                      ${board.boardId === selectedBoard
                        ? 'border-indigo-500 text-indigo-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}
                    `}
                  >
                    {board.name}
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {Object.values(columns).map(column => (
            <Column
              key={column.id}
              column={column}
              tasks={tasks}
              moveTask={moveTask}
              handleEditTask={handleEditTask}
              handleDeleteTask={handleDeleteTask}
            />
          ))}
        </div>

        {/* Task Modal */}
        {isTaskModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg w-full max-w-md relative">
              <button
                onClick={() => {
                  setIsTaskModalOpen(false);
                  setEditingTask(null);
                  setNewTask({ title: '', description: '' });
                }}
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition duration-300 ease-in-out"
              >
                <X className="w-6 h-6" />
              </button>
              <h2 className="text-2xl font-bold mb-6">{editingTask ? 'Edit Task' : 'Add New Task'}</h2>
              <div className="space-y-4">
                <input
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Task Title"
                  value={newTask.title}
                  onChange={(e) => setNewTask({ ...newTask, title: e.target.value })}
                />
                <textarea
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Task Description"
                  value={newTask.description}
                  onChange={(e) => setNewTask({ ...newTask, description: e.target.value })}
                  rows="3"
                />
              </div>
              <div className="flex justify-end space-x-2 mt-6">
                <button
                  className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition duration-300 ease-in-out"
                  onClick={() => {
                    setIsTaskModalOpen(false);
                    setEditingTask(null);
                    setNewTask({ title: '', description: '' });
                  }}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300 ease-in-out"
                  onClick={handleAddOrUpdateTask}
                >
                  {editingTask ? 'Update Task' : 'Add Task'}
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Board Modal */}
        {isBoardModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg w-full max-w-md relative">
              <button
                onClick={() => {
                  setIsBoardModalOpen(false);
                  setNewBoard('');
                }}
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition duration-300 ease-in-out"
              >
                <X className="w-6 h-6" />
              </button>
              <h2 className="text-2xl font-bold mb-6">Add New Board</h2>
              <div className="space-y-4">
                <input
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Board Name"
                  value={newBoard}
                  onChange={(e) => setNewBoard(e.target.value)}
                />
              </div>
              <div className="flex justify-end space-x-2 mt-6">
                <button
                  className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition duration-300 ease-in-out"
                  onClick={() => {
                    setIsBoardModalOpen(false);
                    setNewBoard('');
                  }}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition duration-300 ease-in-out"
                  onClick={handleAddBoard}
                >
                  Add Board
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </DndProvider>
  );
};

export default TasksPage;