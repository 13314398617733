import React, { useState, useEffect } from 'react';
import { Bell, Plus, X, Edit, Trash2, Mail, ExternalLink, AlertCircle, Check, AlertOctagon } from 'lucide-react';
import useApi from '../../hooks/api';
import { getValue } from '../../utils/userData';
import Loader from '../common/Loader';

const EmailInput = ({ emails, setEmails }) => {
  const [inputValue, setInputValue] = useState('');

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      addEmail();
    }
  };

  const addEmail = () => {
    const trimmedEmail = inputValue.trim();
    if (trimmedEmail && !emails.includes(trimmedEmail)) {
      setEmails([...emails, trimmedEmail]);
      setInputValue('');
    }
  };

  const removeEmail = (emailToRemove) => {
    setEmails(emails.filter(email => email !== emailToRemove));
  };

  return (
    <div className="space-y-2">
      <div className="flex flex-wrap gap-2 p-2 bg-gray-100 rounded-md">
        {emails.map(email => (
          <span key={email} className="bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-sm flex items-center">
            {email}
            <button onClick={() => removeEmail(email)} className="ml-1 text-blue-600 hover:text-blue-800">
              <X className="w-4 h-4" />
            </button>
          </span>
        ))}
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          onBlur={addEmail}
          className="flex-grow min-w-[100px] p-1 bg-transparent outline-none"
          placeholder="Type email and press Enter"
        />
      </div>
    </div>
  );
};

const AlertsPage = () => {
  const [alerts, setAlerts] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [newAlert, setNewAlert] = useState({ keywordId: '', campaignId: '', platforms: [], emails: [] });
  const [editingAlertId, setEditingAlertId] = useState(null);
  const { post, loading, error } = useApi();
  const orgId = localStorage.getItem('orgIdSelected');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetchAlerts();
    fetchCampaigns();
  }, []);

  useEffect(() => {
    if (newAlert.campaignId) {
      fetchKeywords(newAlert.campaignId);
    }
  }, [newAlert.campaignId]);

  const fetchAlerts = async () => {
    try {
      const response = await post('/getAlerts', { orgId });
      setAlerts(response.alerts);
    } catch (err) {
      console.error('Error fetching alerts:', err);
    }
  };

  const fetchCampaigns = async () => {
    try {
      const response = await post('/getCampaigns', {
        uid: getValue('uid'),
        orgId
      });

      const formattedCampaigns = response.map(campaign => ({
        name: campaign.campaignName,
        id: campaign.campaignId,
      }));
      setCampaigns(formattedCampaigns);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
  };

  const fetchKeywords = async (campaignId) => {
    try {
      const response = await post('/getAllKeywords', { 
        uid: getValue('uid'), 
        orgId, 
        campaignId 
      });
      setKeywords(response);
    } catch (err) {
      console.error('Error fetching keywords:', err);
    }
  };

  const handleAddAlert = async () => {
    try {
      if (editingAlertId) {
        await post('/updateAlert', { ...newAlert, alertId: editingAlertId });
      } else {
        await post('/addAlert', { ...newAlert, orgId });
      }
      fetchAlerts();
      setShowAddModal(false);
      setNewAlert({ keywordId: '', campaignId: '', platforms: [], emails: [] });
      setEditingAlertId(null);
    } catch (err) {
      console.error('Error adding/updating alert:', err);
    }
  };

  const handleDeleteAlert = async (alertId) => {
    try {
      await post('/deleteAlert', { alertId });
      fetchAlerts();
    } catch (err) {
      console.error('Error deleting alert:', err);
    }
  };

  const handleEditAlert = (alert) => {
    setEditingAlertId(alert._id);
    setNewAlert({ 
      keywordId: alert.keywordId, 
      campaignId: alert.campaignId, 
      platforms: alert.platforms, 
      emails: alert.emails 
    });
    fetchKeywords(alert.campaignId);
    setShowAddModal(true);
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="flex flex-col min-h-screen bg-gray-50 p-4 md:p-8">
      <div className="flex-grow">
        {/* Header Section */}
        <div className="bg-white rounded-lg shadow-md p-4 md:p-6 mb-8">
          <h1 className="text-3xl font-semibold text-gray-800 mb-4">Alerts Dashboard</h1>
          <p className="text-gray-600 mb-6">Manage and track your keyword alerts</p>
          <div className="flex justify-between items-center">
            <div className="text-2xl font-semibold text-gray-800">Your Alerts</div>
            <button
              onClick={() => setShowAddModal(true)}
              className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 flex items-center"
            >
              <Plus className="w-5 h-5 mr-2" /> New Alert
            </button>
          </div>
        </div>

        {/* Alerts Grid or No Alerts Message */}
        {alerts.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {alerts.map(alert => (
              <div key={alert._id} className="bg-white p-4 md:p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                <div className="flex justify-between items-start">
                  <h3 className="text-xl font-semibold text-gray-800">{alert.keyword}</h3>
                  <div className="flex space-x-2">
                    <button onClick={() => handleEditAlert(alert)} className="text-blue-500 hover:text-blue-700">
                      <Edit className="w-5 h-5" />
                    </button>
                    <button onClick={() => handleDeleteAlert(alert._id)} className="text-red-500 hover:text-red-700">
                      <Trash2 className="w-5 h-5" />
                    </button>
                  </div>
                </div>
                <p className="text-sm text-gray-600 mt-2">Campaign: {alert.campaignName}</p>
                <div className="mt-4 flex flex-wrap gap-2">
                  {alert.platforms.map(platform => (
                    <span key={platform} className="px-2 py-1 bg-blue-100 text-blue-800 rounded-full text-sm">
                      {platform}
                    </span>
                  ))}
                </div>
                <div className="mt-4 flex items-center text-gray-600">
                  <Mail className="w-4 h-4 mr-2" />
                  <span>{alert.emails.join(', ')}</span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-12">
            <AlertOctagon className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">No alerts found</h3>
            <p className="mt-1 text-sm text-gray-500">Get started by creating a new alert.</p>
            <div className="mt-6">
              <button
                onClick={() => setShowAddModal(true)}
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <Plus className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                New Alert
              </button>
            </div>
          </div>
        )}

      </div>

      {/* Add/Edit Alert Modal */}
      {showAddModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg w-full max-w-md m-4 p-4 md:p-6">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-2xl font-bold text-gray-800">{editingAlertId ? 'Edit Alert' : 'Add New Alert'}</h3>
              <button 
                onClick={() => {
                  setShowAddModal(false);
                  setEditingAlertId(null);
                  setNewAlert({ keywordId: '', campaignId: '', platforms: [], emails: [] });
                }}
                className="text-gray-400 hover:text-gray-600 transition duration-150"
              >
                <X className="h-6 w-6" />
              </button>
            </div>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Campaign</label>
                <select
                  value={newAlert.campaignId}
                  onChange={(e) => {
                    const selectedCampaignId = e.target.value;
                    setNewAlert({ ...newAlert, campaignId: selectedCampaignId, keywordId: '' });
                    if (selectedCampaignId) {
                      fetchKeywords(selectedCampaignId);
                    }
                  }}
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Select a campaign</option>
                  {campaigns.map(campaign => (
                    <option key={campaign.id} value={campaign.id}>
                      {campaign.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Keyword</label>
                <select
                  value={newAlert.keywordId}
                  onChange={(e) => setNewAlert({ ...newAlert, keywordId: e.target.value })}
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  disabled={!newAlert.campaignId}
                >
                  <option value="">Select a keyword</option>
                  {keywords.map(keyword => (
                    <option key={keyword.keywordId} value={keyword.keywordId}>
                      {keyword.keyword}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Platforms</label>
                <div className="flex flex-wrap gap-2">
                  {['Reddit', 'Twitter', 'Hacker News'].map(platform => (
                    <label key={platform} className="inline-flex items-center">
                      <input
                        type="checkbox"
                        checked={newAlert.platforms.includes(platform)}
                        onChange={(e) => {
                          const updatedPlatforms = e.target.checked
                            ? [...newAlert.platforms, platform]
                            : newAlert.platforms.filter(p => p !== platform);
                          setNewAlert({ ...newAlert, platforms: updatedPlatforms });
                        }}
                        className="form-checkbox h-5 w-5 text-blue-600"
                      />
                      <span className="ml-2 text-gray-700">{platform}</span>
                    </label>
                  ))}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Email Notifications</label>
                <EmailInput
                  emails={newAlert.emails}
                  setEmails={(emails) => setNewAlert({ ...newAlert, emails })}
                />
              </div>
            </div>
            <div className="mt-6 flex justify-end space-x-3">
              <button
                onClick={() => {
                  setShowAddModal(false);
                  setEditingAlertId(null);
                  setNewAlert({ keywordId: '', campaignId: '', platforms: [], emails: [] });
                }}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition duration-300"
              >
                Cancel
              </button>
              <button
                onClick={handleAddAlert}
                className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                {editingAlertId ? 'Update Alert' : 'Add Alert'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AlertsPage;