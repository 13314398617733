import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="font-sans flex flex-col min-h-screen">
      <main className="flex-grow pt-16 sm:pt-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl sm:text-5xl font-bold mb-8 text-center text-gray-900">Terms and Conditions</h1>
          <div className="bg-white rounded-lg shadow-lg p-6 sm:p-10">
            {/* Acceptance of Terms */}
            <section className="mb-10">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">1. Acceptance of Terms</h2>
              <p className="text-gray-600">
                By accessing or using SocialSignalAI, you agree to be bound by these Terms and Conditions and all applicable laws and regulations. If you do not agree with any part of these terms, you may not use our service.
              </p>
            </section>

            {/* Use of Service */}
            <section className="mb-10">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">2. Use of Service</h2>
              <p className="text-gray-600">
                You agree to use SocialSignalAI only for lawful purposes and in accordance with these Terms. You are responsible for maintaining the confidentiality of your account and password.
              </p>
            </section>

            {/* Content Responsibility */}
            <section className="mb-10">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">3. Content Responsibility</h2>
              <p className="text-gray-600">
                You are solely responsible for the content you post, share, or engage with using our service. SocialSignalAI does not endorse, support, represent or guarantee the completeness, truthfulness, accuracy, or reliability of any content posted via the service.
              </p>
            </section>

            {/* Third-Party Platforms */}
            <section className="mb-10">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">4. Third-Party Platforms</h2>
              <p className="text-gray-600">
                Our service interacts with third-party platforms such as Reddit, X (Twitter), and HackerNews. You agree to comply with the terms of service of these platforms. SocialSignalAI is not responsible for any actions taken by these platforms against your account due to your use of our service.
              </p>
            </section>

            {/* Account Suspension and Termination */}
            <section className="mb-10">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">5. Account Suspension and Termination</h2>
              <p className="text-gray-600">
                We reserve the right to suspend or terminate your account if you violate these Terms or use the service in a manner that could cause harm to SocialSignalAI or other users. Additionally, if your account on Reddit, X, or HackerNews is suspended or terminated due to your use of our service, including but not limited to spamming or violating their terms of service, SocialSignalAI bears no responsibility for such actions.
              </p>
            </section>

            {/* Limitation of Liability */}
            <section className="mb-10">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">6. Limitation of Liability</h2>
              <p className="text-gray-600">
                SocialSignalAI shall not be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the service.
              </p>
            </section>

            {/* Changes to Terms */}
            <section className="mb-10">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">7. Changes to Terms</h2>
              <p className="text-gray-600">
                We reserve the right to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect.
              </p>
            </section>

            {/* Governing Law */}
            <section className="mb-10">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">8. Governing Law</h2>
              <p className="text-gray-600">
                These Terms shall be governed and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.
              </p>
            </section>

            {/* Contact Us */}
            <section className="mb-10">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">9. Contact Us</h2>
              <p className="text-gray-600">
                If you have any questions about these Terms, please contact us at terms@socialsignalai.com.
              </p>
            </section>
          </div>
        </div>
      </main>
    </div>
  );
};

export default TermsAndConditions;