import React, { useState, useEffect } from 'react';
import { Save, Users, DollarSign, Check, Building, AlertCircle, X, Plus, Trash2, Edit2, ChevronDown, Shield, Globe, Zap } from 'lucide-react';
import useApi from '../../hooks/api';
import { getValue } from '../../utils/userData';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Toggle = ({ checked, onChange, disabled }) => (
  <label className={`relative inline-block w-10 h-6 ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}>
    <input
      type="checkbox"
      className="opacity-0 w-0 h-0"
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
    <span
      className={`absolute top-0 left-0 right-0 bottom-0 rounded-full transition-colors duration-200 ease-in-out ${
        checked ? 'bg-blue-500' : 'bg-gray-300'
      }`}
    >
      <span
        className={`absolute left-0.5 top-0.5 bg-white w-5 h-5 rounded-full transition-transform duration-200 ease-in-out ${
          checked ? 'transform translate-x-4' : ''
        }`}
      ></span>
    </span>
  </label>
);

const MonthlyUsageChart = () => {
  const [usageData, setUsageData] = useState(null);
  const { post, loading, error } = useApi();

  useEffect(() => {
    fetchRemainingMonthlyUsage();
  }, []);

  const fetchRemainingMonthlyUsage = async () => {
    try {
      const response = await post('/get-remaining-monthly-usage', {
        orgId: localStorage.getItem('orgIdSelected')
      });
      setUsageData(response);
    } catch (err) {
      console.error('Error fetching remaining monthly usage:', err);
    }
  };

  if (loading) return <div>Loading usage data...</div>;
  if (error) return <div>Error loading usage data</div>;
  if (!usageData) return null;

  const chartData = {
    labels: Object.keys(usageData),
    datasets: [
      {
        label: 'Remaining Usage',
        data: Object.values(usageData),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Remaining Monthly Usage',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="mt-8">
      <h3 className="text-xl font-semibold mb-4">Remaining Monthly Usage</h3>
      <Bar data={chartData} options={options} />
    </div>
  );
};

const APISettingsTab = () => {
  const { post, loading, error: apiError } = useApi();
  const [apiSettings, setApiSettings] = useState({
    redditEnabled: false,
    hackerNewsEnabled: false,
    twitterEnabled: false,
    monitoringFrequency: 60,
    redditCredentials: [],
    hackerNewsCredentials: [],
    twitterCredentials: [],
    postingEnabled: false,
    maxPostsPerCampaign: 5,
    commentInterval: 60,
    postInterval: 300,
    subreddits: []
  });
  const [editingCredential, setEditingCredential] = useState(null);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [newSubreddit, setNewSubreddit] = useState('');
  const [error, setError] = useState(null);
  const [orgId, setOrgId] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const storedOrgId = localStorage.getItem('orgIdSelected');
    setOrgId(storedOrgId);
    if (storedOrgId) {
      fetchApiSettings(storedOrgId);
    }
  }, []);

  const fetchApiSettings = async (orgId) => {
    try {
      const response = await post('/get-api-settings', { 
        uid: getValue('uid'),
        orgId: orgId
      });
      setApiSettings(response.data);
    } catch (err) {
      console.error('Error fetching API settings:', err);
      setError(err.message || 'An error occurred while fetching settings');
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setApiSettings({
      ...apiSettings,
      [e.target.name]: value
    });
  };

  const handleToggleChange = (platform) => {
    setApiSettings({
      ...apiSettings,
      [`${platform}Enabled`]: !apiSettings[`${platform}Enabled`]
    });
  };

  const handleCredentialChange = (platform, id, field, value) => {
    const updatedCredentials = apiSettings[`${platform}Credentials`].map(cred =>
      cred.id === id ? { ...cred, [field]: value } : cred
    );
    setApiSettings({
      ...apiSettings,
      [`${platform}Credentials`]: updatedCredentials
    });
  };

  const addCredential = (platform) => {
    const newId = Math.max(0, ...apiSettings[`${platform}Credentials`].map(c => c.id)) + 1;
    const newCredential = platform === 'reddit'
      ? { id: newId, apiKey: '', username: '', password: '', clientId: '', clientSecret: '', userAgent: '' }
      : { id: newId, username: '', password: '' };
    setApiSettings({
      ...apiSettings,
      [`${platform}Credentials`]: [...apiSettings[`${platform}Credentials`], newCredential]
    });
    setEditingCredential({ platform, id: newId });
  };

  const removeCredential = (platform, id) => {
    const updatedCredentials = apiSettings[`${platform}Credentials`].filter(cred => cred.id !== id);
    setApiSettings({
      ...apiSettings,
      [`${platform}Credentials`]: updatedCredentials
    });
  };

  const addSubreddit = () => {
    if (newSubreddit && !apiSettings.subreddits.includes(newSubreddit)) {
      setApiSettings({
        ...apiSettings,
        subreddits: [...apiSettings.subreddits, newSubreddit]
      });
      setNewSubreddit('');
    }
  };

  const removeSubreddit = (subreddit) => {
    setApiSettings({
      ...apiSettings,
      subreddits: apiSettings.subreddits.filter(s => s !== subreddit)
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      const updatedSettings = {
        ...apiSettings,
        // Remove the password and clientSecret masking
      };

      await post('/save-api-settings', {
        uid: getValue('uid'),
        orgId: orgId,
        ...updatedSettings
      });
      setSaveSuccess(true);
      setTimeout(() => setSaveSuccess(false), 3000);
    } catch (err) {
      console.error('Error saving API settings:', err);
      setError(err.message || 'An error occurred while saving settings');
    }
  };
  
  const renderCredentialInputs = (platform) => (
    <div className="space-y-4 bg-gray-50 p-4 rounded-lg">
      <h3 className="text-lg font-semibold">
        {platform === 'reddit' ? 'Reddit' : platform === 'hackerNews' ? 'Hacker News' : 'Twitter'} Credentials
      </h3>
      <div className="space-y-4">
        {apiSettings[`${platform}Credentials`].map((cred) => (
          <div key={cred.id} className="bg-white p-4 rounded-lg shadow">
            {editingCredential && editingCredential.platform === platform && editingCredential.id === cred.id ? (
              <div className="space-y-2">
                <input
                  className="w-full p-2 border rounded-md"
                  type="text"
                  placeholder="Username"
                  value={cred.username}
                  onChange={(e) => handleCredentialChange(platform, cred.id, 'username', e.target.value)}
                />
                <input
                  className="w-full p-2 border rounded-md"
                  type="password"
                  placeholder="Password"
                  value={cred.password}
                  onChange={(e) => handleCredentialChange(platform, cred.id, 'password', e.target.value)}
                />
                {platform === 'reddit' && (
                  <>
                    <input
                      className="w-full p-2 border rounded-md"
                      type="text"
                      placeholder="API Key"
                      value={cred.apiKey}
                      onChange={(e) => handleCredentialChange(platform, cred.id, 'apiKey', e.target.value)}
                    />
                    <input
                      className="w-full p-2 border rounded-md"
                      type="text"
                      placeholder="Client ID"
                      value={cred.clientId}
                      onChange={(e) => handleCredentialChange(platform, cred.id, 'clientId', e.target.value)}
                    />
                    <input
                      className="w-full p-2 border rounded-md"
                      type="password"
                      placeholder="Client Secret"
                      value={cred.clientSecret}
                      onChange={(e) => handleCredentialChange(platform, cred.id, 'clientSecret', e.target.value)}
                    />
                    <input
                      className="w-full p-2 border rounded-md"
                      type="text"
                      placeholder="User Agent"
                      value={cred.userAgent}
                      onChange={(e) => handleCredentialChange(platform, cred.id, 'userAgent', e.target.value)}
                    />
                  </>
                )}
                <button
                  type="button"
                  onClick={() => setEditingCredential(null)}
                  className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300"
                >
                  Save
                </button>
              </div>
            ) : (
              <div>
                <p><strong>Username:</strong> {cred.username}</p>
                <p><strong>Password:</strong> {cred.password ? '********' : 'Not set'}</p>
                {platform === 'reddit' && (
                  <>
                    <p><strong>API Key:</strong> {cred.apiKey}</p>
                    <p><strong>Client ID:</strong> {cred.clientId}</p>
                    <p><strong>Client Secret:</strong> {cred.clientSecret ? '********' : 'Not set'}</p>
                    <p><strong>User Agent:</strong> {cred.userAgent}</p>
                  </>
                )}
                <div className="mt-2 flex space-x-2">
                  <button
                    type="button"
                    onClick={() => setEditingCredential({ platform, id: cred.id })}
                    className="text-blue-500 hover:text-blue-700 transition duration-300"
                  >
                    <Edit2 className="w-5 h-5" />
                  </button>
                  <button
                    type="button"
                    onClick={() => removeCredential(platform, cred.id)}
                    className="text-red-500 hover:text-red-700 transition duration-300"
                  >
                    <Trash2 className="w-5 h-5" />
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <button
        type="button"
        onClick={() => addCredential(platform)}
        className="flex items-center text-blue-500 hover:text-blue-700 transition duration-300"
      >
       <Plus className="w-5 h-5 mr-1" /> Add {platform === 'reddit' ? 'Reddit' : platform === 'hackerNews' ? 'Hacker News' : 'Twitter'} Credential
      </button>
    </div>
  );

  return (
    <form onSubmit={handleSubmit} className="space-y-8">
      <div className="space-y-6 bg-gray-50 p-6 rounded-lg">
        <h2 className="text-2xl font-bold mb-4">Platform Settings</h2>
        
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <label className="font-semibold">Reddit</label>
            <Toggle
              checked={apiSettings.redditEnabled}
              onChange={() => handleToggleChange('reddit')}
            />
          </div>
          {apiSettings.redditEnabled && renderCredentialInputs('reddit')}
        </div>

        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <label className="font-semibold">Hacker News</label>
            <Toggle
              checked={apiSettings.hackerNewsEnabled}
              onChange={() => handleToggleChange('hackerNews')}
            />
          </div>
          {apiSettings.hackerNewsEnabled && renderCredentialInputs('hackerNews')}
        </div>

        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <label className="font-semibold">Twitter</label>
            <Toggle
              checked={apiSettings.twitterEnabled}
              onChange={() => handleToggleChange('twitter')}
            />
          </div>
          {apiSettings.twitterEnabled && renderCredentialInputs('twitter')}
        </div>
      </div>

      <div className="space-y-6 bg-gray-50 p-6 rounded-lg">
        <h2 className="text-2xl font-bold mb-4">Monitoring Settings</h2>
        <div>
          <label className="block mb-2 font-semibold">Monitoring Frequency (minutes)</label>
          <input
            className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            type="number"
            name="monitoringFrequency"
            value={apiSettings.monitoringFrequency}
            onChange={handleInputChange}
            placeholder="Enter monitoring frequency"
          />
        </div>
      </div>

      <div className="space-y-6 bg-gray-50 p-6 rounded-lg">
        <h2 className="text-2xl font-bold mb-4">Posting Settings</h2>
        <div className="flex items-center justify-between">
          <label className="font-semibold">Posting Enabled</label>
          <Toggle
            checked={apiSettings.postingEnabled}
            onChange={() => handleInputChange({ target: { name: 'postingEnabled', type: 'checkbox', checked: !apiSettings.postingEnabled } })}
          />
        </div>
        <div>
          <label className="block mb-2 font-semibold">Max Posts Per Campaign</label>
          <input
            className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            type="number"
            name="maxPostsPerCampaign"
            value={apiSettings.maxPostsPerCampaign}
            onChange={handleInputChange}
            placeholder="Enter max posts per campaign"
          />
        </div>
        <div>
          <label className="block mb-2 font-semibold">Comment Interval (seconds)</label>
          <input
            className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            type="number"
            name="commentInterval"
            value={apiSettings.commentInterval}
            onChange={handleInputChange}
            placeholder="Enter comment interval"
          />
        </div>
        <div>
          <label className="block mb-2 font-semibold">Post Interval (seconds)</label>
          <input
            className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            type="number"
            name="postInterval"
            value={apiSettings.postInterval}
            onChange={handleInputChange}
            placeholder="Enter post interval"
          />
        </div>
      </div>

      <div className="space-y-6 bg-gray-50 p-6 rounded-lg">
        <h2 className="text-2xl font-bold mb-4">Subreddit List</h2>
        <div className="flex space-x-2">
          <input
            className="flex-grow p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            type="text"
            value={newSubreddit}
            onChange={(e) => setNewSubreddit(e.target.value)}
            placeholder="Enter subreddit name"
          />
          <button
            type="button"
            onClick={addSubreddit}
            className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300"
          >
            Add
          </button>
        </div>
        <div className="flex flex-wrap gap-2 mt-4">
          {apiSettings.subreddits.map((subreddit) => (
            <div key={subreddit} className="bg-blue-100 px-3 py-1 rounded-full flex items-center">
              <span>{subreddit}</span>
              <button
                type="button"
                onClick={() => removeSubreddit(subreddit)}
                className="ml-2 text-red-500 hover:text-red-700 transition duration-300"
              >
                <X className="w-4 h-4" />
              </button>
            </div>
          ))}
        </div>
      </div>

      <button
        type="submit"
        className="w-full bg-indigo-600 text-white p-3 rounded-md hover:bg-blue-600 transition duration-300 flex items-center justify-center text-lg font-semibold"
        disabled={loading}
      >
        {loading ? 'Saving...' : (
          <>
            <Save className="w-6 h-6 mr-2" />
            Save Settings
          </>
        )}
      </button>
      {error && (
        <div className="text-red-500 mt-2 p-3 bg-red-100 rounded-md">
          <AlertCircle className="inline mr-2" />
          {error}
        </div>
      )}
      {saveSuccess && (
        <div className="text-green-500 mt-2 p-3 bg-green-100 rounded-md">
          Settings saved successfully!
        </div>
      )}
    </form>
  );
};

const OrganizationsAndMembersTab = () => {
  const [organizations, setOrganizations] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [newMember, setNewMember] = useState({ name: '', email: '', role: 'Member' });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { post, loading, error } = useApi();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetchOrganizationsAndMembers();
  }, []);

  const fetchOrganizationsAndMembers = async () => {
    try {
      const response = await post('/list-organizations-and-members', {
        uid: getValue('uid')
      });
      setOrganizations(response.organizations);
      if (response.organizations.length > 0) {
        setActiveTab(response.organizations[0].id);
      }
    } catch (err) {
      console.error('Error fetching organizations and members:', err);
      setErrorMessage('Failed to fetch organizations and team members. Please try again.');
    }
  };

  const handleAddMember = async (organizationId) => {
    if (newMember.name && newMember.email) {
      try {
        await post('/team-members', {
          uid: getValue('uid'),
          ...newMember,
          organizationId
        });
        setNewMember({ name: '', email: '', role: 'Member' });
        fetchOrganizationsAndMembers();
        setErrorMessage('');
        setSuccessMessage('Team member invited successfully');
        setTimeout(() => setSuccessMessage(''), 3000);
      } catch (err) {
        console.error('Error adding team member:', err);
        setErrorMessage(err.message || 'Failed to add team member. Please try again.');
      }
    }
  };

  const handleDeleteMember = async (organizationId, email) => {
    if (window.confirm('Are you sure you want to delete this team member?')) {
      try {
        console.log('?printing uid' , getValue('uid'))
        await post('/delete-team-member', {
          uid: getValue('uid'),
          organizationId,
          email
        });
        fetchOrganizationsAndMembers();
        setErrorMessage('');
        setSuccessMessage('Team member deleted successfully');
        setTimeout(() => setSuccessMessage(''), 3000);
      }catch(e){

      }
    }
  };

  const renderMemberList = (members, organizationId) => (
    <div className="space-y-4">
      <h3 className="font-semibold text-lg">Team Members</h3>
      {members.length > 0 ? (
        members.map(member => (
          <div key={member.id} className="bg-gray-50 p-4 rounded-lg shadow">
            <div className="flex justify-between items-center">
              <div>
                <h3 className="font-semibold">{member.name}</h3>
                <p className="text-sm text-gray-600">{member.email}</p>
              </div>
              <div className="flex items-center space-x-2">
                <span className="bg-gray-200 px-2 py-1 rounded-full text-xs font-semibold">
                  {member.role}
                </span>
                {member.inviteStatus && (
                  <span className={`px-2 py-1 rounded-full text-xs font-semibold ${
                    member.inviteStatus === 'Accepted' ? 'bg-green-200' : 'bg-yellow-200'
                  }`}>
                    {member.inviteStatus}
                  </span>
                )}
                {console.log(member)}
                <button
                  onClick={() => handleDeleteMember(member.organizationId, member.email)}
                  className="text-red-500 hover:text-red-700 transition duration-300"
                >
                  <Trash2 className="h-4 w-4" />
                </button>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className="text-gray-500">No team members found.</p>
      )}
    </div>
  );

  return (
    <div className="w-full">
      <div className="flex mb-4 border-b">
        {organizations.map(org => (
          <button
            key={org.id}
            className={`px-4 py-2 text-sm font-medium ${
              activeTab === org.id
                ? 'border-b-2 border-blue-500 text-blue-600'
                : 'text-gray-500 hover:text-gray-700'
            }`}
            onClick={() => setActiveTab(org.id)}
          >
            {org.name}
          </button>
        ))}
      </div>
      {organizations.map(org => (
        <div key={org.id} className={activeTab === org.id ? 'block' : 'hidden'}>
          <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-2xl font-bold mb-2">{org.name}</h2>
            <p className="text-gray-600 mb-4">Manage team members for this organization</p>
            <div className="space-y-4">
              <div className="flex flex-col md:flex-row justify-between items-center mb-6">
                <input
                  className="flex-grow p-2 border rounded mb-2 md:mb-0 md:mr-2"
                  placeholder="Name"
                  value={newMember.name}
                  onChange={(e) => setNewMember({ ...newMember, name: e.target.value })}
                />
                <input
                  className="flex-grow p-2 border rounded mb-2 md:mb-0 md:mr-2"
                  placeholder="Email"
                  value={newMember.email}
                  onChange={(e) => setNewMember({ ...newMember, email: e.target.value })}
                />
                <select
                  className="p-2 border rounded mb-2 md:mb-0 md:mr-2"
                  value={newMember.role}
                  onChange={(e) => setNewMember({ ...newMember, role: e.target.value })}
                >
                  <option value="Admin">Admin</option>
                  <option value="Member">Member</option>
                </select>
                <button
                  className="bg-indigo-600 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300 flex items-center"
                  onClick={() => handleAddMember(org.id)}
                  disabled={loading}
                >
                  <Plus className="mr-2 h-4 w-4" /> Add Member
                </button>
              </div>
              {errorMessage && (
                <div className="text-red-500 p-3 bg-red-100 rounded-md flex items-center">
                  <AlertCircle className="mr-2 h-4 w-4" />
                  {errorMessage}
                </div>
              )}
              {successMessage && (
                <div className="text-green-500 p-3 bg-green-100 rounded-md flex items-center">
                  <Check className="mr-2 h-4 w-4" />
                  {successMessage}
                </div>
              )}
              {renderMemberList(org.members, org.id)}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const CurrentPlanTab = () => {
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [usageData, setUsageData] = useState(null);
  const [showChangePlanModal, setShowChangePlanModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedBillingCycle, setSelectedBillingCycle] = useState('monthly');
  const [changePlanError, setChangePlanError] = useState(null);
  const { post, loading, error } = useApi();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Define the plans array
  const plans = [
    { id: 'starter', name: 'Starter Plan', monthlyPrice: 29, yearlyPrice: 279 },
    { id: 'pro', name: 'Pro Plan', monthlyPrice: 79, yearlyPrice: 759 },
    { id: 'business', name: 'Business Plan', monthlyPrice: 199, yearlyPrice: 1911 },
    { id: 'agency', name: 'Agency Plan', monthlyPrice: 499, yearlyPrice: 4791 }
  ];

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetchSubscriptionDetails();
    fetchUsageData();
  }, []);

  const fetchSubscriptionDetails = async () => {
    try {
      const response = await post('/user-subscription-details', {
        uid: getValue('uid'),
        orgId: localStorage.getItem('orgIdSelected')
      });
      setSubscriptionDetails(response);
    } catch (err) {
      console.error('Error fetching subscription details:', err);
    }
  };

  const fetchUsageData = async () => {
    try {
      const response = await post('/get-remaining-monthly-usage', {
        orgId: localStorage.getItem('orgIdSelected')
      });
      setUsageData(response);
    } catch (err) {
      console.error('Error fetching usage data:', err);
    }
  };

  const handleChangePlan = async () => {
    if (!selectedPlan || !subscriptionDetails.isAdmin) return;

    if (subscriptionDetails.planName === 'Trial') {
      // If the current plan is Trial, use the checkout logic
      try {
        const response = await post('/create-checkout-session', {
          uid: getValue('uid'),
          email: getValue('email'),
          plan: selectedPlan,
          billingCycle: selectedBillingCycle,
          organizationId: localStorage.getItem('orgIdSelected')
        });

        if (response.url) {
          // Redirect to Stripe Checkout
          window.location.href = response.url;
        } else {
          setChangePlanError('No checkout URL received');
        }
      } catch (err) {
        console.error('Error creating checkout session:', err);
        setChangePlanError(err.message || 'An error occurred while creating the checkout session. Please try again.');
      }
    } else {
      // For non-trial plans, use the existing change plan logic
      try {
        // const { uid, organizationId, plan, billingCycle } = req.body;
        setChangePlanError(null);
        const response = await post('/change-plan-settings', {
          uid: getValue('uid'),
          organizationId: localStorage.getItem('orgIdSelected'),
          subscriptionId: subscriptionDetails.subscriptionId,
          plan: selectedPlan,
          billingCycle: selectedBillingCycle
        });

        if (response.subscription) {
          setSubscriptionDetails(response.subscription);
          setShowChangePlanModal(false);
        }
      } catch (err) {
        console.error('Error changing plan:', err);
        setChangePlanError(err.message || 'An error occurred while changing the plan. Please try again.');
      }
    }
  };

  if (loading) return <div>Loading subscription details...</div>;
  if (error) return <div>Error loading subscription details</div>;
  if (!subscriptionDetails || !usageData) return null;

  const getPlanColor = (planName) => {
    switch (planName.toLowerCase()) {
      case 'trial': return 'bg-yellow-100 text-yellow-800';
      case 'starter': return 'bg-green-100 text-green-800';
      case 'pro': return 'bg-blue-100 text-blue-800';
      case 'business': return 'bg-purple-100 text-purple-800';
      case 'agency': return 'bg-indigo-100 text-indigo-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="space-y-8">
      <div className="bg-white p-6 rounded-lg shadow-md relative overflow-hidden">
        <div className={`absolute top-0 right-0 px-4 py-2 rounded-bl-lg ${getPlanColor(subscriptionDetails.planName)}`}>
          {subscriptionDetails.planName}
        </div>
        <h3 className="text-3xl font-bold mb-6">Your Current Plan</h3>
        <div className="flex items-center justify-between mb-6">
          <div>
            <p className="text-4xl font-bold text-indigo-600">
              {subscriptionDetails.planName === 'Trial' ? 'Free' : `$${subscriptionDetails.amount}`}
            </p>
            {subscriptionDetails.planName !== 'Trial' && (
              <p className="text-gray-500">per {subscriptionDetails.billingCycle}</p>
            )}
          </div>
          {subscriptionDetails.isAdmin && (
            <button
              className="bg-indigo-600 text-white px-6 py-2 rounded-md hover:bg-indigo-700 transition duration-300 ease-in-out"
              onClick={() => setShowChangePlanModal(true)}
            >
              {subscriptionDetails.planName === 'Trial' ? 'Upgrade Plan' : 'Change Plan'}
            </button>
          )}
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="bg-gray-50 p-4 rounded-lg">
            <h4 className="font-semibold mb-2">Plan Type</h4>
            <p>{subscriptionDetails.planName === 'Trial' ? 'Trial' : 'Organization Plan'}</p>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg">
            <h4 className="font-semibold mb-2">Status</h4>
            <p className="capitalize">{subscriptionDetails.status}</p>
          </div>
          {subscriptionDetails.planName !== 'Trial' && (
            <>
              <div className="bg-gray-50 p-4 rounded-lg">
                <h4 className="font-semibold mb-2">Billing Cycle</h4>
                <p className="capitalize">{subscriptionDetails.billingCycle}</p>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg">
                <h4 className="font-semibold mb-2">Next Billing Date</h4>
                <p>{subscriptionDetails.nextBillingDate ? new Date(subscriptionDetails.nextBillingDate).toLocaleDateString() : 'N/A'}</p>
              </div>
            </>
          )}
          <div className="bg-gray-50 p-4 rounded-lg">
            <h4 className="font-semibold mb-2">Start Date</h4>
            <p>{new Date(subscriptionDetails.startDate).toLocaleDateString()}</p>
          </div>
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-md">
        <h3 className="text-2xl font-bold mb-4">Usage Overview</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {Object.entries(usageData).map(([feature, { limit, used, remaining }]) => (
            <div key={feature} className="bg-gray-100 p-4 rounded-lg">
              <h4 className="text-lg font-semibold mb-2 capitalize">{feature.replace(/([A-Z])/g, ' $1').trim()}</h4>
              <div className="flex justify-between mb-2">
                <span>Used: {used}</span>
                <span>Limit: {limit}</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div 
                  className="bg-blue-600 h-2.5 rounded-full" 
                  style={{ width: `${Math.min((used / limit) * 100, 100)}%` }}
                ></div>
              </div>
              <p className="text-sm mt-2 text-gray-600">
                {remaining} remaining
              </p>
            </div>
          ))}
        </div>
      </div>

      {showChangePlanModal && subscriptionDetails.isAdmin && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg shadow-xl w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold">{subscriptionDetails.planName === 'Trial' ? 'Upgrade Plan' : 'Change Plan'}</h3>
              <button onClick={() => setShowChangePlanModal(false)}>
                <X className="w-6 h-6" />
              </button>
            </div>
            <div className="mb-4">
              <label className="block mb-2">Billing Cycle</label>
              <select
                className="w-full p-2 border rounded-md"
                value={selectedBillingCycle}
                onChange={(e) => setSelectedBillingCycle(e.target.value)}
              >
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
              </select>
            </div>
            <div className="space-y-4 mb-6">
              {plans.map((plan) => (
                <div
                  key={plan.id}
                  className={`p-4 border rounded-md cursor-pointer ${
                    selectedPlan === plan.id ? 'border-blue-500 bg-blue-50' : ''
                  }`}
                  onClick={() => setSelectedPlan(plan.id)}
                >
                  <h4 className="font-bold">{plan.name}</h4>
                  <p>
                    ${selectedBillingCycle === 'monthly' ? plan.monthlyPrice : plan.yearlyPrice}/
                    {selectedBillingCycle}
                  </p>
                </div>
              ))}
            </div>
            {changePlanError && (
              <div className="mb-4 text-red-500">
                <AlertCircle className="inline mr-2" />
                {changePlanError}
              </div>
            )}
            <button
              className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 transition duration-300 ease-in-out"
              onClick={handleChangePlan}
              disabled={!selectedPlan}
            >
              {subscriptionDetails.planName === 'Trial' ? 'Upgrade Plan' : 'Confirm Change'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const OrganizationsTab = () => {
  const [organizations, setOrganizations] = useState([]);
  const [newOrganization, setNewOrganization] = useState({ name: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const { post, loading, error } = useApi();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const fetchOrganizations = async () => {
    try {
      const response = await post('/list-organizations', {
        uid: getValue('uid')
      });
      setOrganizations(response.organizations);
    } catch (err) {
      console.error('Error fetching organizations:', err);
      setErrorMessage('Failed to fetch organizations. Please try again.');
    }
  };

  const handleAddOrganization = async () => {
    if (newOrganization.name) {
      try {
        await post('/organizations', {
          uid: getValue('uid'),
          organizationName: newOrganization.name
        });
        setNewOrganization({ name: '' });
        fetchOrganizations();
        setErrorMessage('');
      } catch (err) {
        console.error('Error adding organization:', err);
        setErrorMessage('Failed to add organization. Please try again.');
      }
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold mb-4">Add New Organization</h3>
        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
          <input
            className="flex-grow p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Organization Name"
            value={newOrganization.name}
            onChange={(e) => setNewOrganization({ name: e.target.value })}
          />
          <button
            className="bg-indigo-600 text-white p-2 rounded-md hover:bg-blue-600 transition duration-300 flex items-center"
            onClick={handleAddOrganization}
            disabled={loading}
          >
            <Plus className="mr-2 h-4 w-4" /> Add
          </button>
        </div>
        {errorMessage && (
          <div className="text-red-500 mt-2">
            <AlertCircle className="inline mr-2" />
            {errorMessage}
          </div>
        )}
      </div>

      <div className="bg-white p-6 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold mb-4">All Organizations</h3>
        <div className="space-y-4">
          {organizations.map(org => (
            <div key={org.organizationId} className="p-4 border rounded-md">
              <h4 className="font-semibold">{org.organizationName}</h4>
              <p className="text-sm text-gray-500">ID: {org.organizationId}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const SettingsPage = () => {
  const [activeTab, setActiveTab] = useState('api');
  const [access, setAccess] = useState({ limit: {} });
  const { post } = useApi();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetchAvailableFeatures();
  }, []);

  const fetchAvailableFeatures = async () => {
    try {
      const response = await post('/getAvailableAccess', { orgId: localStorage.getItem('orgIdSelected') });
      setAccess(response || { limit: {} });
    } catch (err) {
      console.error('Error fetching available features:', err);
    }
  };

  const tabs = [
    { id: 'api', label: 'Platform Settings', icon: <Zap className="w-5 h-5" /> },
    { 
      id: 'orgsAndMembers', 
      label: 'Team', 
      icon: <Users className="w-5 h-5" />,
      premium: !access.limit.multiAccountManagement
    },
    { id: 'plan', label: 'Subscription', icon: <DollarSign className="w-5 h-5" /> },
    { 
      id: 'organizations', 
      label: 'Organizations', 
      icon: <Building className="w-5 h-5" />,
      premium: !access.limit.multiAccountManagement
    },
    { id: 'security', label: 'Security', icon: <Shield className="w-5 h-5" /> },
    { id: 'integrations', label: 'Integrations', icon: <Globe className="w-5 h-5" />, comingSoon: true },
  ];

  return (
    <div className="min-h-screen p-4 md:p-6">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="md:flex">
            <div className="md:w-full lg:w-1/4 bg-gray-50 p-4 md:p-6 mb-6 md:mb-0">
              <h2 className="text-2xl font-bold text-gray-800 mb-6">Settings</h2>
              <nav>
                {tabs.map((tab) => (
                  <button
                    key={tab.id}
                    className={`flex items-center w-full px-4 py-3 text-left rounded-lg mb-2 transition-all duration-200 ${
                      activeTab === tab.id
                        ? 'bg-blue-500 text-white shadow-md'
                        : 'text-gray-600 hover:bg-gray-200'
                    } ${tab.comingSoon || tab.premium ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={() => !tab.comingSoon && !tab.premium && setActiveTab(tab.id)}
                    disabled={tab.comingSoon || tab.premium}
                  >
                    <div className="flex items-center flex-grow">
                      {tab.icon}
                      <span className="ml-3 font-medium">{tab.label}</span>
                    </div>
                    {tab.comingSoon && (
                      <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-indigo-100 text-indigo-800">
                        Soon
                      </span>
                    )}
                    {tab.premium && (
                      <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
                        Premium
                      </span>
                    )}
                  </button>
                ))}
              </nav>
            </div>
            <div className="md:w-full lg:w-3/4 p-4 md:p-6">
              <h3 className="text-2xl font-bold text-gray-800 mb-6">
                {tabs.find(tab => tab.id === activeTab)?.label}
              </h3>
              <div className="bg-white rounded-lg shadow-inner p-6">
                {activeTab === 'api' && <APISettingsTab />}
                {activeTab === 'orgsAndMembers' && (
                  access.limit.multiAccountManagement ? (
                    <OrganizationsAndMembersTab />
                  ) : (
                    <PremiumFeaturePrompt feature="Team Management" />
                  )
                )}
                {activeTab === 'plan' && <CurrentPlanTab />}
                {activeTab === 'organizations' && (
                  access.limit.multiAccountManagement ? (
                    <OrganizationsTab />
                  ) : (
                    <PremiumFeaturePrompt feature="Organizations Management" />
                  )
                )}
                {activeTab === 'security' && <SecurityTab />}
                {activeTab === 'integrations' && <IntegrationsTab />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PremiumFeaturePrompt = ({ feature }) => (
  <div className="bg-gradient-to-r from-yellow-400 to-orange-500 p-8 rounded-xl shadow-lg text-white relative overflow-hidden">
    <div className="absolute top-0 right-0 -mt-4 -mr-4 w-24 h-24 bg-white opacity-20 rounded-full"></div>
    <div className="absolute bottom-0 left-0 -mb-4 -ml-4 w-24 h-24 bg-white opacity-20 rounded-full"></div>
    <h3 className="text-3xl font-bold mb-4">{feature}</h3>
    <p className="text-xl mb-6">Upgrade to Premium to unlock this powerful feature!</p>
    <button className="bg-white text-orange-500 px-6 py-2 rounded-full font-semibold text-lg uppercase tracking-wide shadow-md hover:bg-orange-100 transition duration-300">
      Upgrade Now
    </button>
    <DollarSign className="absolute bottom-4 right-4 h-16 w-16 text-white opacity-20" />
  </div>
);

const SecurityTab = () => {
  const [passwordLastChanged, setPasswordLastChanged] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Fetch security settings
    // For now, we'll use dummy data
    setPasswordLastChanged(new Date(2023, 5, 15));
  }, []);

  return (
    <div className="space-y-6">
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h3 className="text-xl font-semibold mb-4">Password</h3>
        <p className="text-sm text-gray-600">
          Last changed: {passwordLastChanged ? passwordLastChanged.toLocaleDateString() : 'Never'}
        </p>
        <button className="mt-4 bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300">
          Change Password
        </button>
      </div>
    </div>
  );
};

const IntegrationsTab = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="space-y-6">
      <div className="bg-gradient-to-r from-blue-500 to-purple-600 p-8 rounded-xl shadow-lg text-white relative overflow-hidden">
        <div className="absolute top-0 right-0 -mt-4 -mr-4 w-24 h-24 bg-white opacity-20 rounded-full"></div>
        <div className="absolute bottom-0 left-0 -mb-4 -ml-4 w-24 h-24 bg-white opacity-20 rounded-full"></div>
        <h3 className="text-3xl font-bold mb-4">Integrations</h3>
        <p className="text-xl mb-6">Exciting new features are on the horizon!</p>
        <div className="inline-block bg-white text-purple-600 px-4 py-2 rounded-full font-semibold text-sm uppercase tracking-wide shadow-md">
          Coming Soon
        </div>
        <Globe className="absolute bottom-4 right-4 h-16 w-16 text-white opacity-20" />
      </div>
      <div className="bg-white p-6 rounded-xl shadow-md">
        <h4 className="text-xl font-semibold mb-4">What to expect</h4>
        <ul className="space-y-3">
          <li className="flex items-center">
            <Check className="h-5 w-5 text-green-500 mr-2" />
            <span>Seamless integration with popular tools</span>
          </li>
          <li className="flex items-center">
            <Check className="h-5 w-5 text-green-500 mr-2" />
            <span>Enhanced workflow automation</span>
          </li>
          <li className="flex items-center">
            <Check className="h-5 w-5 text-green-500 mr-2" />
            <span>Improved data synchronization</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SettingsPage;