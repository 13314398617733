import React, { useState, useRef, useEffect } from 'react';
import { X, Menu, Check, ChevronDown, ChevronRight, ChevronLeft } from 'lucide-react';
import { useNavigate, Link } from 'react-router-dom';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="bg-white shadow-sm fixed top-0 left-0 right-0 z-50">
      <nav className="container mx-auto px-4 py-4 flex flex-wrap justify-between items-center">
        <div className="flex items-center space-x-6">
          <Link to="/">
            <h1 className="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-indigo-600">
              SocialSignalAI
            </h1>
          </Link>

          <div className="hidden md:flex space-x-6">
            <div className="relative group">
              {/* <button className="text-gray-600 hover:text-gray-900 flex items-center text-sm">
                How it Works
                <ChevronDown className="ml-1 h-4 w-4" />
              </button> */}
              <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300">
                <div className="py-1">
                  <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Feature 1</a>
                  <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Feature 2</a>
                  <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Feature 3</a>
                </div>
              </div>
            </div>
            <Link to="/pricing" className="text-gray-600 hover:text-gray-900 text-sm">Pricing</Link>
            <a href="https://tally.so/r/31v7vg" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-gray-900 text-sm">Contact Us</a>
          </div>
        </div>

        <div className="flex items-center space-x-2 sm:space-x-4">
          <Link to="/auth" className="hidden md:inline-block text-gray-600 hover:text-gray-900 text-sm">Log in</Link>
          <button className="bg-indigo-600 text-white px-3 py-1 sm:px-4 sm:py-2 rounded-full text-xs sm:text-sm hover:bg-indigo-700 transition duration-300">
            Request a Demo →
          </button>
          <button
            className="md:hidden text-gray-600 hover:text-gray-900"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <Menu className="h-6 w-6" />
          </button>
        </div>
      </nav>

      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="md:hidden absolute top-full left-0 right-0 bg-white shadow-md">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <a href="#" className="block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50">How it Works</a>
            <Link to="/pricing" className="block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50">Pricing</Link>
            <Link to="/auth" className="block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50">Log in</Link>
            <a href="https://tally.so/r/31v7vg" target="_blank" rel="noopener noreferrer" className="block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50">Contact Us</a>
          </div>
        </div>
      )}
    </header>
  );
};

const plans = [
  {
    name: 'Starter Plan',
    monthlyPrice: 29,
    features: [
      '10 Keywords Tracking',
      '50 Auto-replies/month',
      '2 Campaigns',
      'Auto-replies',
      'Advanced Sentiment Analysis',
      'Real-time Notifications',
      '24/7 Email and Chat Support'
    ],
    color: 'bg-blue-500',
    idealFor: 'Small businesses or individuals who need a reliable, feature-rich solution for managing a single project.'
  },
  {
    name: 'Pro Plan',
    monthlyPrice: 79,
    features: [
      '100 Keywords Tracking',
      '250 Auto-replies/month',
      '5 Campaigns',
      'Auto-replies with AI-driven enhancements',
      'Advanced Sentiment Analysis with Historical Insights',
      'Real-time Notifications',
      '24/7 Priority Support (Email, Chat, Phone)'
    ],
    color: 'bg-purple-500',
    idealFor: 'Growing businesses or mid-sized teams that require more advanced tools and increased project capacity.'
  },
  {
    name: 'Business Plan',
    monthlyPrice: 199,
    features: [
      '500 Keywords Tracking',
      '1,500 Auto-replies/month',
      '100 Campaigns',
      'Advanced Sentiment Analysis',
      'Advanced Notifications',
      '24/7 Premium Support with Dedicated Account Manager',
      'Multi-Account Management'
    ],
    color: 'bg-indigo-600',
    popular: true,
    idealFor: 'Established businesses or enterprises needing extensive capabilities and top-tier support.'
  },
  {
    name: 'Agency Plan',
    monthlyPrice: 499,
    features: [
      '1000 Keywords Tracking',
      '5,000 Auto-replies/month',
      '1000 Campaigns',
      'AI-driven Enhancements',
      'Advanced Sentiment Analysis',
      'Multi-account Management',
      '24/7 VIP Support with Dedicated Team'
    ],
    color: 'bg-gray-800',
    idealFor: 'Marketing agencies and large enterprises managing multiple brands or clients with need for customization and scalability.'
  }
];

const PricingCard = ({ plan, isYearly, isActive }) => {
  const navigate = useNavigate();
  const price = isYearly ? plan.monthlyPrice * 10 : plan.monthlyPrice;
  const billingPeriod = isYearly ? '/year' : '/month';

  return (
    <div className={`${plan.color} text-white p-6 sm:p-8 rounded-lg w-full relative transition-all duration-300 ${
      isActive ? 'transform scale-105 shadow-2xl z-10 my-4' : 'scale-95 opacity-90'
    }`}>
      {plan.popular && (
        <div className="absolute top-0 right-0 bg-yellow-400 text-gray-900 text-xs font-bold px-2 py-1 rounded-bl-lg rounded-tr-lg">
          Most Popular
        </div>
      )}
      <h3 className="text-xl sm:text-2xl font-semibold mb-4">{plan.name}</h3>
      <p className="text-4xl sm:text-5xl font-bold mb-4">
        ${price}<span className="text-base sm:text-lg">{billingPeriod}</span>
      </p>
      {isYearly && (
        <p className="mb-2 text-sm text-yellow-300">Save ${plan.monthlyPrice * 2} annually</p>
      )}
      <p className="mb-6 sm:mb-8 text-xs sm:text-sm">{plan.idealFor}</p>
      <ul className="space-y-2 sm:space-y-4 mb-6 sm:mb-8 text-left text-sm sm:text-base">
        {plan.features.map((feature, index) => (
          <li key={index} className="flex items-center">
            <Check className="w-4 h-4 sm:w-5 sm:h-5 mr-2" />
            {feature}
          </li>
        ))}
      </ul>
      <button 
        className="bg-white text-gray-800 px-4 py-2 sm:px-6 sm:py-3 rounded-full text-sm sm:text-base font-semibold hover:bg-gray-100 transition duration-300 w-full"
        onClick={() => navigate('/auth?register')}
      >
        Get Started
      </button>
    </div>
  );
};

const Toggle = ({ isOn, onToggle }) => (
  <div
    onClick={onToggle}
    className={`w-14 h-7 flex items-center rounded-full p-1 cursor-pointer ${isOn ? 'bg-indigo-600' : 'bg-gray-300'
      }`}
  >
    <div
      className={`bg-white w-5 h-5 rounded-full shadow-md transform transition-transform duration-300 ease-in-out ${isOn ? 'translate-x-7' : ''
        }`}
    ></div>
  </div>
);

const Pricing = () => {
  const [isYearly, setIsYearly] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const visiblePlans = plans.slice(currentIndex, currentIndex + 3);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, plans.length - 3));
  };

  return (
    <section className="py-16 sm:py-24 bg-white">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-8">Choose Your Plan</h2>
        <div className="flex justify-center items-center mb-8">
          <span className={`mr-3 ${isYearly ? 'text-gray-500' : 'text-gray-900 font-semibold'}`}>Monthly</span>
          <Toggle isOn={isYearly} onToggle={() => setIsYearly(!isYearly)} />
          <span className={`ml-3 ${isYearly ? 'text-gray-900 font-semibold' : 'text-gray-500'}`}>Yearly</span>
        </div>
        <div className="relative max-w-6xl mx-auto">
          <div className="overflow-visible">
            <div className="flex flex-col md:flex-row md:space-x-4">
              {visiblePlans.map((plan, index) => (
                <div key={plan.name} className="w-full md:w-1/3 mb-8 md:mb-0">
                  <PricingCard 
                    plan={plan} 
                    isYearly={isYearly} 
                    isActive={plan.popular}
                  />
                </div>
              ))}
            </div>
          </div>
          {plans.length > 3 && (
            <>
              <button 
                onClick={handlePrev} 
                className="absolute top-1/2 -left-4 md:-left-8 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md z-10"
                disabled={currentIndex === 0}
              >
                <ChevronLeft className={`w-6 h-6 ${currentIndex === 0 ? 'text-gray-300' : 'text-gray-600'}`} />
              </button>
              <button 
                onClick={handleNext} 
                className="absolute top-1/2 -right-4 md:-right-8 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md z-10"
                disabled={currentIndex === plans.length - 3}
              >
                <ChevronRight className={`w-6 h-6 ${currentIndex === plans.length - 3 ? 'text-gray-300' : 'text-gray-600'}`} />
              </button>
            </>
          )}
        </div>
        <div className="mt-8 flex justify-center space-x-2 md:hidden">
          {plans.slice(0, plans.length - 2).map((_, index) => (
            <button
              key={index}
              className={`w-3 h-3 rounded-full ${
                index === currentIndex ? 'bg-indigo-600' : 'bg-gray-300'
              }`}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

const Footer = () => (
  <footer className="bg-gray-800 text-white py-8 sm:py-12">
    <div className="container mx-auto px-4">
      <div className="grid grid-cols-2 md:grid-cols-2 gap-8">
        <div>
          <h3 className="text-base sm:text-lg font-semibold mb-4">Product</h3>
          <ul className="space-y-2">
            <li><Link to="/" className="text-sm sm:text-base hover:text-gray-300">Home</Link></li>
            <li><Link to="/pricing" className="text-sm sm:text-base hover:text-gray-300">Pricing</Link></li>
            <li><Link to="/auth" className="text-sm sm:text-base hover:text-gray-300">Log in</Link></li>
          </ul>
        </div>
        <div>
          <h3 className="text-base sm:text-lg font-semibold mb-4">Company</h3>
          <ul className="space-y-2">
            <li><Link to="/" className="text-sm sm:text-base hover:text-gray-300">Home</Link></li>
            <li><Link to="/#comparison" className="text-sm sm:text-base hover:text-gray-300">Compare</Link></li>
            <li><Link to="/pricing" className="text-sm sm:text-base hover:text-gray-300">Pricing</Link></li>
            <li><Link to="/#faq" className="text-sm sm:text-base hover:text-gray-300">FAQ</Link></li>
            <li><a href="https://tally.so/r/31v7vg" target="_blank" rel="noopener noreferrer" className="text-sm sm:text-base hover:text-gray-300">Contact Us</a></li>
          </ul>
        </div>
      </div>
      <div className="mt-8 pt-8 border-t border-gray-700 text-center">
        <p className="text-sm sm:text-base">&copy; 2023 SocialSignalAI. All rights reserved.</p>
      </div>
    </div>
  </footer>
);

const PricingHero = () => (
  <section className="py-20 bg-gradient-to-b  to-white">
    <div className="container mx-auto px-4 text-center">
      <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6 text-gray-900">
        Choose the Right Plan for Your Growth
      </h1>
      <p className="text-xl text-gray-600 mb-8 max-w-3xl mx-auto">
        Whether you're just starting out or scaling up, we have a plan that fits your needs. Unlock the power of AI-driven growth and engagement.
      </p>
    </div>
  </section>
);

const ComparisonTable = () => (
  <section className="py-20 bg-white">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold mb-12 text-center">Feature Comparison</h2>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-100">
              <th className="p-4 text-left">Feature</th>
              {plans.map(plan => (
                <th key={plan.name} className="p-4 text-center">{plan.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="p-4 border-t">Keywords Tracking</td>
              {plans.map(plan => (
                <td key={plan.name} className="p-4 border-t text-center">
                  {plan.features.find(f => f.includes('Keywords Tracking')).split(' ')[0]}
                </td>
              ))}
            </tr>
            <tr>
              <td className="p-4 border-t">Auto-replies/month</td>
              {plans.map(plan => (
                <td key={plan.name} className="p-4 border-t text-center">
                  {plan.features.find(f => f.includes('Auto-replies/month')).split(' ')[0]}
                </td>
              ))}
            </tr>
            <tr>
              <td className="p-4 border-t">Campaigns/Projects</td>
              {plans.map(plan => (
                <td key={plan.name} className="p-4 border-t text-center">
                  {plan.features.find(f => f.includes('Campaign') || f.includes('Project'))?.split(' ')[0] || 'Unlimited'}
                </td>
              ))}
            </tr>
            <tr>
              <td className="p-4 border-t">Sentiment Analysis</td>
              {plans.map(plan => (
                <td key={plan.name} className="p-4 border-t text-center">
                  {plan.name === 'Trial' ? '-' : 
                   plan.features.find(f => f.includes('Sentiment Analysis')) ? 
                   (plan.features.find(f => f.includes('Sentiment Analysis')).includes('Advanced') ? 'Advanced' : 'Basic') : 
                   'Advanced'}
                </td>
              ))}
            </tr>
            <tr>
              <td className="p-4 border-t">Support</td>
              {plans.map(plan => (
                <td key={plan.name} className="p-4 border-t text-center">
                  {plan.features.find(f => f.includes('Support'))?.replace('Support', '').trim() || '-'}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
);

const TestimonialSection = () => {
  const testimonials = [
    { name: "Priya Sharma", company: "GreenLeaf Digital", quote: "SocialSignalAI has revolutionized how we handle our social media presence. The insights are invaluable!", plan: "Pro Plan" },
    { name: "Rajesh Patel", company: "Nexus Innovations", quote: "The sentiment analysis feature alone has saved us countless hours. It's a game-changer for our brand.", plan: "Business Plan" },
    { name: "Ananya Gupta", company: "Pixel Perfect Marketing", quote: "As a growing agency, the Starter Plan gives us everything we need to deliver great results for our clients.", plan: "Starter Plan" },
    { name: "Vikram Singh", company: "TechSprint Solutions", quote: "The keyword tracking feature has helped us stay ahead of trends in our industry.", plan: "Pro Plan" },
    { name: "Neha Kapoor", company: "Bluebird Enterprises", quote: "The auto-reply feature has significantly improved our response time to customer inquiries.", plan: "Business Plan" },
    { name: "Arjun Mehta", company: "Quantum Dynamics", quote: "SocialSignalAI's analytics have given us deep insights into our social media performance.", plan: "Agency Plan" },
    { name: "Sanya Reddy", company: "Crimson Technologies", quote: "The multi-account management feature is perfect for our agency needs.", plan: "Agency Plan" },
    { name: "Karthik Nair", company: "Evergreen Solutions", quote: "We've seen a notable increase in engagement since using SocialSignalAI's tools.", plan: "Pro Plan" },
    { name: "Meera Joshi", company: "Sapphire Digital", quote: "The campaign management tools have streamlined our workflow tremendously.", plan: "Business Plan" },
    { name: "Rahul Sharma", company: "Pinnacle Innovations", quote: "The trial period convinced us of the value SocialSignalAI brings to our operations.", plan: "Starter Plan" },
    { name: "Zara Malik", company: "Zenith Marketing", quote: "The customizable reports have impressed our clients and improved our presentations.", plan: "Agency Plan" },
    { name: "Aditya Patel", company: "Nebula Systems", quote: "SocialSignalAI's customer support team has been exceptional in addressing our needs.", plan: "Business Plan" },
    { name: "Nisha Verma", company: "Pulse Digital", quote: "The real-time notifications have helped us stay on top of important social media trends.", plan: "Pro Plan" },
    { name: "Rohan Kapoor", company: "Stellar Solutions", quote: "We've been able to scale our social media efforts effortlessly with SocialSignalAI.", plan: "Agency Plan" },
    { name: "Anjali Desai", company: "Prism Analytics", quote: "The AI-driven enhancements have significantly improved our content strategy.", plan: "Business Plan" },
    { name: "Vivek Malhotra", company: "Oasis Technologies", quote: "SocialSignalAI has become an indispensable tool in our digital marketing arsenal.", plan: "Pro Plan" },
    { name: "Pooja Singhania", company: "Cascade Innovations", quote: "The multilingual support has allowed us to expand our reach to new markets.", plan: "Business Plan" },
    { name: "Siddharth Rao", company: "Vertex Digital", quote: "The predictive models have given us a competitive edge in our campaigns.", plan: "Agency Plan" },
    { name: "Tanya Khanna", company: "Mosaic Marketing", quote: "We've seen a significant ROI since implementing SocialSignalAI in our workflow.", plan: "Pro Plan" },
    { name: "Aryan Choudhury", company: "Nova Dynamics", quote: "The comprehensive features of SocialSignalAI have exceeded our expectations.", plan: "Business Plan" },
  ];

  const testimonialRef = useRef(null);

  useEffect(() => {
    const scrollTestimonials = () => {
      if (testimonialRef.current) {
        if (testimonialRef.current.scrollLeft >= testimonialRef.current.scrollWidth / 2) {
          testimonialRef.current.scrollLeft = 0;
        } else {
          testimonialRef.current.scrollLeft += 1;
        }
      }
    };

    const intervalId = setInterval(scrollTestimonials, 30);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-12 text-center">What Our Customers Say</h2>
        <div 
          ref={testimonialRef}
          className="overflow-hidden whitespace-nowrap"
          style={{ maskImage: 'linear-gradient(to right, transparent, black 10%, black 90%, transparent)' }}
        >
          <div className="inline-block animate-marquee">
            {testimonials.concat(testimonials).map((testimonial, index) => (
              <div key={index} className="inline-block w-80 mx-4 align-top whitespace-normal mb-8">
                <div className="bg-white shadow-lg rounded-lg overflow-hidden h-full">
                  <div className="px-6 py-8">
                    <div className="text-xl font-semibold text-indigo-600 mb-2">{testimonial.plan}</div>
                    <p className="text-gray-700 mb-4">"{testimonial.quote}"</p>
                    <div className="font-medium text-gray-900">{testimonial.name}</div>
                    <div className="text-gray-600">{testimonial.company}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 py-4">
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-lg font-semibold">{question}</span>
        <ChevronDown className={`w-5 h-5 transform transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </button>
      {isOpen && <p className="mt-2 text-gray-600">{answer}</p>}
    </div>
  );
};

const PricingFAQ = () => (
  <section className="py-20 bg-gray-50">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold mb-12 text-center">Frequently Asked Questions</h2>
      <div className="max-w-3xl mx-auto">
        <FAQItem
          question="Can I change my plan later?"
          answer="Yes, you can upgrade or downgrade your plan at any time. Changes will be reflected in your next billing cycle."
        />
        <FAQItem
          question="Is there a long-term contract?"
          answer="No, all our plans are billed monthly or annually with no long-term commitment. You can cancel at any time."
        />
        <FAQItem
          question="What happens if I exceed my plan limits?"
          answer="If you exceed your plan limits, we'll notify you and provide options to upgrade your plan or purchase additional capacity."
        />
      </div>
    </div>
  </section>
);

const CTASection = () => {
  const navigate = useNavigate();

  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-3xl font-bold mb-6 text-gray-900">Ready to Supercharge Your Growth?</h2>
        <p className="text-xl mb-8 text-gray-700">Choose a plan that fits your needs and start growing today.</p>
        <button
          onClick={() => navigate('/signup')}
          className="bg-indigo-600 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-indigo-700 transition duration-300"
        >
          Get Started Now
        </button>
      </div>
    </section>
  );
};

const PricingPage = () => {
  return (
    <div className="font-sans flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow pt-16 sm:pt-20">
        <PricingHero />
        <Pricing />
        <ComparisonTable />
        <TestimonialSection />
        <PricingFAQ />
        <CTASection />
      </main>
      <Footer />
    </div>
  );
};

export default PricingPage;