import React, { useState, useEffect } from 'react';
import { ChevronRight, Mail, Lock, User, Building, Key, X } from 'lucide-react';
import { createClient } from '@supabase/supabase-js';
import useApi from '../hooks/api';
import { useNavigate, useLocation } from 'react-router-dom';
import { storeUserData } from '../utils/userData';

// Initialize Supabase client
const supabaseUrl = 'https://kqvgrqahkjzigcqsmvkx.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImtxdmdycWFoa2p6aWdjcXNtdmt4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjIwNDc2ODEsImV4cCI6MjAzNzYyMzY4MX0.dJVotFjQ4R72tQXGuxkbYMy7fZ5eAMNSREIsnkyFucg';
const supabase = createClient(supabaseUrl, supabaseAnonKey);

const AuthForm = ({ onLogin }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [organizationId, setOrganizationId] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [session, setSession] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState('');
  const [user, setUser] = useState({});
  const api = useApi();

  useEffect(() => {
    // Check for 'register' query parameter
    const params = new URLSearchParams(location.search);
    if (params.get('register') !== null) {
      setIsLogin(false);
    }

    const inviteOrgId = localStorage.getItem('inviteOrgId');
    if (inviteOrgId) {
      setOrganizationId(inviteOrgId);
    }

    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      console.log(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      console.log(session);
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    if (isLogin === false) {
      try {
        const registrationData = {
          email,
          password,
          confirm: password,
          name,
          ...(organizationId ? { organizationId } : { organizationName })
        };

        const message = await api.post('/register', registrationData);
        if (message.message === "USER_CREATED") {
          setUser(message.data?.user);
          storeUserData({...message.data?.user, name}); // Store the name explicitly
          setShowConfirmation(true);
          setError("Please enter the confirmation code sent to your email.");
          setSuccess('Registration successful! Please check your email for verification.');
        }
      } catch (error) {
        if (error?.error === "USER_ALREADY_EXISTS") {
          setError("User already exists!");
        } else {
          setError(error?.error || "An error occurred during registration.");
        }
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const login = await api.post('/login', {
          email,
          password
        });
        setUser(login.data?.user);
        storeUserData({...login.data?.user, name: login.data?.user.name || email.split('@')[0]}); // Use email as fallback for name
        setSuccess('Login successful!');
        onLogin(); // Call the onLogin function passed from App.js
        localStorage.setItem('orgIdSelected', login.data?.user.organizationId)


        const isPremium = await api.post('/is-user-premium', {
          uid: login.data?.user?.uid
        });

        if (isPremium.message === 'UPGRADE_USER') {
          navigate('/plan');
        } else {
          navigate('/dashboard');
        }
      } catch (error) {
        if (error.error === "USER_NOT_ACTIVATED") {
          setUser(error.data?.user);
          storeUserData(error.data?.user);
          setShowConfirmation(true);
          setError("Please enter the confirmation code sent to your email.");
        } else if (error.error === "USER_NOT_FOUND") {
          setError("User not found!");
        } else {
          setError(error.error || "An error occurred during login.");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleConfirmation = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const { uid, token } = user;
      const confirmUser = await api.post('/confirmUser', {
        uid,
        token,
        confirmationCode
      });
      setUser(confirmUser.data?.user);
      storeUserData(confirmUser.data?.user);
      localStorage.setItem('orgIdSelected', confirmUser.data?.user.organizationId)
      setSuccess('Account activated successfully! Redirecting to plan selection...');
      setShowConfirmation(false);
      
      // Redirect to plan page without checking premium status
      navigate('/plan');
    } catch (error) {
      setError(error.error || 'Failed to confirm account. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLogin = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
      });
      navigate('/dashboard');
      if (error) throw error;
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDenyOrganization = () => {
    localStorage.removeItem('inviteOrgId');
    setOrganizationId('');
  };

  if (session) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Welcome, {session.user.user_metadata.name || session.user.email}!
          </h2>
          <button
            onClick={() => supabase.auth.signOut()}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Sign Out
          </button>
        </div>
      </div>
    );
  }

  if (showConfirmation) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Confirm Your Account
            </h2>
          </div>
          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
              <span className="block sm:inline">{error}</span>
            </div>
          )}
          {success && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
              <span className="block sm:inline">{success}</span>
            </div>
          )}
          <form className="mt-8 space-y-6" onSubmit={handleConfirmation}>
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="confirmation-code" className="sr-only">
                  Confirmation Code
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Key className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                  <input
                    id="confirmation-code"
                    name="confirmation-code"
                    type="text"
                    required
                    className="appearance-none rounded-md relative block w-full px-3 py-2 pl-10 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Confirmation Code"
                    value={confirmationCode}
                    onChange={(e) => setConfirmationCode(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                disabled={loading}
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <ChevronRight className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                </span>
                Confirm Account
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white p-10 rounded-xl shadow-2xl transform transition-all duration-300 hover:scale-105">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {isLogin ? 'Welcome Back!' : 'Join Us Today'}
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            {isLogin ? 'Sign in to your account' : 'Create a new account'}
          </p>
        </div>
        {error && (
          <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-md" role="alert">
            <p className="font-bold">Error</p>
            <p>{error}</p>
          </div>
        )}
        {success && (
          <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 rounded-md" role="alert">
            <p className="font-bold">Success</p>
            <p>{success}</p>
          </div>
        )}
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="space-y-4">
            {!isLogin && (
              <>
                <div>
                  <label htmlFor="name" className="sr-only">
                    Name
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <User className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      autoComplete="name"
                      required
                      className="appearance-none rounded-md relative block w-full px-3 py-2 pl-10 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm transition-all duration-300"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                {organizationId ? (
                  <div>
                    <label htmlFor="organizationId" className="sr-only">
                      Organization ID
                    </label>
                    <div className="flex items-center">
                      <div className="relative flex-grow">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <Building className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                        <input
                          id="organizationId"
                          name="organizationId"
                          type="text"
                          autoComplete="off"
                          required
                          disabled
                          className="appearance-none rounded-md relative block w-full px-3 py-2 pl-10 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm bg-gray-100"
                          value={`Joining: ${organizationId}`}
                        />
                      </div>
                      <button
                        type="button"
                        onClick={handleDenyOrganization}
                        className="ml-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                      >
                        <X className="h-4 w-4 mr-1" />
                        Deny
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <label htmlFor="organizationName" className="sr-only">
                      Organization Name
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <Building className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                      <input
                        id="organizationName"
                        name="organizationName"
                        type="text"
                        autoComplete="organization"
                        required
                        className="appearance-none rounded-md relative block w-full px-3 py-2 pl-10 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm transition-all duration-300"
                        placeholder="Organization Name"
                        value={organizationName}
                        onChange={(e) => setOrganizationName(e.target.value)}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-md relative block w-full px-3 py-2 pl-10 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm transition-all duration-300"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete={isLogin ? "current-password" : "new-password"}
                  required
                  className="appearance-none rounded-md relative block w-full px-3 py-2 pl-10 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm transition-all duration-300"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                Remember me
              </label>
            </div>

            {isLogin && (
              <div className="text-sm">
                <a
                  href="/requestResetPassword"
                  className="font-medium text-indigo-600 hover:text-indigo-500 transition-all duration-300"
                >
                  Forgot your password?
                </a>
              </div>
            )}
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-300 transform hover:scale-105"
              disabled={loading}
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <ChevronRight className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
              </span>
              {isLogin ? 'Sign in' : 'Register'}
            </button>
          </div>
        </form>

        {/* Remove the "Or continue with" section and Google sign-in button */}

        <div className="mt-6 text-center">
          <button
            type="button"
            onClick={() => setIsLogin(!isLogin)}
            className="font-medium text-indigo-600 hover:text-indigo-500 transition-all duration-300"
          >
            {isLogin ? 'Need an account? Register' : 'Already have an account? Sign in'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AuthForm;