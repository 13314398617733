import React, { useState, useEffect } from 'react';
import { MessageSquare, ExternalLink, Clock, User, Activity, UserPlus, ThumbsUp, AlertCircle, ArrowUpRight, MessageCircle, Zap, Filter, Check, X, AlertTriangle, RefreshCw, Send, ArrowDown, ArrowUp, Trash2 } from 'lucide-react';
import { FaReddit, FaTwitter, FaHackerNews } from 'react-icons/fa';
import useApi from '../../hooks/api';
import { getValue } from '../../utils/userData';
import Loader from '../common/Loader';


const EngagementTracker = ({ organizationId }) => {
  const [activities, setActivities] = useState([]);
  const [pendingActivities, setPendingActivities] = useState([]);
  const [leads, setLeads] = useState([]);
  const [activeTab, setActiveTab] = useState('activities');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filter, setFilter] = useState('all');
  const [localError, setLocalError] = useState(null);
  const [status, setStatus] = useState(null);
  const [sortOrder, setSortOrder] = useState('desc'); // 'desc' for latest first, 'asc' for oldest first
  const [approvingActivity, setApprovingActivity] = useState(null);
  const { post, loading, error } = useApi();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetchData();
  }, [post, organizationId, activeTab, currentPage]);

  const fetchData = async () => {
    try {
      const uid = getValue('uid');
      if (activeTab === 'activities' || activeTab === 'pending') {
        const response = await post('/getActivity', { uid, organizationId });
        console.log('API response:', response); // Add this line for debugging
        if (response.message === "NO_ACTIVITY_FOUND") {
          setLocalError({ message: "NO_ACTIVITY_FOUND" });
          setActivities([]);
          setPendingActivities([]);
        } else {
          const allActivities = response || [];
          setActivities(sortActivities(allActivities.filter(a => a.status !== 'pending')));
          setPendingActivities(sortActivities(allActivities.filter(a => a.status === 'pending')));
          setLocalError(null);
        }
      } else if (activeTab === 'leads') {
        const response = await post('/getLeads', { uid, organizationId, page: currentPage });
        if (response.message === "NO_ACTIVITY_FOUND") {
          setLocalError({ message: "NO_ACTIVITY_FOUND" });
          setLeads([]);
          setTotalPages(1);
        } else {
          setLeads(response.leads);
          setTotalPages(response.totalPages);
          setLocalError(null);
        }
      }
    } catch (err) {
      console.error(`Error retrieving ${activeTab}:`, err);
      setLocalError(err);
    }
  };

  const sortActivities = (activitiesToSort) => {
    return activitiesToSort
      .filter(activity => activity && (activity._id || activity.id)) // Check for both _id and id
      .sort((a, b) => {
        const dateA = new Date(a.created);
        const dateB = new Date(b.created);
        return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
      });
  };

  const toggleSortOrder = () => {
    setSortOrder(prevOrder => prevOrder === 'desc' ? 'asc' : 'desc');
  };

  const EmptyState = ({ type }) => (
    <div className="text-center py-16">
      <AlertCircle className="w-20 h-20 text-gray-400 mx-auto mb-4" />
      <h3 className="text-2xl font-bold text-gray-700 mb-2">No {type} found</h3>
      <p className="text-gray-500">Your {type.toLowerCase()} log will be populated as you interact across platforms. This process may take some time, so please check back later.</p>
    </div>
  );

  const ActivityItem = ({ activity }) => {
    let Icon, iconColor, platformName, actionIcon;

    switch (activity.platform) {
      case 'reddit':
        Icon = FaReddit;
        iconColor = 'text-orange-500';
        platformName = 'Reddit';
        break;
      case 'twitter':
        Icon = FaTwitter;
        iconColor = 'text-blue-400';
        platformName = 'Twitter';
        break;
      case 'hackernews':
        Icon = FaHackerNews;
        iconColor = 'text-orange-600';
        platformName = 'Hacker News';
        break;
      default:
        Icon = MessageSquare;
        iconColor = 'text-gray-500';
        platformName = 'Unknown';
    }

    switch (activity.activityType) {
      case 'post':
        actionIcon = <ArrowUpRight className="w-4 h-4 text-green-500" />;
        break;
      case 'comment':
      case 'tweet':
        actionIcon = <MessageCircle className="w-4 h-4 text-blue-500" />;
        break;
      default:
        actionIcon = <Activity className="w-4 h-4 text-gray-500" />;
    }

    return (
      <div className="bg-white rounded-lg shadow-md border border-gray-200 p-4 md:p-6 hover:shadow-lg transition-all duration-200 transform hover:-translate-y-1">
        <div className="flex items-start space-x-4">
          <div className={`${iconColor} flex-shrink-0 p-3 bg-gray-100 rounded-full`}>
            <Icon className="w-8 h-8" />
          </div>
          <div className="flex-grow">
            <div className="flex justify-between items-center mb-2">
              <span className="text-sm font-semibold text-gray-600 bg-gray-100 px-3 py-1 rounded-full">
                {platformName}
              </span>
              <span className="text-xs text-gray-500 flex items-center">
                <Clock className="w-4 h-4 mr-1" />
                {new Date(activity.created).toLocaleString()}
              </span>
            </div>
            <div className="flex items-center mb-3">
              {actionIcon}
              <span className="ml-2 text-sm font-medium text-gray-700 capitalize">
                {activity.activityType}
              </span>
            </div>
            {activity.activityType === 'post' && (
              <>
                <h3 className="text-lg font-semibold mb-2 text-gray-800">{activity.postTitle || 'Untitled Post'}</h3>
                <p className="text-sm text-gray-700 mb-3 line-clamp-3">{activity.postText}</p>
              </>
            )}
            {activity.activityType === 'comment' && (
              <p className="text-sm text-gray-700 mb-3 line-clamp-3">{activity.commentText}</p>
            )}
            {activity.platform === 'twitter' && activity.tweetText && (
              <div className="mb-3 p-3 bg-blue-50 rounded-lg">
                <p className="text-sm text-gray-700">{activity.tweetText}</p>
              </div>
            )}
            <a
              href={activity.link}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center text-sm text-blue-600 hover:text-blue-800 transition-colors duration-200"
            >
              View on {platformName}
              <ExternalLink className="w-4 h-4 ml-1" />
            </a>
          </div>
        </div>
      </div>
    );
  };

  const LeadItem = ({ lead }) => {
    const getInteractionIcon = (type) => {
      switch (type) {
        case 'upvoted_reply':
          return <ThumbsUp className="w-4 h-4 text-green-500" />;
        case 'positive_comment':
          return <MessageSquare className="w-4 h-4 text-blue-500" />;
        default:
          return <AlertCircle className="w-4 h-4 text-yellow-500" />;
      }
    };

    const getPlatformIcon = (campaignId) => {
      // Assuming campaign IDs have a prefix indicating the platform
      if (campaignId.startsWith('red')) return <FaReddit className="w-5 h-5 text-orange-500" />;
      if (campaignId.startsWith('twi')) return <FaTwitter className="w-5 h-5 text-blue-400" />;
      if (campaignId.startsWith('hn')) return <FaHackerNews className="w-5 h-5 text-orange-600" />;
      return <User className="w-5 h-5 text-gray-500" />;
    };

    return (
      <div className="bg-white rounded-lg shadow-md border border-gray-200 p-4 md:p-6 hover:shadow-lg transition-shadow duration-200">
        <div className="flex items-start space-x-4">
          <div className="flex-shrink-0">
            {getPlatformIcon(lead.campaign_id)}
          </div>
          <div className="flex-grow">
            <div className="flex justify-between items-center mb-2">
              <span className="text-lg font-semibold text-gray-800">
                {lead.username}
              </span>
            </div>
            <div className="flex items-center mb-2">
              <span className="text-sm font-medium text-gray-600 mr-2">Interaction:</span>
              <span className="flex items-center text-sm text-gray-700">
                {getInteractionIcon(lead.interaction_type)}
                <span className="ml-1">{lead.interaction_type.replace('_', ' ')}</span>
              </span>
            </div>
            <div className="flex items-center mb-4">
              <span className="text-sm font-medium text-gray-600 mr-2">Campaign ID:</span>
              <span className="text-sm text-gray-700">{lead.campaign_id}</span>
            </div>
            <a
              href={lead.profile_link}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200"
            >
              View Profile
              <ExternalLink className="w-4 h-4 ml-2" />
            </a>
          </div>
        </div>
      </div>
    );
  };

  const Pagination = () => (
    <div className="flex flex-col md:flex-row justify-center items-center space-y-2 md:space-y-0 md:space-x-2 py-8 bg-gray-50">
      <button
        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
        disabled={currentPage === 1}
        className="px-4 py-2 bg-blue-500 text-white rounded-md disabled:bg-gray-300 transition-colors duration-200"
      >
        Previous
      </button>
      <span className="px-4 py-2 bg-gray-200 border-t border-b border-gray-300">
        Page {currentPage} of {totalPages}
      </span>
      <button
        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
        disabled={currentPage === totalPages}
        className="px-4 py-2 bg-blue-500 text-white rounded-md disabled:bg-gray-300 transition-colors duration-200"
      >
        Next
      </button>
    </div>
  );

  const TabButton = ({ label, icon: Icon, isActive, onClick, count }) => (
    <button
      onClick={onClick}
      className={`flex items-center px-4 py-2 rounded-lg transition-all duration-300 ${
        isActive
          ? 'bg-gradient-to-r from-blue-500 to-indigo-600 text-white shadow-lg transform scale-105'
          : 'bg-white text-gray-600 hover:bg-gray-100 hover:shadow-md'
      }`}
    >
      <Icon className={`w-5 h-5 mr-2 ${isActive ? 'text-white' : 'text-blue-500'}`} />
      <span className="font-semibold">{label}</span>
      {count > 0 && (
        <span className={`ml-2 px-2 py-1 rounded-full text-xs ${isActive ? 'bg-white text-blue-500' : 'bg-blue-500 text-white'}`}>
          {count}
        </span>
      )}
    </button>
  );

  const filterOptions = activeTab === 'activities'
    ? ['all', 'reddit', 'twitter', 'hackernews']
    : ['all', 'upvoted_reply', 'positive_comment', 'other'];

  const filteredData = activeTab === 'activities'
    ? activities.filter(activity => filter === 'all' || activity.platform === filter)
    : leads.filter(lead => filter === 'all' || lead.interaction_type === filter);

  const FilterDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <div className="relative inline-block text-left">
        <div>
          <button
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          >
            <Filter className="mr-2 h-5 w-5" aria-hidden="true" />
            Filter: {filter.charAt(0).toUpperCase() + filter.slice(1)}
          </button>
        </div>
        {isOpen && (
          <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-10">
            <div className="py-1">
              {filterOptions.map((option) => (
                <button
                  key={option}
                  onClick={() => {
                    setFilter(option);
                    setIsOpen(false);
                  }}
                  className={`${
                    filter === option ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                  } block px-4 py-2 text-sm w-full text-left hover:bg-gray-100`}
                >
                  {option.charAt(0).toUpperCase() + option.slice(1)}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const approvePendingActivity = async (activityId, platform) => {
    setApprovingActivity(activityId);
    try {
      const response = await post('/approvePendingActivity', { activityId, platform });
      if (response.message.includes('successfully')) {
        setStatus({ 
          type: 'success', 
          message: response.message,
          contentUrl: response.commentUrl || response.tweetUrl,
          contentText: response.commentText || response.tweetText
        });
        // Remove the approved activity from the pendingActivities state
        setPendingActivities(prevActivities => prevActivities.filter(activity => activity.id !== activityId));
        // Add the approved activity to the activities state
        setActivities(prevActivities => [response.approvedActivity, ...prevActivities]);
      } else {
        throw new Error('Failed to approve activity');
      }
    } catch (err) {
      console.error('Error approving activity:', err);
      setStatus({ type: 'error', message: 'Failed to approve activity. Please try again.' });
    } finally {
      setApprovingActivity(null);
    }
  };

  const refreshPendingActivity = async (activityId, platform) => {
    try {
      const response = await post('/refresh-pending-activity', { activityId, platform });
      if (response.message === 'Activity content refreshed successfully') {
        setStatus({ type: 'success', message: 'Content refreshed successfully' });
        fetchData(); // Refresh the activity list
      } else {
        throw new Error('Failed to refresh activity content');
      }
    } catch (err) {
      console.error('Error refreshing activity content:', err);
      setStatus({ type: 'error', message: 'Failed to refresh activity content. Please try again.' });
    }
  };

  const deletePendingActivity = async (activityId, platform) => {
    try {
      const response = await post('/delete-pending-activity', { activityId, platform });
      if (response.message === 'Activity deleted successfully') {
        setPendingActivities(prevActivities => prevActivities.filter(activity => activity.id !== activityId));
        setStatus({ type: 'success', message: 'Activity deleted successfully' });
      } else {
        throw new Error('Failed to delete activity');
      }
    } catch (err) {
      console.error('Error deleting activity:', err);
      setStatus({ type: 'error', message: 'Failed to delete activity. Please try again.' });
    }
  };

  const PendingActivityItem = ({ activity, onDelete, onRefresh, onApprove }) => (
    <div className="rounded-lg shadow-md border border-gray-200 p-6 hover:shadow-lg transition-all duration-200">
      <div className="flex justify-between items-start">
        <div>
          <span className="text-sm font-semibold text-blue-600 bg-blue-100 px-3 py-1 rounded-full flex items-center">
            <AlertTriangle className="w-4 h-4 mr-1" />
            Pending {activity.activityType} on {activity.platform}
          </span>
          <h3 className="mt-2 text-lg font-semibold text-gray-800">
            {activity.platform === 'twitter' ? 'Tweet' : activity.postDetails.title}
          </h3>
          <p className="mt-1 text-sm text-gray-600 line-clamp-2">
            {activity.platform === 'twitter' ? activity.generatedContent : activity.postDetails.selftext}
          </p>
        </div>
        <div className="flex space-x-2">
          <button
            onClick={() => onRefresh(activity.id, activity.platform)}
            className="text-blue-500 hover:text-blue-700 bg-blue-100 p-2 rounded-full transition-colors duration-200"
            title="Refresh Content"
          >
            <RefreshCw className="w-5 h-5" />
          </button>
          <button
            onClick={() => onApprove(activity.id, activity.platform)}
            className="text-green-500 hover:text-green-700 bg-green-100 p-2 rounded-full transition-colors duration-200"
            title="Approve and Post"
          >
            <Send className="w-5 h-5" />
          </button>
          <button
            onClick={() => onDelete(activity.id, activity.platform)}
            className="text-red-500 hover:text-red-700 bg-red-100 p-2 rounded-full transition-colors duration-200"
            title="Delete"
          >
            <Trash2 className="w-5 h-5" />
          </button>
        </div>
      </div>
      <div className="mt-4">
        <h4 className="text-md font-semibold text-gray-700">Generated Content:</h4>
        <p className="mt-1 text-sm text-gray-600">{activity.generatedContent || 'No content generated yet.'}</p>
      </div>
      <div className="mt-4 flex items-center text-sm text-gray-500">
        <Clock className="w-4 h-4 mr-1" />
        <span>{new Date(activity.created).toLocaleString()}</span>
      </div>
    </div>
  );

  const SortButton = () => (
    <button
      onClick={toggleSortOrder}
      className="flex items-center px-3 py-2 bg-gray-100 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-200 transition-colors duration-200"
    >
      Sort by Date
      {sortOrder === 'desc' ? (
        <ArrowDown className="w-4 h-4 ml-2" />
      ) : (
        <ArrowUp className="w-4 h-4 ml-2" />
      )}
    </button>
  );

  return (
    <div className="bg-white rounded-2xl shadow-xl overflow-hidden p-4 md:p-6">
      <div className="p-6 border-b">
        <h2 className="text-3xl font-bold text-gray-800">Engagement Tracker</h2>
      </div>

      <div className="p-6">
        <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-4 mb-8">
          <div className="bg-gray-100 p-2 rounded-xl flex flex-wrap space-y-2 md:space-y-0 md:space-x-2">
            <TabButton
              label="Activities"
              icon={Activity}
              isActive={activeTab === 'activities'}
              onClick={() => setActiveTab('activities')}
            />
            <TabButton
              label="Pending"
              icon={AlertTriangle}
              isActive={activeTab === 'pending'}
              onClick={() => setActiveTab('pending')}
              count={pendingActivities.length}
            />
            <TabButton
              label="Leads"
              icon={UserPlus}
              isActive={activeTab === 'leads'}
              onClick={() => setActiveTab('leads')}
            />
          </div>
          <div className="flex space-x-2">
            <SortButton />
            <FilterDropdown />
          </div>
        </div>

        {loading ? (
          <Loader />
        ) : localError || error ? (
          (localError && localError.message === "NO_ACTIVITY_FOUND") || (error && error.message === "NO_ACTIVITY_FOUND") ? (
            <EmptyState type={activeTab === 'activities' ? 'Activities' : activeTab === 'pending' ? 'Pending Activities' : 'Leads'} />
          ) : (
            <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded" role="alert">
              <p className="font-bold">Error</p>
              <p>An error occurred while loading {activeTab}: {(localError || error).message}</p>
            </div>
          )
        ) : (
          <>
            {activeTab === 'activities' && (
              <div className="space-y-4">
                {sortActivities(activities).map((activity) => (
                  activity && (activity._id || activity.id) ? (
                    <ActivityItem key={activity._id || activity.id} activity={activity} />
                  ) : null
                ))}
              </div>
            )}

            {activeTab === 'pending' && (
              <div className="space-y-4">
                {sortActivities(pendingActivities).map((activity) => (
                  activity && (activity._id || activity.id) ? (
                    <PendingActivityItem 
                      key={activity._id || activity.id} 
                      activity={activity} 
                      onDelete={deletePendingActivity}
                      onRefresh={refreshPendingActivity}
                      onApprove={approvePendingActivity}
                    />
                  ) : null
                ))}
              </div>
            )}

            {activeTab === 'leads' && (
              <>
                <div className="space-y-4">
                  {leads.map((lead) => (
                    lead && lead._id ? (
                      <LeadItem key={lead._id} lead={lead} />
                    ) : null
                  ))}
                </div>
                <Pagination />
              </>
            )}
          </>
        )}

        {status && (
          <div className={`mt-4 p-4 rounded-md ${status.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
            <p>{status.message}</p>
            {status.contentUrl && (
              <p className="mt-2">
                <a href={status.contentUrl} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                  View Content on {status.contentUrl.includes('reddit.com') ? 'Reddit' : 'Twitter'}
                </a>
              </p>
            )}
            {status.contentText && (
              <p className="mt-2">
                <strong>Posted Content:</strong> {status.contentText}
              </p>
            )}
            <button onClick={() => setStatus(null)} className="float-right font-bold">&times;</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EngagementTracker;