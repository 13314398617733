import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight, Zap, Users, BarChart2, Bell, MessageCircle, Search, TrendingUp, ShieldCheck, Globe, Target } from 'lucide-react';
import { motion } from 'framer-motion';

// Import images
import keywordsImage from './images/keywords.png';
import engagementTrackerImage from './images/engagementtracker.png';
import sentimentAnalysisImage from './images/senitimentanalysis.png';
import alertImage from './images/alert.png';
import settingsImage from './images/settings.png';

const GradientLogo = () => (
  <Link to="/">
    <h1 className="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-indigo-600">
      SocialSignalAI
    </h1>
  </Link>
);

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  return (
    <header className="bg-white py-4 border-b border-gray-200">
      <nav className="container mx-auto px-4 flex items-center justify-between">
        <GradientLogo />
        <div className="hidden md:flex space-x-6">
          <Link to="/" className="text-gray-600 hover:text-gray-900">Overview</Link>
          <Link to="/features" className="text-indigo-600 font-medium">Features</Link>
          <Link to="/pricing" className="text-gray-600 hover:text-gray-900">Pricing</Link>
          <Link to="/whatsnew" className="text-gray-600 hover:text-gray-900">What's new</Link>
        </div>
        <button
          className="md:hidden text-gray-600 hover:text-gray-900"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>
      </nav>
      {isMenuOpen && (
        <div className="md:hidden bg-white py-2">
          <Link to="/" className="block px-4 py-2 text-gray-600 hover:bg-gray-100">Overview</Link>
          <Link to="/features" className="block px-4 py-2 text-indigo-600 font-medium">Features</Link>
          <Link to="/pricing" className="block px-4 py-2 text-gray-600 hover:bg-gray-100">Pricing</Link>
          <Link to="/whatsnew" className="block px-4 py-2 text-gray-600 hover:bg-gray-100">What's new</Link>
        </div>
      )}
    </header>
  );
};

const Hero = () => (
  <section className="py-20 bg-gradient-to-br from-indigo-50 via-white to-blue-50 overflow-hidden">
    <div className="container mx-auto px-4">
      <div className="flex flex-col lg:flex-row items-center">
        <motion.div 
          className="lg:w-1/2 text-center lg:text-left mb-12 lg:mb-0"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-4xl lg:text-5xl font-bold mb-6 leading-tight">
            Turn Social Chatter into <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-indigo-600">Actionable Insights</span>
          </h1>
          <p className="text-lg lg:text-xl text-gray-600 mb-8 max-w-lg mx-auto lg:mx-0">
            Harness the power of AI to transform social media conversations into growth opportunities for your business.
          </p>
          <motion.button 
            className="bg-gradient-to-r from-blue-600 to-indigo-600 text-white px-8 py-4 rounded-full text-lg font-semibold hover:from-blue-700 hover:to-indigo-700 transition duration-300 shadow-lg"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Sign Up Now <ChevronRight className="inline-block ml-2" />
          </motion.button>
          <p className="mt-4 text-sm text-gray-500">Cancel anytime • Premium support</p>
        </motion.div>
        <motion.div 
          className="lg:w-1/2 relative"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <div className="relative w-full h-[32rem] sm:h-[36rem] md:h-[40rem] lg:h-96">
            <motion.div 
              className="absolute top-0 left-0 p-4 sm:p-6 rounded-lg shadow-xl bg-white w-36 sm:w-auto"
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              <Zap className="text-yellow-500 mb-2" size={20} />
              <h3 className="font-semibold mb-1 text-sm sm:text-base">AI-Powered Insights</h3>
              <p className="text-xs sm:text-sm text-gray-600">Unlock valuable social data</p>
            </motion.div>
            <motion.div 
              className="absolute top-1/4 lg:top-1/3 right-0 p-4 sm:p-6 rounded-lg shadow-xl bg-white w-36 sm:w-auto"
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.7 }}
            >
              <Users className="text-green-500 mb-2" size={20} />
              <h3 className="font-semibold mb-1 text-sm sm:text-base">Audience Engagement</h3>
              <p className="text-xs sm:text-sm text-gray-600">Connect with your followers</p>
            </motion.div>
            <motion.div 
              className="absolute bottom-1/4 lg:bottom-0 left-0 lg:left-1/4 p-4 sm:p-6 rounded-lg shadow-xl bg-white w-36 sm:w-auto"
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.9 }}
            >
              <BarChart2 className="text-blue-500 mb-2" size={20} />
              <h3 className="font-semibold mb-1 text-sm sm:text-base">Advanced Analytics</h3>
              <p className="text-xs sm:text-sm text-gray-600">Data-driven decision making</p>
            </motion.div>
            <motion.div 
              className="absolute bottom-0 lg:bottom-1/3 right-0 lg:right-1/4 p-4 sm:p-6 rounded-lg shadow-xl bg-white w-36 sm:w-auto"
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 1.1 }}
            >
              <Bell className="text-purple-500 mb-2" size={20} />
              <h3 className="font-semibold mb-1 text-sm sm:text-base">Real-time Alerts</h3>
              <p className="text-xs sm:text-sm text-gray-600">Stay ahead of the conversation</p>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  </section>
);

const FeatureSection = ({ title, description, image, reverse }) => (
  <section className="py-16">
    <div className={`container mx-auto px-4 flex flex-col ${reverse ? 'lg:flex-row-reverse' : 'lg:flex-row'} items-center`}>
      <div className="lg:w-1/2 mb-8 lg:mb-0">
        <h2 className="text-2xl lg:text-3xl font-bold mb-4">{title}</h2>
        <p className="text-base lg:text-lg text-gray-600">{description}</p>
      </div>
      <div className="lg:w-1/2">
        <img src={image} alt={title} className="w-full h-auto object-contain" />
      </div>
    </div>
  </section>
);

const Footer = () => (
  <footer className="bg-gray-800 text-white py-8 sm:py-12">
    <div className="container mx-auto px-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <h3 className="text-base sm:text-lg font-semibold mb-4">Company</h3>
          <ul className="space-y-2">
            <li><Link to="/" className="text-sm sm:text-base hover:text-gray-300">Home</Link></li>
            <li><Link to="/features" className="text-sm sm:text-base hover:text-gray-300">Features</Link></li>
            <li><Link to="/pricing" className="text-sm sm:text-base hover:text-gray-300">Pricing</Link></li>
            <li><a href="#faq" className="text-sm sm:text-base hover:text-gray-300">FAQ</a></li>
          </ul>
        </div>
        <div>
          <h3 className="text-base sm:text-lg font-semibold mb-4">Legal</h3>
          <ul className="space-y-2">
            <li><Link to="/privacy-policy" className="text-sm sm:text-base hover:text-gray-300">Privacy Policy</Link></li>
            <li><Link to="/terms-and-conditions" className="text-sm sm:text-base hover:text-gray-300">Terms & Conditions</Link></li>
          </ul>
        </div>
      </div>
      <div className="mt-8 pt-8 border-t border-gray-700 text-center">
        <p className="text-sm sm:text-base">&copy; 2023 SocialSignalAI. All rights reserved.</p>
      </div>
    </div>
  </footer>
);

const FeaturesPage = () => {
  return (
    <div className="font-sans">
      <Header />
      <Hero />
      
      <FeatureSection 
        title="Tailor-Made Social Listening"
        description="Customize SocialSignalAI to fit your unique social strategy. Easily track keywords, monitor multiple platforms, and manage campaigns without any coding knowledge."
        image={keywordsImage}
      />
      
      <FeatureSection 
        title="AI-Powered Engagement That Converts"
        description="Our smart AI generates context-aware responses, helping you build meaningful connections and effortlessly capture qualified leads across various social platforms."
        image={engagementTrackerImage}
        reverse={true}
      />
      
      <FeatureSection 
        title="Uncover Insights with Advanced Sentiment Analysis"
        description="Gain a deep understanding of your brand's perception using our sophisticated NLP algorithms. Use these insights to refine your strategy and proactively manage your reputation."
        image={sentimentAnalysisImage}
      />
      
      <FeatureSection 
        title="Stay Ahead with Real-Time Alerts"
        description="Never miss a beat with instant notifications on crucial mentions, emerging trends, or significant shifts in sentiment. Be proactive in your social media approach."
        image={alertImage}
        reverse={true}
      />
      
      <FeatureSection 
        title="Seamless Multi-Org Management"
        description="Perfect for agencies and large enterprises. Streamline your operations with role-based access control and effortless plan sharing across multiple teams and organizations."
        image={settingsImage}
      />
      
      <section className="py-16 bg-indigo-600 text-white text-center">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl lg:text-3xl font-bold mb-8">Ready to Transform Your Social Media Strategy?</h2>
          <button className="bg-white text-indigo-600 px-8 py-3 rounded-full text-lg font-semibold hover:bg-gray-100 transition duration-300">
            Get Started
          </button>
          <p className="mt-4 text-sm">Cancel anytime • Premium support</p>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default FeaturesPage;